import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

import { status_bg_color, status_chip_color } from './ReminderStaticData';
import { useUserData } from '../App/Component/UserDataProvider';
import CustomChip from '../Approvals/Component/CreateApprovalForm/CustomChip';
import { status_icon } from '../DocumentView/Component/Reminders/ReminderStatic';
import ListSkeleton from '../RiverusUI/Components/Skeleton/ListSkeleton';
import NameAvatar from '../RiverusUI/DataGrid/NameAvatar';
import { getDraftById } from '../Services/Draft';

interface Props {
  isLoading: boolean;
  reminderList: any;
}

const ReminderColumn: React.FC<Props> = ({ reminderList, isLoading }) => {
  const { user_id } = useUserData();

  const handleRouting = (item: any) => {
    const is_owner = item?.created_by?.id === user_id;
    const isAssignee = item?.users?.find((item: any) => item?.id === user_id);
    if (
      (is_owner || isAssignee) &&
      item?.status !== 'Completed' &&
      item?.is_active &&
      item?.users?.[0]?.is_active &&
      item?.active_status
    ) {
      window.open(
        '/document/documentlibrary/' +
          btoa(item?.contract?.id?.toString()) +
          '?isReminder=true&reminderId=' +
          item?.id,
        '_blank'
      );
    } else if (!item?.users?.[0]?.is_active) {
      enqueueSnackbar('This user is inactive!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else if (
      item?.status === 'Completed' ||
      !item?.active_status ||
      !item?.is_active
    ) {
      const infoMsg = !item?.active_status
        ? 'The draft version is updated, this reminder no longer exists!'
        : 'Reminder no longer exists!';
      enqueueSnackbar(infoMsg, {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      enqueueSnackbar('You are not assigned any role for this reminder!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const isOwner = React.useCallback(
    (owners: any) => {
      const index = owners?.findIndex((owner: any) => owner?.id === user_id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [user_id]
  );

  const canAccessReminder = React.useCallback(
    (data: any, reminderData: any) => {
      const assignedUser = reminderData?.users?.filter(
        (item: any) => item?.id === user_id
      );
      const owner = isOwner(data?.owners);
      if (owner || assignedUser?.length) {
        if (
          data?.status === 'Completed' ||
          (data?.new_draft_exists && data?.reminder_type !== 'checklist')
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    [user_id, isOwner]
  );

  const handleReminderNameClick = React.useCallback(
    async (item: any) => {
      const draftResponse = await getDraftById(
        item?.new_draft_id || item?.draft?.id
      );

      if (
        item?.status === 'Completed' ||
        !item?.active_status ||
        (item?.new_draft_exists && item?.reminder_type !== 'checklist') ||
        item?.status === 'contract executed successfully'
      ) {
        const infoMsg = !item?.active_status
          ? 'The draft version is updated, this reminder no longer exists!'
          : 'Reminder no longer exists!';
        enqueueSnackbar(infoMsg, {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      } else if (!item?.users?.[0]?.is_active) {
        enqueueSnackbar('This user is inactive!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }

      if (draftResponse) {
        const accessGranted = canAccessReminder(draftResponse, item);
        if (accessGranted && !item?.new_draft_id) {
          window.open(
            `/draft/${draftResponse?.version}/${draftResponse?.id}/?reminder_type=${item?.reminder_type}&reminderId=${item?.id}`,
            '_blank'
          );
        } else if (item?.new_draft_id) {
          window.open(
            `/draft/${item?.new_draft_version}/${item?.new_draft_id}/?reminder_type=${item?.reminder_type}&reminderId=${item?.id}`,
            '_blank'
          );
        } else {
          enqueueSnackbar('You are not assigned any role for this reminder!', {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    },
    [canAccessReminder]
  );

  return (
    <Stack
      spacing={1}
      sx={{
        minHeight: '100%',
        borderRadius: '20px',
        margin: '16px 0',
        gap: '16px',
      }}
    >
      {isLoading && !reminderList?.length && (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      )}

      {reminderList?.length === 0 && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          style={{
            color: '#938F99',
          }}
        >
          <InfoOutlinedIcon />
          <Typography>No reminder found</Typography>
        </Stack>
      )}

      {reminderList?.map((item: any, index: number) => {
        const isPastDue = dayjs(item?.due_date)?.isBefore(dayjs(), 'day');
        return (
          <Stack
            key={index}
            className={`according-reminder-class ${
              !item?.users?.[0]?.is_active || !item?.active_status
                ? 'reminderCardDeleted'
                : ''
            }`}
            padding="16px"
            spacing={0}
            alignItems="start"
            justifyContent="space-between"
            sx={{
              background:
                !item?.users?.[0]?.is_active || !item?.active_status
                  ? ''
                  : status_bg_color?.[item?.status],
              cursor: 'pointer',
              mt: '0 !important',
              gap: '8px',
              minHeight: '11rem',
            }}
            onClick={() => {
              if (item?.app === 'Umbrella') {
                handleRouting(item);
              } else if (item?.app === 'Stylus') {
                handleReminderNameClick(item);
              }
            }}
          >
            <Stack spacing={1} sx={{ flexGrow: 1, width: '100%' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                width="100%"
              >
                <Typography variant="body2">{item?.name}</Typography>
                {!item?.users?.[0]?.is_active ||
                  (!item?.active_status && (
                    <CustomChip
                      label="Expired"
                      sx={{
                        background: '#ffff',
                        padding: '6px 16px',
                        borderRadius: '5px',
                        pointerEvents:
                          !item?.users?.[0]?.is_active || !item?.active_status
                            ? 'none'
                            : '',
                        '& .MuiChip-label': {
                          padding: '0',
                        },
                      }}
                    />
                  ))}
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                width="100%"
                sx={{
                  flexFlow: 'wrap',
                  gap: '8px',
                }}
              >
                <CustomChip
                  label={item?.app}
                  sx={{
                    background:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? ''
                        : item?.app === 'Umbrella'
                          ? '#fa9e4d'
                          : '#208fc5',
                    color:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? ''
                        : '#fff',
                    border:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? ''
                        : `1px solid ${item?.app === 'Umbrella' ? '#fa9e4d' : '#208fc5'}`,
                    padding: '6px 16px',
                    borderRadius: '5px',
                    pointerEvents:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? 'none'
                        : '',
                    '& .MuiChip-label': {
                      padding: '0',
                    },
                    '&:hover': {
                      background: '#fff',
                      color: item?.app === 'Umbrella' ? '#fa9e4d' : '#208fc5',
                      '& .MuiChip-icon': {
                        color: item?.app === 'Umbrella' ? '#fa9e4d' : '#208fc5',
                      },
                    },
                  }}
                />
                <CustomChip
                  sx={{
                    background:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? ''
                        : status_chip_color?.[item?.status],
                    color:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? ''
                        : '#fff',
                    border:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? ''
                        : `1px solid ${status_chip_color?.[item?.status]}`,
                    padding: '6px 8px',
                    borderRadius: '5px',
                    marginLeft: '0 !important',
                    pointerEvents:
                      !item?.users?.[0]?.is_active || !item?.active_status
                        ? 'none'
                        : '',
                    '& .MuiChip-label': {
                      paddingRight: '0',
                      paddingLeft: '4px',
                      fontSize: '12px',
                    },
                    '& .MuiChip-icon': {
                      width: '14px',
                      height: '14px',
                      margin: '0',
                      color:
                        !item?.users?.[0]?.is_active || !item?.active_status
                          ? ''
                          : '#fff',
                    },
                    '&:hover': {
                      background: '#fff',
                      color: status_chip_color?.[item?.status],
                      '& .MuiChip-icon': {
                        color: status_chip_color?.[item?.status],
                      },
                    },
                  }}
                  icon={status_icon[item?.status]}
                  label={item?.status}
                />
              </Stack>
              <Tooltip
                title={
                  !item?.users?.[0]?.is_active || !item?.active_status
                    ? ''
                    : isPastDue
                      ? 'Past Due Date'
                      : ''
                }
              >
                <Box>
                  <CustomChip
                    sx={{
                      background:
                        !item?.users?.[0]?.is_active || !item?.active_status
                          ? ''
                          : isPastDue
                            ? '#e8c0bd'
                            : '#fff',
                      color: '#49454f',
                      width: 'fit-content',
                      pointerEvents:
                        !item?.users?.[0]?.is_active || !item?.active_status
                          ? 'none'
                          : '',
                      padding: '6px 8px',
                      borderRadius: '5px',
                      '& .MuiChip-label': {
                        paddingRight: '0',
                        paddingLeft: '4px',
                        fontSize: '12px',
                      },
                      '& .MuiChip-icon': {
                        width: '14px',
                        height: '14px',
                        margin: '0',
                        color:
                          isPastDue &&
                          item?.is_active &&
                          item?.active_status &&
                          item?.app === 'Umbrella'
                            ? '#7B0D0D'
                            : '',
                      },
                      '&:hover': {
                        background: isPastDue
                          ? '#F9DEDC'
                          : 'rgba(0, 0, 0, 0.08)',
                      },
                    }}
                    icon={<AccessTimeIcon />}
                    label={dayjs(item?.due_date).format('MMMM D, YYYY')}
                  />
                </Box>
              </Tooltip>
              {item?.contract?.file_name && (
                <Typography fontSize="13px" sx={{ wordBreak: 'break-all' }}>
                  {item?.contract?.file_name}
                </Typography>
              )}
              {item?.support_documents?.length > 0 && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontWeight={600} fontSize="14px">
                    Support documents:
                  </Typography>
                  <Typography fontSize="13px">
                    {item?.support_documents?.length}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: '6px !important' }}>
              {item?.external_user?.length > 0
                ? item.external_user.map((userItem: any) => {
                    const fullName: string[] = userItem?.name?.split(' ');
                    return (
                      <NameAvatar
                        key={userItem?.id}
                        firstName={fullName?.[0]}
                        lastName={fullName?.[1]}
                        sx={{
                          backgroundColor: 'unset',
                          width: 32,
                          height: 32,
                          fontSize: '12px',
                        }}
                      />
                    );
                  })
                : item?.users?.map((userItem: any) => {
                    const fullName: string[] = userItem?.first_name?.split(' ');
                    if (userItem?.assignee_type === 'external') {
                      return (
                        <NameAvatar
                          key={userItem?.id}
                          firstName={fullName?.[0]}
                          lastName={fullName?.[1]}
                          sx={{
                            backgroundColor: 'unset',
                            width: 32,
                            height: 32,
                            fontSize: '12px',
                          }}
                        />
                      );
                    }
                    return (
                      <NameAvatar
                        key={userItem?.id}
                        firstName={userItem?.first_name}
                        lastName={userItem?.last_name}
                      />
                    );
                  })}
            </Stack>
          </Stack>
        );
      })}

      {reminderList?.length > 0 && isLoading && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </Stack>
  );
};

export default ReminderColumn;
