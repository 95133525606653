import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import ApprovalChecklist from './ApprovalChecklist';
import ChecklistDrawer from './ChecklistDrawer';
import {
  approvalTypeOptions,
  approverSequence,
  conditionMap,
  DataDictionary,
  entireContractCondition,
  rangeCondition,
  similarityArray,
} from './CreateApproval';
import CustomChip from './CustomChip';
import LinkCLauseTextField from './LinkClauseTextField';
import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import ConfirmationModal from '../../../RiverusUI/Components/ConfirmationModalComponent';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import NotepadComponent from '../../../RiverusUI/Components/NotepadComponent';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  createApproval,
  fetchApprovalData,
  fetchApprovers,
  fetchContractCategory,
  fetchContractType,
  fetchContractTypesHasPolicy,
  fetchGroups,
  fetchMergeTag,
  fetchRequisitionApprovers,
  getUsers,
  updateApproval,
} from '../../../Services/Approval';
import { currencyList } from '../../../Utils/GeneralUtil/genUtils';
import { QueryKeyGenerator } from '../../../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: () => void;
  editDetails: boolean;
  editCopyDetailId: string;
  isViewPolicy?: boolean;
  setIsViewPolicy?: Dispatch<SetStateAction<boolean>>;
  setFilters?: any;
  setSorting?: any;
  initialFilter?: any;
  setApprovalTableKey?: any;
}

const textDataTypeArray = ['text-box', 'text', 'list'];
const triggerApprovalArray = ['contains', 'does not contain'];
const triggerDescribeBox = ['describe'];

const CreateApprovalComponent: React.FC<Props> = ({
  open,
  onClose,
  editDetails,
  editCopyDetailId,
  isViewPolicy = false,
  setIsViewPolicy,
  setFilters,
  setSorting,
  initialFilter,
  setApprovalTableKey,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedChecklists, setSelectedChecklists] = useState<any>([]);
  const [clauseValue, setClauseValue] = useState<any>([]);
  const [formDefaults, setFormDefaults] = useState<any>({
    approval_sequence: 'any',
  });
  const [selectedClauseArray, setSelectedClauseArray] = useState<any>([]);
  const [selectedFieldOperator, setSelectedFieldOperator] = useState<any>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [dataType, setDataType] = useState<string>('');
  const [isClose, setIsClose] = useState<boolean>(false);
  const [clauseNameLists, setClauseNameLists] = useState<any[]>([]);
  const [selectedRequisitionContract, setSelectedRequisitionContract] =
    useState<any[]>([]);
  const [openWarningMSG, setOpenWarningMSG] = useState<boolean>(false);
  // Checklist Drawer
  const [openChecklistDrawer, setOpenChecklistDrawer] =
    useState<boolean>(false);
  const [scope] = useState<string>('text_of_clause');

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['get_approval', editCopyDetailId],
    queryFn: async () => {
      const response = await fetchApprovalData(editCopyDetailId);

      return response;
    },
    enabled: !!editCopyDetailId,
  });

  const { data: contractData, isLoading: contractTypeLoading } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: fetchContractType,
    select: (response: any) => sortListAlphabetical(response?.results),
  });

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: fetchApprovers,
    select: (response: any) => {
      const groups = response?.results?.map((data: any) => ({
        ...data,
        name: data?.first_name + ' ' + data?.last_name,
      }));
      return sortListAlphabetical(groups);
    },
  });

  const {
    data: requisitionApproversData,
    isLoading: requisitionApproversLoading,
  } = useQuery({
    queryKey: ['Requisition Approvers'],
    queryFn: fetchRequisitionApprovers,
    select: (response: any) => {
      const groups = response?.results?.map((data: any) => ({
        ...data,
        name: data?.first_name + ' ' + data?.last_name,
      }));
      return sortListAlphabetical(groups);
    },
  });

  const methods = useForm({
    defaultValues: formDefaults,
  });

  const { handleSubmit, reset, control, watch, resetField, setValue } = methods;

  // Prescribing Department

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
    select: (response: any) => sortListAlphabetical(response),
  });

  useEffect(() => {
    if (data) {
      setSelectedChecklists(data.checklist);
      setDataType(data.data_type);
      if (
        data.value &&
        (data.data_type === 'text' || data.data_type === 'list')
      ) {
        setClauseValue(JSON.parse(data.value));
      }
      setFormDefaults((prev: any) => ({
        ...prev,
        contract_type: data.contract?.map((item: any) => item?.id),
        contract_category: data?.contract_category?.map(
          (item: any) => item?.id
        ),
        approval_type: data.approval_type,
        approvers: data?.priority,
        approval_sequence: data.approval_sequence,
        groups: data.groups?.map((group: any) => group?.id),
        policy_name: data.policy_name,
        case_sensitive: data.case_sensitive,
        include_all: data.include_all,
        condition: data.condition,
        similarity: data.similarity,
        clause_name:
          data?.clause_type === 'kdp'
            ? data.clause_name + ' (Machine Extracted)'
            : data.clause_name,
        field_name: data.field_name,
        duration: data.field_name === 'duration' ? data.value : '',
        optional_data: data.optional_data,
        note_for_approvers: data.note_for_approvers,
        checklist_required: data?.checklist_required,
        value: data.value,
        data_type:
          data.condition === 'contains' ||
          data.condition === 'does not contain' ||
          data.condition === 'describe'
            ? 'text'
            : data.data_type,
        selected_clause_text: data.data_type === 'text' ? data.value : null,
      }));
    }
  }, [contractData, data]);

  React.useEffect(() => {
    reset(formDefaults);
  }, [formDefaults, reset]);

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleConfirm = () => {
    setOpenConfirmationModal(false);
    onClose();
  };

  const handleSelectClause = (value: any) => {
    setClauseValue(value);
  };

  const approvalType = watch('approval_type') || '';
  const selectClauseName = watch('clause_name') || '';
  const selectedCondition = watch('condition') || '';
  const selectFieldName = watch('field_name') || '';
  const contractType = watch('contract_type') || '';
  const value = watch('value') || '';
  const contract_category = watch('contract_category') || '';
  const selectedCurrency = watch('currency') || '';

  useEffect(() => {
    resetField('contract_category');
    if (
      formDefaults?.contract_category?.length &&
      formDefaults.contract_type?.length
    ) {
      const isValueEqual = formDefaults?.contract_type?.every(
        (value: string, index: number) => value === contractType[index]
      );
      if (editDetails && !isValueEqual && contractType?.length) {
        setFormDefaults((prev: any) => ({
          ...prev,
          contract_type: contractType,
          contract_category: [],
        }));
      }
    }
  }, [contractType, resetField]);

  const { data: requisitionContractType } = useQuery({
    queryKey: ['requisition_contract_type', contract_category, contractType],
    queryFn: () => {
      let params = '?has_requisition_policy=true';
      if (contractType?.length) {
        const contractParams = `&contract_id=${contractType?.join(',')}`;
        params = `${params}${contractParams}`;
      }
      if (contract_category?.length) {
        const categoryParams = contract_category
          ?.map((item: string) => `&category=${item}`)
          ?.join('');
        params = `${params}${categoryParams}`;
      }
      return fetchContractTypesHasPolicy(params);
    },
    select: (response: any) => response.results,
    enabled: !!contractType?.length || !!contract_category?.length,
  });

  const { data: preSignatoryContractType } = useQuery({
    queryKey: ['pre_signatory_contract_type', contract_category, contractType],
    queryFn: () => {
      let params = '?has_pre_signatory_policy=true';
      if (contractType?.length) {
        const contractParams = `&contract_id=${contractType?.join(',')}`;
        params = `${params}${contractParams}`;
      }
      if (contract_category?.length) {
        const categoryParams = contract_category
          ?.map((item: string) => `&category=${item}`)
          ?.join('');
        params = `${params}${categoryParams}`;
      }
      return fetchContractTypesHasPolicy(params);
    },
    select: (response: any) => response.results,
    enabled: !!contractType?.length || !!contract_category?.length,
  });

  useEffect(() => {
    if (
      approvalType === 'contract_request' ||
      approvalType === 'pre_signatory_approval'
    ) {
      const filterRequisitionContract = requisitionContractType?.filter(
        (item: any) => contractType?.includes(item?.id)
      );
      const filterPreSignatoryContract = preSignatoryContractType?.filter(
        (item: any) => contractType?.includes(item?.id)
      );

      if (approvalType === 'contract_request') {
        setSelectedRequisitionContract(filterRequisitionContract);
      }

      if (approvalType === 'pre_signatory_approval') {
        setSelectedRequisitionContract(filterPreSignatoryContract);
      }

      const editContractType = data?.contract?.map((item: any) => item?.id);

      const areSelectedContractEqual = (
        editContractType: string[],
        contract_type: string[]
      ): boolean => {
        if (editContractType?.length !== contract_type?.length) return false;
        return editContractType?.every(
          (value, index) => value === contract_type[index]
        );
      };

      const isRequisitionPolicy =
        filterRequisitionContract?.length > 0 &&
        approvalType === 'contract_request';

      const isPreSignatoryPolicy =
        filterPreSignatoryContract?.length > 0 &&
        approvalType === 'pre_signatory_approval';

      if (
        ((isRequisitionPolicy || isPreSignatoryPolicy) &&
          !isViewPolicy &&
          !areSelectedContractEqual(editContractType, contractType)) ||
        (isPreSignatoryPolicy && data?.approval_type !== approvalType) ||
        (isRequisitionPolicy && data?.approval_type !== approvalType)
      ) {
        setOpenWarningMSG(true);
      }
    } else {
      setSelectedRequisitionContract([]);
      setOpenWarningMSG(false);
    }
  }, [contractType, requisitionContractType, approvalType, isViewPolicy]);

  const { data: getMergeTagList } = useQuery({
    queryKey: ['merge_tag_lists', contractType?.length],
    queryFn: () => fetchMergeTag(contractType?.[0]),
    select: (response: any) => response?.data,
    enabled: !!(contractType?.length === 1),
  });

  const { data: contractCategory, isLoading: loadingCategory } = useQuery({
    queryKey: ['contract_category', contractType],
    queryFn: () => fetchContractCategory(contractType?.[0]),
    select: (response: any) => sortListAlphabetical(response?.results),
    enabled: !!(contractType?.length === 1),
  });

  const filteredMergeTagList = useMemo(() => {
    return getMergeTagList?.filter((list: any) => list?.field_data?.length);
  }, [getMergeTagList]);

  useEffect(() => {
    if (getMergeTagList !== undefined) {
      setClauseNameLists([...DataDictionary, ...filteredMergeTagList]);
    }
  }, [getMergeTagList]);

  React.useEffect(() => {
    if (!editCopyDetailId) {
      resetField('approval_type');
      setSelectedChecklists([]);
    }
  }, [contractType, resetField, editCopyDetailId]);

  React.useEffect(() => {
    resetField('condition');
    resetField('value');
  }, [resetField, selectFieldName]);

  React.useEffect(() => {
    resetField('field_name');
    resetField('condition');
    resetField('value');
  }, [resetField, selectClauseName]);

  useEffect(() => {
    setValue('currency', formDefaults?.optional_data);
  }, [setValue, formDefaults]);

  useEffect(() => {
    if (selectClauseName) {
      const filterSelectedObject = clauseNameLists?.find(
        (item) => item?.section_name === selectClauseName
      );
      setSelectedClauseArray(filterSelectedObject);
    }
  }, [selectClauseName, clauseNameLists]);

  useEffect(() => {
    if (selectFieldName) {
      const selectedFieldObject = selectedClauseArray?.field_data?.find(
        (item: any) => item?.display_name === selectFieldName
      );
      setDataType(selectedFieldObject?.data_type?.toLowerCase());
      const getOperators = conditionMap.find(
        (item: any) =>
          item?.mergeTagDatatype ===
          selectedFieldObject?.data_type?.toLowerCase()
      );
      setSelectedFieldOperator(getOperators);
    }
  }, [selectFieldName, selectedClauseArray]);

  const { mutate: addApproval, isPending: loadingAddApproval } = useMutation({
    mutationKey: ['create_approval'],
    mutationFn: async (approvalAddData: any) => createApproval(approvalAddData),

    onSuccess: () => {
      isClose ? onClose() : reset();
      enqueueSnackbar('Approval created successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setApprovalTableKey(Math.random());
      setFilters(initialFilter);
      setSorting([]);
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.__all__?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create Approval!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: editApproval, isPending: loadingEditApproval } = useMutation({
    mutationKey: ['update_approval', editCopyDetailId, isClose],
    mutationFn: async (approvalAddData) =>
      updateApproval(editCopyDetailId, approvalAddData),

    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: [QueryKeyGenerator.getRequestApproval()],
      });
      enqueueSnackbar('Approval updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.__all__?.[0];
      enqueueSnackbar(`${responseData || 'Failed to update Approval!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response) => {
      const users = response?.users || [];
      return users.map((user: any) => ({
        ...user,
        name: `${user.first_name} ${user.last_name}`,
      }));
    },
  });

  const filteredRequisitionData = useMemo(() => {
    return requisitionApproversData?.filter(
      (user: any) =>
        user?.is_active ||
        data?.approvers?.some((approver: any) => approver?.id === user?.id)
    );
  }, [requisitionApproversData, data?.approvers]);

  const filteredApproversData = useMemo(() => {
    return approversData?.filter(
      (user: any) =>
        user?.is_active ||
        data?.approvers?.some((approver: any) => approver?.id === user?.id)
    );
  }, [approversData, data?.approvers]);

  const onSubmit = (data: any, isClose = true) => {
    const isUserDisabled = data?.approvers?.some((approver: any) => {
      const matchingUser = authorData?.find(
        (user: any) => user?.id === approver
      );
      return matchingUser && !matchingUser.is_active;
    });
    if (isUserDisabled) {
      enqueueSnackbar(
        'To proceed with this policy, please deselect inactive users and add new approvers if required.',
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    } else if (!data?.approvers?.length) {
      enqueueSnackbar('please select approvers', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else if (!data?.groups?.length) {
      enqueueSnackbar('please select Prescribing Department', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      data.contract = contractType;
      data.value =
        clauseValue?.length !== 0 ? JSON.stringify(clauseValue) : (value ?? '');
      data.checklist =
        approvalType === 'contract_request' ? selectedChecklists : [];
      data.priority = data.approvers;
      data.clause_name = selectClauseName.replace(/\(Machine Extracted\)/g, '');
      data.include_all =
        data.condition === 'does not contain' ? true : data?.include_all;
      if (dataType !== '') {
        data.data_type =
          data.condition === 'contains' ||
          data.condition === 'does not contain' ||
          data.condition === 'describe'
            ? 'list'
            : dataType;
      }

      if (!data?.contract_category?.length) {
        delete data['contract_category'];
      }

      setIsClose(isClose);

      const payload = {
        ...data,
        scope: scope,
        ...(data?.contract_type?.length > 1 ||
        data?.contract_category === undefined
          ? { contract_category: [] }
          : {}),
        clause_type:
          selectClauseName?.includes('Machine Extracted') ||
          scope === 'entire_contract'
            ? 'kdp'
            : 'merge_tag',
        ...(data?.data_type === 'currency'
          ? { optional_data: data?.currency, currency: data?.currency }
          : { currency: '' }),
      };

      if (editDetails) {
        editApproval(payload);
      } else {
        addApproval(payload);
      }
    }
  };

  const isDescribeCondition = useMemo(() => {
    return triggerDescribeBox?.includes(selectedCondition);
  }, [triggerDescribeBox, selectedCondition]);

  const renderTextInput = React.useCallback(
    () => (
      <LinkCLauseTextField
        readOnly={isViewPolicy}
        name="selected_clause_text"
        control={control}
        key="entire-contract-text"
        clauseValue={clauseValue}
        handleClauseValue={(value: any) => handleSelectClause(value)}
        isDescribeCondition={isDescribeCondition}
      />
    ),
    [clauseValue, control, isViewPolicy, isDescribeCondition]
  );

  const showValueField = useMemo(
    () =>
      selectedCondition &&
      selectedCondition !== 'is present' &&
      selectedCondition !== 'is absent',
    [selectedCondition]
  );

  const disableIncludeAll = useMemo(
    () => selectedCondition === 'does not contain',
    [selectedCondition]
  );

  const handleOnCloseWarningMsg = () => {
    setSelectedRequisitionContract([]);
    setOpenWarningMSG(false);
  };

  const filteredApprovalTypeOptions = useMemo(() => {
    return approvalTypeOptions.map((option) => ({
      ...option,
      disabled:
        option?.value === 'contract_condition' && contractType?.length > 1,
    }));
  }, [contractType?.length]);

  const filteredOperators = useMemo(() => {
    if (selectClauseName?.includes('(Machine Extracted)')) {
      return selectedFieldOperator?.operators || [];
    }
    if (selectedFieldOperator?.mergeTagDatatype === 'date') {
      return (
        selectedFieldOperator?.operators?.filter((operator: string) =>
          ['before', 'after', '='].includes(operator)
        ) || []
      );
    }
    return (
      selectedFieldOperator?.operators?.filter(
        (condition: string) =>
          condition !== 'is present' &&
          condition !== 'is absent' &&
          condition !== 'describe'
      ) || []
    );
  }, [selectClauseName, selectedFieldOperator]);

  const filteredFieldData = useMemo(() => {
    if (selectedClauseArray?.section_name?.includes('(Machine Extracted)')) {
      return selectedClauseArray?.field_data;
    }
    return selectedClauseArray?.field_data?.filter(
      (field: any) =>
        ![
          'Numbered List',
          'Currency Verbal',
          'Bulleted List',
          'Date Verbal',
          'Serial No',
          'Boolean',
          'Select',
          'Percentage',
        ].includes(field?.data_type)
    );
  }, [selectedClauseArray?.field_data, selectedClauseArray?.section_name]);

  return (
    <React.Fragment>
      <CustomModal
        title={data ? 'Edit Policy' : 'Create Policy'}
        maxWidth="lg"
        open={open}
        handleClose={onClose}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Stack spacing={3} sx={{ padding: '30px 15px' }}>
              <Grid container gap={2}>
                <Grid item sm={9}>
                  <Stack spacing={2}>
                    <Typography fontWeight="700">
                      Select contracts and type of policy
                    </Typography>
                    <RISelectComponent
                      readOnly={isViewPolicy}
                      name="contract_type"
                      control={control}
                      label="Contract Type *"
                      labelKey="displayName"
                      options={contractData}
                      loading={contractTypeLoading}
                      valueKey="id"
                      isMultiselect
                      canSelectAll
                      renderCustomComponent={(value: any, props) => (
                        <CustomChip
                          {...props}
                          label={value?.displayName}
                          key={value?.id}
                        />
                      )}
                      textFieldProps={{
                        helperText:
                          'You can select multiple contracts here. The approval rule below will be applied to this selection',
                      }}
                    />
                    {contractType?.length === 1 && (
                      <RISelectComponent
                        readOnly={isViewPolicy}
                        name="contract_category"
                        control={control}
                        label="Contract Category"
                        options={contractCategory}
                        loading={loadingCategory}
                        valueKey="id"
                        labelKey="name"
                        isMultiselect
                        renderCustomComponent={(value: any, props) => (
                          <CustomChip
                            {...props}
                            label={value?.name}
                            key={value?.id}
                          />
                        )}
                        textFieldProps={{
                          helperText: contractCategory?.length
                            ? `${contractCategory?.length} contract ${contractCategory?.length === 1 ? 'category' : 'categories'} found under selected contract type`
                            : 'No contract category found under selected contract type',
                        }}
                      />
                    )}
                    <RadioButtonGroup
                      row
                      required
                      name="approval_type"
                      options={filteredApprovalTypeOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                      disabled={isViewPolicy}
                    />
                  </Stack>
                </Grid>
                {approvalType === 'contract_condition' && (
                  <>
                    {/* <Grid item sm={9}>
                    <Typography marginBottom={2} fontWeight={"700"}>
                      Define scope of this policy
                    </Typography>
                    <RadioButtonGroup
                      name="scope"
                      isDescription
                      control={control}
                      row
                      required
                      options={policyScope}
                      valueKey="value"
                      onChange={handleScope}
                      disabled={isViewPolicy}
                    />
                  </Grid> */}
                    {scope && (
                      <Grid item sm={9}>
                        <Typography fontWeight={'700'}>
                          Define condition to match
                        </Typography>
                      </Grid>
                    )}
                    {scope === 'entire_contract' && (
                      <>
                        <Grid item sm={12} mx={1}>
                          {renderTextInput()}
                        </Grid>
                        <Grid container alignItems={'center'} mt={2}>
                          <Grid
                            item
                            sm={1}
                            style={{
                              transform: 'scaleX(-1)',
                              paddingRight: '15px',
                            }}
                          >
                            <KeyboardReturnIcon />
                          </Grid>
                          <Grid item sm={8}>
                            <Controller
                              name="condition"
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  fullWidth
                                  style={{ margin: '10px' }}
                                >
                                  <InputLabel htmlFor="Select-condition">
                                    Select condition
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    required
                                    label="Select condition"
                                    variant="outlined"
                                    inputProps={{
                                      id: 'Select-condition',
                                      readOnly: isViewPolicy,
                                    }}
                                  >
                                    {entireContractCondition.map(
                                      (option: any, index: number) => {
                                        return (
                                          <MenuItem key={index} value={option}>
                                            {option}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {scope === 'text_of_clause' && (
                      <>
                        <Grid item sm={9}>
                          <RISelectComponent
                            label="Select Clause Name"
                            name="clause_name"
                            required
                            labelKey="section_name"
                            options={sortListAlphabetical(clauseNameLists)}
                            valueKey="section_name"
                            readOnly={isViewPolicy}
                            control={control}
                          />
                        </Grid>
                        {selectClauseName && (
                          <Grid container alignItems={'center'} mt={2}>
                            <Grid
                              item
                              sm={1}
                              style={{
                                transform: 'scaleX(-1)',
                                paddingRight: '15px',
                              }}
                            >
                              <KeyboardReturnIcon />
                            </Grid>
                            <Grid item sm={8}>
                              <RISelectComponent
                                label="Select a field name"
                                name="field_name"
                                required
                                labelKey="display_name"
                                options={sortListAlphabetical(
                                  filteredFieldData
                                )}
                                valueKey="display_name"
                                readOnly={isViewPolicy}
                                control={control}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {selectFieldName && (
                          <Grid container alignItems={'center'} mt={2}>
                            <Grid
                              item
                              sm={2}
                              style={{
                                transform: 'scaleX(-1)',
                              }}
                            >
                              <KeyboardReturnIcon />
                            </Grid>
                            <Grid item sm={7}>
                              <RISelectComponent
                                name="condition"
                                readOnly={isViewPolicy}
                                control={control}
                                label="Select condition"
                                options={sortListAlphabetical(
                                  filteredOperators
                                )}
                                labelKey="condition"
                                valueKey="name"
                                required
                              />
                            </Grid>
                          </Grid>
                        )}
                        {showValueField && (
                          <>
                            <Grid container alignItems={'center'} mt={2}>
                              <Grid
                                item
                                sm={2}
                                style={{
                                  transform: 'scaleX(-1)',
                                }}
                              >
                                <KeyboardReturnIcon />
                              </Grid>
                              <Grid
                                item
                                sm={
                                  textDataTypeArray.includes(dataType) ? 10 : 7
                                }
                              >
                                {dataType === 'duration' && (
                                  <Stack direction="row" alignItems="center">
                                    <Controller
                                      name="optional_data"
                                      control={control}
                                      render={({ field }) => (
                                        <FormControl
                                          style={{
                                            margin: '10px',
                                            width: '50%',
                                          }}
                                        >
                                          <InputLabel htmlFor="Select-duration">
                                            Duration *
                                          </InputLabel>
                                          <Select
                                            {...field}
                                            required
                                            label="Duration"
                                            variant="outlined"
                                            inputProps={{
                                              id: 'Select-duration',
                                              readOnly: isViewPolicy,
                                            }}
                                          >
                                            <MenuItem
                                              key={'day'}
                                              value={'days'}
                                            >
                                              Day(s)
                                            </MenuItem>
                                            <MenuItem
                                              key={'Month'}
                                              value={'Months'}
                                            >
                                              Month(s)
                                            </MenuItem>
                                            <MenuItem
                                              key={'Years'}
                                              value={'Years'}
                                            >
                                              Years(s)
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      )}
                                    />
                                    <ControlledTextField
                                      name="value"
                                      control={control}
                                      required
                                      type="number"
                                      label="Enter value"
                                      inputProps={{
                                        readOnly: isViewPolicy,
                                      }}
                                    />
                                  </Stack>
                                )}
                                {dataType === 'date' && (
                                  <ControlledTextField
                                    name="value"
                                    control={control}
                                    fullWidth
                                    required
                                    type="date"
                                    inputProps={{
                                      readOnly: isViewPolicy,
                                    }}
                                  />
                                )}
                                {dataType === 'numeric' && (
                                  <ControlledTextField
                                    name="value"
                                    control={control}
                                    fullWidth
                                    required
                                    type="number"
                                    inputProps={{
                                      readOnly: isViewPolicy,
                                    }}
                                  />
                                )}
                                {dataType === 'percentage' && (
                                  <ControlledTextField
                                    name="value"
                                    control={control}
                                    fullWidth
                                    type="number"
                                    required
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PercentIcon />
                                        </InputAdornment>
                                      ),
                                      readOnly: isViewPolicy,
                                    }}
                                  />
                                )}
                                {dataType === 'currency' && (
                                  <Stack spacing={2}>
                                    <RISelectComponent
                                      name="currency"
                                      readOnly={isViewPolicy}
                                      control={control}
                                      label="Select a currency"
                                      options={sortListAlphabetical(
                                        currencyList
                                      )}
                                      labelKey="currency"
                                      valueKey="name"
                                      required
                                    />
                                    {selectedCurrency && (
                                      <ControlledTextField
                                        label="Enter value"
                                        name="value"
                                        control={control}
                                        fullWidth
                                        type="number"
                                        required
                                        InputProps={{
                                          readOnly: isViewPolicy,
                                        }}
                                      />
                                    )}
                                  </Stack>
                                )}
                                {textDataTypeArray.includes(dataType) && (
                                  <Box sx={{ paddingX: '12px' }}>
                                    {renderTextInput()}
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                    {scope &&
                      (textDataTypeArray.includes(dataType) ||
                        scope === 'entire_contract') && (
                        <Grid container>
                          <Grid sm={scope === 'entire_contract' ? 2 : 3} />
                          <Grid item sm={6}>
                            {rangeCondition.includes(selectedCondition) && (
                              <Controller
                                disabled={isViewPolicy}
                                name="similarity"
                                control={control}
                                defaultValue={100}
                                render={({ field }) => (
                                  <Slider
                                    defaultValue={100}
                                    marks={similarityArray}
                                    valueLabelDisplay="auto"
                                    {...field}
                                  />
                                )}
                              />
                            )}
                            {!triggerDescribeBox?.includes(
                              selectedCondition
                            ) && (
                              <Controller
                                disabled={isViewPolicy}
                                name="case_sensitive"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        {...field}
                                        defaultChecked={data?.case_sensitive}
                                      />
                                    }
                                    label="Clauses are case sensitive"
                                    labelPlacement="end"
                                  />
                                )}
                              />
                            )}
                            {triggerApprovalArray.includes(
                              selectedCondition
                            ) && (
                              <Controller
                                disabled={isViewPolicy}
                                name="include_all"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <>
                                        {!disableIncludeAll ? (
                                          <Switch
                                            {...field}
                                            defaultChecked={data?.include_all}
                                          />
                                        ) : (
                                          <Switch
                                            {...field}
                                            disabled
                                            defaultChecked
                                          />
                                        )}
                                      </>
                                    }
                                    label="Trigger approval if all values pass the condition"
                                    labelPlacement="end"
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          <Grid sm={3} />
                        </Grid>
                      )}
                  </>
                )}
                <Grid item sm={9}>
                  <Stack spacing={2}>
                    <Typography fontWeight="700">
                      Who will approve this?
                    </Typography>
                    <RISelectComponent
                      readOnly={isViewPolicy}
                      name="approvers"
                      control={control}
                      label="Select approvers *"
                      options={
                        approvalType === 'contract_request'
                          ? filteredRequisitionData
                          : filteredApproversData
                      }
                      loading={
                        approvalType === 'contract_request'
                          ? requisitionApproversLoading
                          : approversLoading
                      }
                      isMultiselect={true}
                      renderCustomComponent={(value: any, props) => (
                        <ShareChip
                          key={value?.id}
                          {...props}
                          label={value?.name}
                          sx={{
                            background: value?.is_active
                              ? '#FFECF1'
                              : '#e0e0e0',
                          }}
                        />
                      )}
                      renderAction={(value) => {
                        return value?.is_active ? (
                          <></>
                        ) : (
                          <Stack
                            direction="row"
                            color="#88305f"
                            fontWeight={600}
                          >
                            Inactive
                          </Stack>
                        );
                      }}
                      textFieldProps={{
                        helperText:
                          'You can select multiple approvers. All approvers must approve in order for this rule to pass',
                      }}
                    />
                    <RadioButtonGroup
                      disabled={isViewPolicy}
                      name="approval_sequence"
                      row
                      options={approverSequence}
                      control={control}
                      isDescription
                      valueKey="value"
                      required
                    />
                    {approvalType === 'contract_request' && (
                      <NotepadComponent
                        name="note_for_approvers"
                        control={control}
                        disabled={isViewPolicy}
                      />
                    )}
                    <RISelectComponent
                      readOnly={isViewPolicy}
                      name="groups"
                      control={control}
                      label="Prescribing Department *"
                      options={groupListData}
                      loading={groupLoading}
                      isMultiselect
                      renderCustomComponent={(value: any) => (
                        <CustomChip key={value?.id} label={value?.name} />
                      )}
                    />
                  </Stack>
                </Grid>
                {approvalType === 'contract_request' && (
                  <>
                    <Grid item sm={9}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack spacing={1}>
                          <Typography fontWeight="700">
                            Define checklist
                          </Typography>
                          <Typography variant="caption">
                            These documents/checks will need to be completed
                            before signing
                          </Typography>
                        </Stack>
                        <Tooltip
                          title="Click here to make this checklist item mandatory"
                          arrow
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            alignSelf="flex-end"
                          >
                            <Typography>Required Field:</Typography>
                            <Controller
                              name="checklist_required"
                              control={control}
                              render={({ field: { value, ...rest } }) => (
                                <Switch
                                  {...rest}
                                  checked={value}
                                  disabled={isViewPolicy}
                                />
                              )}
                            />
                          </Stack>
                        </Tooltip>
                      </Stack>

                      {selectedChecklists?.length ? (
                        <FormControl disabled={isViewPolicy}>
                          <ApprovalChecklist list={selectedChecklists} />
                        </FormControl>
                      ) : (
                        <Typography
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            columnGap: '10px',
                            color: '#B0A7C0',
                            margin: '10px',
                          }}
                        >
                          <ErrorOutlineIcon /> No items defined yet. Click
                          `&#34;`Modify list`&#34;` button to add items
                        </Typography>
                      )}
                    </Grid>
                    {!isViewPolicy && (
                      <Grid item sm={3}>
                        <Button
                          onClick={() => {
                            setOpenChecklistDrawer(true);
                          }}
                          style={{ marginLeft: '20px' }}
                          startIcon={<EditIcon />}
                        >
                          Modify list
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item sm={9}>
                  <Stack spacing={2}>
                    <ControlledTextField
                      inputProps={{
                        readOnly: isViewPolicy,
                      }}
                      name="policy_name"
                      control={control}
                      required
                      label="Policy Name"
                      fullWidth
                      helperText="   The name will appear in your policy dashboard and in drafts
                  page."
                    />
                    {!isViewPolicy && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Stack spacing={2} direction="row">
                          <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loadingAddApproval || loadingEditApproval}
                          >
                            Save
                          </LoadingButton>
                          {!editDetails && (
                            <LoadingButton
                              variant="outlined"
                              onClick={handleSubmit((data) =>
                                onSubmit(data, false)
                              )}
                              loading={
                                loadingAddApproval || loadingEditApproval
                              }
                            >
                              Save and create new policy
                            </LoadingButton>
                          )}
                        </Stack>
                        <Button
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          onClick={() => setOpenConfirmationModal(true)}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </form>
          {isViewPolicy && setIsViewPolicy && (
            <Stack
              justifyContent="space-between"
              px={5}
              alignItems="center"
              direction="row"
              sx={{ width: '75%' }}
            >
              <Button
                variant="outlined"
                startIcon={<ModeOutlinedIcon />}
                onClick={() => setIsViewPolicy(false)}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </FormProvider>
      </CustomModal>
      {openChecklistDrawer && (
        <ChecklistDrawer
          open={openChecklistDrawer}
          onClose={() => setOpenChecklistDrawer(false)}
          selectedChecklist={selectedChecklists}
          setSelectedChecklist={setSelectedChecklists}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={handleClose}
          onConfirm={handleConfirm}
          title="Confirmation"
          message="Are you sure you want to close this?"
        />
      )}
      {openWarningMSG && (
        <ReusableConfirmationModal
          open={openWarningMSG}
          onConfirm={handleOnCloseWarningMsg}
          headerOnClose={handleOnCloseWarningMsg}
          onClose={() => {
            handleOnCloseWarningMsg();
            if (!editDetails) {
              resetField('contract_type');
            }
          }}
          cancelBtnText={editDetails ? 'Close' : 'Remove Selection'}
          confirmBtnText="Go Ahead"
          title="Warning"
        >
          <Stack spacing={2}>
            <Typography variant="body2">
              Few of the contract types from your current selection already have{' '}
              {approvalType === 'contract_request'
                ? 'requisition'
                : approvalType === 'pre_signatory_approval'
                  ? 'pre-signatory approval'
                  : ''}
              . If you decide to proceed further then this approval policy will
              overwrite the previous{' '}
              {approvalType === 'contract_request'
                ? 'requisition policy'
                : approvalType === 'pre_signatory_approval'
                  ? 'pre-signatory policy'
                  : ''}
              .
            </Typography>
            <Typography variant="body2" fontWeight={700}>
              Contract Types are
            </Typography>
            <Stack spacing={1} maxHeight={100} sx={{ overflowY: 'scroll' }}>
              {selectedRequisitionContract?.map((item: any, index: number) => (
                <Typography
                  variant="body2"
                  fontWeight={700}
                  key={`${index}-requisition contract`}
                >
                  {index + 1}. {item?.displayName}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default CreateApprovalComponent;
