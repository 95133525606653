import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import TagIcon from '@mui/icons-material/Tag';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import { getContractsTags } from '../../../Draft/DA';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  addClauseLibrary,
  addTags,
  fetchClauseLibraryById,
  updateClauseLibrary,
} from '../../../Services/Approval';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  sectionNamesData?: any;
  clauseId?: any;
  setRefreshKey?: any;
  setFilters?: any;
  setSorting?: any;
  initialFilter?: any;
  setClauseTableKey?: any;
  contractOption?: any;
  contractLoading?: any;
};

const CreateNewClauseModal: React.FC<Props> = ({
  open,
  onClose,
  sectionNamesData,
  clauseId,
  setRefreshKey,
  setFilters,
  setSorting,
  initialFilter,
  setClauseTableKey,
  contractOption,
  contractLoading,
}) => {
  const methods = useForm();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;
  const queryClient = useQueryClient();

  const [isEdit, setIsEdit] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const sectionNameOptions = useMemo(
    () =>
      sectionNamesData?.data?.map((sectionName: any) => ({
        value: sectionName,
        label: sectionName,
      })),
    [sectionNamesData]
  );

  const handleCopyToClipboard = () => {
    const clauseText = watch('clause_text');
    navigator.clipboard.writeText(clauseText).then(() => {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    });
  };

  const { data: clauseLibraryById } = useQuery({
    queryKey: ['get_clause_by_id', clauseId, open],
    queryFn: async () => await fetchClauseLibraryById(clauseId),
    enabled: !!clauseId,
  });

  useEffect(() => {
    if (open && clauseLibraryById) {
      setIsEdit(false);
      setIsDuplicate(false);
    } else if (open) {
      reset({
        section_name: '',
        clause_name: '',
        clause_text: '',
        clause_type: '',
        applicability: '',
        tags: '',
      });
    }
  }, [open, clauseLibraryById, reset]);

  useEffect(() => {
    if (clauseLibraryById) {
      if (!isDuplicate) {
        reset({
          section_name: clauseLibraryById.section_name || '',
          clause_name: clauseLibraryById.clause_name || '',
          clause_text: clauseLibraryById.clause_text || '',
          clause_type: clauseLibraryById.clause_type || '',
          applicability:
            clauseLibraryById.applicability?.map(
              (applicability: any) => applicability.id
            ) || [],
          tags: clauseLibraryById.tags?.map((tag: any) => tag.id) || [],
        });
      } else {
        reset({
          section_name: clauseLibraryById.section_name || '',
          clause_name: '',
          clause_text: clauseLibraryById.clause_text || '',
          clause_type: clauseLibraryById.clause_type || '',
          applicability:
            clauseLibraryById.applicability?.map(
              (applicability: any) => applicability.id
            ) || [],
          tags: clauseLibraryById.tags?.map((tag: any) => tag.id) || [],
        });
      }
    }
  }, [clauseLibraryById, isDuplicate, reset]);

  const handleDuplicate = () => {
    setIsDuplicate(true);
  };

  const { mutate: createClauseMutation, isPending: loadingCreateClause } =
    useMutation({
      mutationKey: ['create_clause'],
      mutationFn: addClauseLibrary,
      onSuccess: () => {
        enqueueSnackbar('Clause added successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setClauseTableKey(Math.random());
        setFilters(initialFilter);
        setSorting([]);
        setRefreshKey((prevKey: any) => !prevKey);
        onClose();
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.__all__?.[0];
        enqueueSnackbar(`${responseData || 'Failed to create Clause!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const {
    mutate: updateClauseLibraryMutation,
    isPending: loadingUpdateClause,
  } = useMutation({
    mutationKey: ['update_clause_library'],
    mutationFn: updateClauseLibrary,
    onSuccess: () => {
      enqueueSnackbar('Clause updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['admin-clause-library'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Clause!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: tagsData, isLoading: tagsLoading } = useQuery({
    queryKey: ['tags-data'],
    queryFn: getContractsTags,
    select: (response: any) => response.results,
  });

  const { mutate: addTagsMutation } = useMutation({
    mutationKey: ['add-tags'],
    mutationFn: addTags,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags-data'] });
    },
  });

  const onSubmit = useCallback(
    (data: any) => {
      const tagsArray = Array.isArray(data?.tags)
        ? data.tags.map((tagId: string) => tagId)
        : [];
      const applicabilityArray = Array.isArray(data?.applicability)
        ? data.applicability.map((applicabilityId: string) => applicabilityId)
        : [];

      const payload = {
        ...data,
        section_name: data?.section_name,
        clause_name: data?.clause_name,
        clause_text: data?.clause_text,
        clause_type: data?.clause_type,
        applicability: applicabilityArray,
        tags: tagsArray,
      };

      if (clauseLibraryById && !isDuplicate) {
        const updateClausePayload = {
          id: clauseLibraryById?.id,
          body: payload,
        };
        updateClauseLibraryMutation(updateClausePayload);
      } else {
        createClauseMutation(payload);
      }
    },
    [
      clauseLibraryById,
      isDuplicate,
      updateClauseLibraryMutation,
      createClauseMutation,
    ]
  );

  useEffect(() => {
    if (errors?.clause_type) {
      enqueueSnackbar('Select a Clause Type', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  }, [errors?.clause_type]);

  return (
    <CustomModal
      maxWidth="md"
      title={
        clauseLibraryById && !isDuplicate
          ? `${clauseLibraryById?.clause_name}`
          : 'Create a new clause'
      }
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} sx={{ width: '656px', padding: '30px 10px 10px' }}>
            <Stack spacing={3}>
              <RISelectComponent
                name="section_name"
                control={control}
                label="Select Section"
                options={sortListAlphabetical(sectionNameOptions, 'label')}
                labelKey="label"
                valueKey="value"
                readOnly={!isEdit && clauseLibraryById && !isDuplicate}
                required
                renderCustomComponent={(value: any, props) => (
                  <CustomChip {...props} label={value?.label} />
                )}
              />
              <ControlledTextField
                name="clause_name"
                control={control}
                label="Clause Name"
                fullWidth
                required
                disabled={!isEdit && clauseLibraryById && !isDuplicate}
              />

              <Stack alignItems="flex-end">
                <ControlledTextField
                  name="clause_text"
                  control={control}
                  label="Clause Text"
                  fullWidth
                  required
                  disabled={!isEdit && clauseLibraryById && !isDuplicate}
                  multiline
                  minRows={2}
                />
                {clauseLibraryById && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleCopyToClipboard}
                    startIcon={<CopyAllIcon />}
                    sx={{ height: '40px', marginBottom: '-10px' }}
                    disabled={!isEdit}
                  >
                    Copy clause text to clipboard
                  </Button>
                )}
              </Stack>

              <RISelectComponent
                name="applicability"
                options={sortListAlphabetical(contractOption, 'displayName')}
                labelKey="displayName"
                control={control}
                loading={contractLoading}
                isMultiselect
                readOnly={!isEdit && clauseLibraryById && !isDuplicate}
                label="Applicability"
                renderCustomComponent={(value: any, props) => (
                  <CustomChip {...props} label={value?.displayName} />
                )}
                textFieldProps={{
                  helperText:
                    'Select contracts type for which this clause will be valid for.',
                }}
              />

              <RISelectComponent
                name="tags"
                control={control}
                loading={tagsLoading}
                label="Tags"
                options={sortListAlphabetical(tagsData)}
                isMultiselect
                readOnly={!isEdit && clauseLibraryById && !isDuplicate}
                renderCustomComponent={(value: any, props) => (
                  <CustomChip
                    {...props}
                    icon={
                      <TagIcon
                        style={{
                          color: '#6D264C',
                          fontSize: '16px',
                        }}
                      />
                    }
                    label={value?.name}
                  />
                )}
                canCreateNew
                addNewValue={(value) => addTagsMutation({ name: value })}
                textFieldProps={{
                  helperText:
                    'Select tag for which this clause will be valid for.',
                }}
              />
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1" fontWeight={700}>
                Clause Type *
              </Typography>
              <Stack sx={{ padding: '0 16px' }}>
                <FormControl>
                  <Controller
                    name="clause_type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <RadioGroup
                        row
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        sx={{
                          '& .MuiRadio-root': {
                            color: fieldState.invalid ? '#d32f2f' : 'inherit',
                          },
                        }}
                      >
                        <FormControlLabel
                          value="recommended"
                          disabled={
                            !isEdit && clauseLibraryById && !isDuplicate
                          }
                          control={<Radio />}
                          label="Recommended"
                          sx={{ marginRight: '5rem' }}
                        />
                        <FormControlLabel
                          value="avoidable"
                          disabled={
                            !isEdit && clauseLibraryById && !isDuplicate
                          }
                          control={<Radio />}
                          label="Avoidable"
                          sx={{ marginRight: '5rem' }}
                        />

                        <FormControlLabel
                          value="Fallback"
                          disabled={
                            !isEdit && clauseLibraryById && !isDuplicate
                          }
                          control={<Radio />}
                          label="Fallback"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={2}>
                {!clauseLibraryById && !isDuplicate && (
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ height: '40px' }}
                    loading={loadingCreateClause}
                  >
                    Save
                  </LoadingButton>
                )}
                {!clauseLibraryById && !isDuplicate && (
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                    sx={{ height: '40px', width: 'fit-content' }}
                  >
                    Cancel
                  </Button>
                )}

                {isDuplicate && (
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ height: '40px' }}
                    loading={loadingUpdateClause}
                  >
                    Save
                  </LoadingButton>
                )}
                {isDuplicate && (
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                    sx={{ height: '40px', width: 'fit-content' }}
                  >
                    Cancel
                  </Button>
                )}

                {clauseLibraryById && !isDuplicate && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Stack spacing={1}>
                      <Stack direction="row" spacing={2}>
                        {isEdit && (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{ height: '40px' }}
                          >
                            Save
                          </Button>
                        )}
                        {!isEdit && (
                          <Button
                            variant="outlined"
                            sx={{ height: '40px' }}
                            onClick={() => setIsEdit(true)}
                          >
                            Edit
                          </Button>
                        )}
                        {clauseLibraryById && !isDuplicate && (
                          <Button
                            variant="outlined"
                            startIcon={<CopyAllIcon />}
                            sx={{ height: '40px' }}
                            onClick={handleDuplicate}
                          >
                            Duplicate
                          </Button>
                        )}
                      </Stack>

                      <Stack direction="row" spacing={3}>
                        <Typography variant="caption" fontWeight={600}>
                          Created by:
                          <Typography variant="caption" ml={1} fontWeight={400}>
                            {`${clauseLibraryById?.created_by?.first_name} ${clauseLibraryById?.created_by?.last_name}`}
                          </Typography>
                        </Typography>

                        <Typography variant="caption" fontWeight={600}>
                          Last Modified:
                          <Typography variant="caption" ml={1} fontWeight={400}>
                            {dayjs(clauseLibraryById?.modified_on).format(
                              'MMMM D, YYYY'
                            )}
                          </Typography>
                        </Typography>
                      </Stack>
                    </Stack>

                    <Button
                      variant="outlined"
                      onClick={onClose}
                      startIcon={<CloseIcon />}
                      sx={{ height: '40px', width: 'fit-content' }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateNewClauseModal;
