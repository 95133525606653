import React, { useCallback, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import CategoryTable from './Component/CategoryTable';
import CreateCategoryForm from './Component/CreateCategoryForm';
import CreateContractForm from './Component/CreateContractForm';
import DictionaryTable from './Component/DictionaryTable';
import { IObject } from '../../interfaces/IObject';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import DeleteIcon from '../../RiverusUI/Components/Icons/DeleteIcon';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import { fetchContractType } from '../../Services/Approval';
import {
  deleteCategory,
  deleteDataDictionary,
  fetchContractCategories,
  fetchDictionaryContractType,
} from '../../Services/dictionary';
import Scrollable from '../../UniversalComponents/Scrollable/scrollable';
import { QueryKeyGenerator } from '../../Utils/QueryKeyGenerator';

const initialFilter = {
  contractType: null,
  displayName: null,
  name: null,
};

const contractCardContent = [
  {
    title: 'What are Contract Types?',
    content:
      'Contract type denotes the type of contract under which the contract is classified. This is not necessarily the title of your contract. This is because, contracts are often titled differently but belong to the same genre. Illustratively, a Non-Disclosure Agreement may be titled, NDA, Confidentiality Agreement, Sensitive Personal Data or Information Agreement or even a Secrecy Agreement. ',
  },
  {
    title: 'What are Section names?',
    content:
      'Section Name refers to the section to which a paragraph or sentence of the contract belongs. Sections express the main legal concept behind these paragraphs and sentences in a contract.  Paragraphs or sentences are often called clauses of a contract. A section may have several clauses and they may even be spread across the whole contract. For example, Under the Section called Payment, there may be several clauses dealing with payment of consideration, payment of interest, payment of costs, etc. and all these clauses may not come under the same heading. However, from the point of view of legal concept, all these clauses express an obligation or a right in relation to payment of monies.  Riverus provides a standard suite of 18+ sections and will keep adding more. You can use the sections to define your data dictionary or contract standards. You can also create custom section names that may be more relevant to one or more types of contracts frequently seen in your organization.',
  },
  {
    title: 'What are Field names?',
    content:
      "Fields refer to what you write within a clause. This could be actual text of a clause, or any variable content found within a clause. For example, under the Payment Section, you may have a field called 'Amount. This is equivalent to the actual 'Price' (i.e. Rs or Dollar value) of goods sold or bought. Apart from variable/pointed information like price, the actual text of a clause can be a field. Illustratively, the text of the payment clause is represented as paymentclausetext. You can create field names for sections and contracts relevant to your industry. For example, under the Section: Payment appearing in a contract for sale of apartment, you may create additional fields as saleconsiderationclausetext (Sale Consideration Clause Text), saleconsiderationamount (Sale Consideration Clause Amount), earnestmoneyclausetext (Earnest Money Clause Text), refundofearnestmoneytimeline (Refund of Earnest Money Timeline) refundofearnestmoneyinterest (Refund of Earnest Money Interest). You can be as expressive as you want in defining the field names and their character (as in text, numerical, combination, list, etc).",
  },
];

const contractTableTabs = [
  { label: 'Contract Types', value: 'contract_types' },
  { label: 'Contract Categories', value: 'category' },
];

const AdminDataDictionary = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [openContractType, setOpenContractType] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [openContractCategory, setOpenContractCategory] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('contract_types');
  const [dataTableKey, setDataTableKey] = useState<number>(0);
  const [refreshKey, setRefreshKey] = useState(false);

  const queryClient = useQueryClient();

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPageNumber(0);
    setActiveTab(newValue);
    setFilters({});
  };

  const { data: contractData } = useQuery({
    queryKey: ['contract_types', QueryKeyGenerator.getChoiceFetchingQuery()],
    queryFn: fetchContractType,
    select: (response: any) => {
      return response?.results;
    },
    enabled: openContractType,
  });

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'admin-data-dictionary',
      sorting,
      pageNumber,
      filters,
      dataTableKey,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let sortingParam = '';
      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i].field}`;
          }
        }
      }
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${sortingParam}${filterParam}`;
      return fetchDictionaryContractType(params);
    },
    enabled: !!(activeTab === 'contract_types'),
  });

  const {
    data: categoryData,
    isLoading: categoryLoading,
    isFetching: categoryFetching,
  } = useQuery({
    queryKey: [
      'admin-contract-category',
      sorting,
      pageNumber,
      filters,
      dataTableKey,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let sortingParam = '';
      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i].field}`;
          }
        }
      }
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${sortingParam}${filterParam}`;
      return fetchContractCategories(params);
    },
    enabled: !!(activeTab === 'category'),
  });

  const { mutate: delete_data_dictionary } = useMutation({
    mutationKey: ['delete_Data_Dictionary'],
    mutationFn: deleteDataDictionary,
    onSuccess: (response: any) => {
      if (response?.error) {
        setIsOpenDeleteModal(true);
      } else {
        enqueueSnackbar('Data Dictionary deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['admin-data-dictionary'],
      });
      handleCloseDeleteModal();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Data Dictionary!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: delete_category } = useMutation({
    mutationKey: ['delete_category'],
    mutationFn: deleteCategory,
    onSuccess: (response: any) => {
      if (response?.error) {
        setIsOpenDeleteModal(true);
      } else {
        enqueueSnackbar('Contract Category deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['admin-contract-category'],
      });
      handleCloseDeleteModal();
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.error;
      enqueueSnackbar(
        `${responseData || 'Failed to delete Contract Category!'}`,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    },
  });

  const handleDelete = (ids: GridRowId[]) => {
    if (activeTab === 'contract_types') {
      delete_data_dictionary({ ids: ids });
    } else {
      delete_category({ category_ids: ids });
    }
  };

  const handleCloseNotDeletedModal = () => {
    setIsOpenDeleteModal(false);
    handleCloseDeleteModal();
  };

  const handleRefresh = useCallback(() => {
    setDataTableKey(Math.random());
    setFilters(initialFilter);
    setSorting([]);
    setRefreshKey((prevKey) => !prevKey);
  }, []);

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack direction="row" gap={2}>
          {contractCardContent.map((row: any, index: number) => (
            <Card
              key={index}
              style={{
                minWidth: '80px',
                fontSize: '14px',
                backgroundColor: '#E5E5E5',
                width: '32%',
                height: '190px',
              }}
            >
              <CardContent style={{ fontWeight: 900, color: '#1D1D1D' }}>
                {row.title}
              </CardContent>
              <CardContent
                style={{
                  marginTop: '-20px',
                  fontSize: '14px',
                  color: 'grey',
                  fontStyle: 'inherit',
                }}
                className="contractSectionContent"
              >
                <Scrollable maxHeight={100}>
                  <div>{row.content}</div>
                </Scrollable>
              </CardContent>
            </Card>
          ))}
        </Stack>

        <Stack gap="16px">
          <Stack direction="row" spacing={1}>
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'riPrimary.500',
                padding: '4px 14px',
                borderRadius: '6px',
                fontSize: '13px',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 1,
                alignItems: 'center',
                height: '4rem',
                textTransform: 'capitalize',
                minWidth: '8rem',

                '&:hover': {
                  backgroundColor: 'riPrimary.500',
                },
              }}
              onClick={() => {
                setOpenContractType(true);
              }}
            >
              <UploadFileIcon fontSize="small" sx={{ marginBottom: '6px' }} />
              Add New Contract Type
            </Button>
            <Button
              sx={{
                color: 'riPrimary.500',
                padding: '4px 14px',
                borderRadius: '6px',
                fontSize: '13px',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 1,
                alignItems: 'center',
                whiteSpace: 'nowrap',
                height: '4rem',
                textTransform: 'capitalize',
                minWidth: '8rem',
              }}
              variant="outlined"
              onClick={() => {
                setOpenContractCategory(true);
              }}
            >
              <UploadFileIcon fontSize="small" sx={{ marginBottom: '6px' }} />
              Add New Contract Category
            </Button>
          </Stack>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              {selectedRow?.length === 1 ? (
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => setOpenDeleteModal(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ) : (
                <Stack direction="column" alignItems="start">
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="contract tabs"
                  >
                    {contractTableTabs.map((tab: any, index: number) => (
                      <Tab
                        key={index}
                        label={tab.label}
                        value={tab.value}
                        {...a11yProps(tab.value)}
                      />
                    ))}
                  </Tabs>
                </Stack>
              )}
            </Box>
          </Box>
        </Stack>
        {activeTab === 'contract_types' ? (
          <DictionaryTable
            data={data}
            isLoading={isLoading || isFetching}
            pageNumber={pageNumber}
            setPageNumberChange={setPageNumber}
            setFilters={setFilters}
            setSorting={setSorting}
            filters={filters}
            setSelectedRow={setSelectedRow}
            contractData={contractData}
          />
        ) : (
          <CategoryTable
            data={categoryData}
            isLoading={categoryLoading || categoryFetching}
            pageNumber={pageNumber}
            setPageNumberChange={setPageNumber}
            setFilters={setFilters}
            setSorting={setSorting}
            filters={filters}
            setSelectedRow={setSelectedRow}
            refreshKey={refreshKey}
          />
        )}
      </Stack>
      <CreateContractForm
        open={openContractType}
        onClose={() => setOpenContractType(false)}
        setFilters={setFilters}
        setSorting={setSorting}
        initialFilter={initialFilter}
        setRefreshKey={setRefreshKey}
        setDataTableKey={setDataTableKey}
      />
      {openContractCategory && (
        <CreateCategoryForm
          open={openContractCategory}
          onClose={() => setOpenContractCategory(false)}
          setFilters={setFilters}
          setSorting={setSorting}
          initialFilter={initialFilter}
          setRefreshKey={setRefreshKey}
        />
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title={
            activeTab === 'contract_types'
              ? 'Delete Data Dictionary'
              : 'Delete Contract Categories'
          }
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => handleDelete(selectedRow)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected{' '}
              {activeTab === 'contract_types'
                ? 'data dictionary'
                : 'contract category '}{' '}
              will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
      {isOpenDeleteModal && (
        <ReusableConfirmationModal
          open={isOpenDeleteModal}
          onClose={handleCloseNotDeletedModal}
          title="Delete Dialog"
          renderAction={
            <Stack alignItems="end">
              <Button
                onClick={handleCloseNotDeletedModal}
                variant="contained"
                style={{ padding: '5px 15px' }}
              >
                Ok
              </Button>
            </Stack>
          }
        >
          <Stack spacing={0.5}>
            <Typography>
              This{' '}
              {activeTab === 'contract_types'
                ? 'data dictionary'
                : 'contract category '}{' '}
              cannot be deleted
            </Typography>
            <Typography>
              because it is associated with drafts or policies.
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default AdminDataDictionary;
