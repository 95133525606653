import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import { Box, Button, Chip, IconButton, Stack } from '@mui/material';
import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

import AssigneeDrawer from './AssigneeDrawer/AssigneeDrawer';
import AvatarList from './AvatarList';
import CheckListComponent from './DraftChecklist/CheckListComponent';
import { sortListAlphabetical } from './Helper';
import SignatoryDrawer from './SignatoryDrawer/SignatoryDrawer';
import VersionDrawer from './VersionDrawer/VersionDrawer';
import { useUserData } from '../../App/Component/UserDataProvider';
import { IObject } from '../../interfaces/IObject';
import DrawerComponent from '../../RiverusUI/Components/DrawerComponent';
import OverflowTip from '../../RiverusUI/Components/OverflowTip';
import DataGridTable from '../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../RiverusUI/DataGrid/GridCellExpand';
import TableAssigneeSelectInput from '../../RiverusUI/DataGrid/TableAssigneeSelectInput';
import TableChipList from '../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../../RiverusUI/DataGrid/TableSearchSelectInput';
import {
  fetchAllContractCategory,
  fetchGroups,
  getUsers,
} from '../../Services/Approval';
import { fetchProjects } from '../../Services/DocumentLibrary';
import { fetchAssignee, fetchChecklistData } from '../../Services/Draft';
import { getContractTypes } from '../../Services/Template';
import { QueryKeyGenerator } from '../../Utils/QueryKeyGenerator';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
  handleRowsSelection?: any;
  activeHeader?: any;
  refreshKey?: boolean;
  showNoData?: boolean;
  isRenewed?: boolean;
}

const tableButtonStyle = {
  padding: 0,
  justifyContent: 'start',
  ':hover': { background: 'transparent' },
};

const DraftTable = (props: Props) => {
  const {
    setSelectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
    handleRowsSelection,
    activeHeader,
    refreshKey,
    showNoData,
    isRenewed,
  } = props;

  const [searchDraft, setSearchDraft] = useState<boolean>(false);
  const [searchContract, setSearchContractType] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  const [searchAssignee, setSearchAssignee] = useState<boolean>(false);
  const [searchCategory, setSearchCategory] = useState<boolean>(false);
  const [searchProjects, setSearchProjects] = useState<boolean>(false);
  const [openVersionDrawer, setOpenVersionDrawer] = useState<boolean>(false);
  const [draftRowData, setDraftRowData] = useState<any>({});
  const [openAssigneeDrawer, setOpenAssigneeDrawer] = useState<boolean>(false);
  const [openSignatoryDrawer, setOpenSignatoryDrawer] =
    useState<boolean>(false);
  const handleCloseAssigneeDrawer = () => {
    setOpenAssigneeDrawer(false);
  };
  const handleCloseSignatoryDrawer = () => {
    setOpenSignatoryDrawer(false);
  };
  const [openChecklist, setOpenChecklist] = useState<boolean>(false);
  const [selectedDraftData, setSelectedDraftData] = useState<any>();

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response) => {
      const users = response?.users || [];
      return users.map((user: any) => ({
        ...user,
        name: `${user.first_name} ${user.last_name}`,
      }));
    },
    enabled: !isRenewed,
  });

  const { data: getDraftCheckList } = useQuery({
    queryKey: ['draft_checkLists', selectedDraftData?.draftID],
    queryFn: () => fetchChecklistData(selectedDraftData?.draftID),
    select: (response: any) => {
      const filterNotDeletedItem = response?.results?.filter(
        (item: any) => !item?.deleted_status
      );
      return filterNotDeletedItem;
    },
    enabled: !!openChecklist && !!selectedDraftData?.draftID && !isRenewed,
  });

  const { data: projectData } = useQuery({
    queryKey: ['project_data'],
    queryFn: async () => await fetchProjects(),
    select: (response: any) =>
      response.results.filter((data: any) => data.name !== ''),
  });

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: contracts } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: getContractTypes,
    select: (response: any) => response.results,
    enabled: !isRenewed,
  });

  const { data: draftAssignee } = useQuery({
    queryKey: ['draft-assignee'],
    queryFn: fetchAssignee,
    select: (response: any) => response.results,
    enabled: !isRenewed,
  });

  const handleVersionDrawerClose = () => {
    setOpenVersionDrawer(false);
    setDraftRowData({});
  };

  const handleVersionDrawerOpen = (data: any) => {
    setOpenVersionDrawer(true);
    setDraftRowData(data);
  };

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups,
    enabled: !isRenewed,
  });

  const { data: contractCategory } = useQuery({
    queryKey: ['draft_contract_Category'],
    queryFn: fetchAllContractCategory,
    select: (response: any) => response.results || [],
    enabled: !isRenewed,
  });

  const { user_id } = useUserData();

  const isOwner = React.useCallback(
    (owners: any) => {
      const index = owners.findIndex((owner: any) => owner?.id === user_id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [user_id]
  );

  const canAccessDraft = React.useCallback(
    (data: any) => {
      const approvalData = data?.approvers?.filter(
        (item: any) => item?.id === user_id && item?.user_type !== 'external'
      );
      const collaboratorData = data?.collaborators?.filter(
        (item: any) => item?.id === user_id && item?.user_type !== 'external'
      );
      const signatoryData = data?.signatories?.filter(
        (item: any) => item?.id === user_id && item?.user_type !== 'external'
      );
      const preSignatoryData = data?.pre_signatories?.filter(
        (item: any) => item?.id === user_id && item?.user_type !== 'external'
      );
      const owner = isOwner(data?.owners);
      const creator = data?.creator?.id === user_id;
      const draftViewer = data?.draft_viewers?.filter(
        (item: any) => item === user_id
      );
      if (
        owner ||
        approvalData.length ||
        collaboratorData?.length ||
        signatoryData?.length ||
        preSignatoryData?.length ||
        creator ||
        draftViewer?.length
      ) {
        return true;
      }
      return false;
    },
    [user_id, isOwner]
  );

  const getSignatory = React.useCallback(
    (data: any) => {
      const signatoryData = data?.signatories?.find(
        (item: any) => item?.id === user_id && item?.user_type !== 'external'
      );
      return signatoryData;
    },
    [user_id]
  );

  const handleContractNameClick = React.useCallback(
    (canAccess: boolean, version: string, id: string, signatory: any) => {
      if (canAccess) {
        if (signatory) {
          window.open(
            `/draft/${version}/${id}?signatory_id=${signatory?.signatory_id || signatory?.email}`
          );
        } else {
          window.open(`/draft/${version}/${id}`);
        }
      } else {
        enqueueSnackbar('You are not assigned any role for this draft!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [data]
  );

  const handleContractTypeClick = React.useCallback(
    (contractType: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contractType: [contractType?.displayName],
      }));
      if (contractType?.displayName) {
        setPageNumberChange(0);
      }
      setSearchContractType(true);
    },
    [handleFilterChange]
  );

  const handleCategoryClick = React.useCallback(
    (category: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contract_category: [category?.id],
      }));
      if (category?.id) {
        setPageNumberChange(0);
      }
      setSearchCategory(true);
    },
    [handleFilterChange]
  );

  const handleStatusClick = React.useCallback(
    (status: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        status: [status],
      }));
      if (status) {
        setPageNumberChange(0);
      }
      setSearchStatus(true);
    },
    [handleFilterChange]
  );

  const handleGroupClick = React.useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const handleProjectClick = React.useCallback(
    (projects: any) => {
      handleFilterChange('projects', projects);
      setSearchProjects(true);
    },
    [handleFilterChange]
  );

  const handleOpenChecklistDrawer = (data: any) => {
    setSelectedDraftData(data);
    setOpenChecklist(true);
  };

  useEffect(() => {
    if (!filters?.name) {
      setSearchDraft(false);
    }
    if (!filters?.users && !filters?.roles) {
      setSearchAssignee(false);
    }
    if (!filters?.groups) {
      setSearchGroups(false);
    }
    if (!filters?.status) {
      setSearchStatus(false);
    }
  }, [filters, refreshKey]);

  useEffect(() => {
    if (!filters?.contractType) {
      setSearchContractType(false);
    }
    if (!filters?.contract_category) {
      setSearchCategory(false);
    }
    if (!filters?.projects) {
      setSearchProjects(false);
    }
  }, [activeHeader, refreshKey]);

  const statusOptions = [
    { name: 'Automatically Dropped' },
    { name: 'Committed for Signature' },
    { name: 'Contract Executed Successfully' },
    { name: 'Counter Party Draft Uploaded' },
    { name: 'Draft Creation Pending' },
    { name: 'Draft Upload Pending' },
    { name: 'Dropped' },
    { name: 'Internal Draft Created' },
    { name: 'Requisition Approval Pending' },
    { name: 'Pre Signatories Approval Pending' },
    { name: 'Deviation Approval Pending' },
    { name: 'Signature Aborted' },
    { name: 'Signature Pending' },
  ];

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'contractName',
        headerName: 'Title',
        minWidth: 200,
        flex: 1,
        sortable: !searchDraft,
        renderHeader: () => {
          return searchDraft ? (
            <TableSearchInputComponent
              key="draft_name"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, name: null }));
                setSearchDraft(false);
              }}
              placeholder="Search Title"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) => {
                if (e.target.value?.length) {
                  handleFilterChange('name', e.target.value);
                }
              }}
            />
          ) : (
            <Stack onClick={(e) => e.stopPropagation()}>
              <TableHeaderWithSearch
                title="Title"
                setIsSearch={setSearchDraft}
              />
            </Stack>
          );
        },
        renderCell: (Params: any) => {
          const canAccess = canAccessDraft(Params.row);
          const signatory = getSignatory(Params.row);
          return (
            <GridCellExpand
              value={Params?.row?.contractName}
              width={Params.colDef.computedWidth}
              onClick={() =>
                handleContractNameClick(
                  canAccess,
                  Params.row.version,
                  Params.row?.id,
                  signatory
                )
              }
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      {
        field: 'projects',
        headerName: 'Project',
        minWidth: 260,
        flex: 1,
        sortable: false,
        renderCell: (params: any) => {
          const selectedFilters: any = filters?.projects || [];
          let reOrderProjects = params?.row?.projects || [];
          if (selectedFilters?.length) {
            const filterSelectedGroups = reOrderProjects?.filter((item: any) =>
              selectedFilters?.includes(item?.name)
            );
            const filterNotSelectedGroups = reOrderProjects?.filter(
              (item: any) => !selectedFilters?.includes(item?.name)
            );
            reOrderProjects = [
              ...filterSelectedGroups,
              ...filterNotSelectedGroups,
            ];
          }

          return (
            <Stack>
              {reOrderProjects.length > 0 && (
                <TableChipList
                  id={`projects-${params?.row?.id}`}
                  labelKey="name"
                  list={reOrderProjects}
                  chipColor="#CDE7BD"
                  title={`${params?.row?.projects} project`}
                  onClick={handleProjectClick}
                />
              )}
            </Stack>
          );
        },
        renderHeader: () => {
          return searchProjects ? (
            <TableSearchInputComponent
              key="projects"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, projects: null }));
                setSearchProjects(false);
              }}
              placeholder="Search Project"
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.projects)
                      ? (filters?.projects?.[0] as string)
                      : 'projects'
                  }
                  options={sortListAlphabetical(projectData)}
                  label="Select Project"
                  valueKey="name"
                  value={filters?.projects ? filters?.projects : []}
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('projects', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        projects: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              width="80px"
              title="Project"
              setIsSearch={setSearchProjects}
            />
          );
        },
      },
      {
        field: 'contractType',
        headerName: 'Contract Type',
        minWidth: 260,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Chip
            sx={{
              background: '#C4DBFF',
              color: 'riSecondary.900',
            }}
            label={
              <OverflowTip value={params?.row?.contractType?.displayName} />
            }
            onClick={() => handleContractTypeClick(params?.row?.contractType)}
          />
        ),
        renderHeader: () => {
          return searchContract ? (
            <TableSearchInputComponent
              key="contractType"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contractType: null }));
                setSearchContractType(false);
              }}
              placeholder="Search contract type"
              selectedValue={filters?.contractType ? filters.contractType : []}
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.contractType)
                      ? (filters?.contractType?.[0] as string)
                      : 'contractType'
                  }
                  label="Select contract type"
                  labelKey="displayName"
                  options={sortListAlphabetical(contracts, 'displayName')}
                  valueKey="displayName"
                  value={filters?.contractType ? filters.contractType : []}
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('contractType', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        contractType: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Type"
              setIsSearch={setSearchContractType}
            />
          );
        },
      },
      {
        field: 'contract_category',
        headerName: 'Contract Category',
        minWidth: 260,
        flex: 1,
        sortable: false,
        renderCell: (params) =>
          params?.row?.contract_category?.name && (
            <Chip
              sx={{
                background: '#C4DBFF',
                color: 'riSecondary.900',
              }}
              label={
                <OverflowTip value={params?.row?.contract_category?.name} />
              }
              onClick={() =>
                handleCategoryClick(params?.row?.contract_category)
              }
            />
          ),
        renderHeader: () => {
          return searchCategory ? (
            <TableSearchInputComponent
              key="contract_category"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contract_category: null }));
                setSearchCategory(false);
              }}
              placeholder="Search contract category"
              selectedValue={
                filters?.contract_category ? filters.contract_category : []
              }
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.contract_category)
                      ? (filters?.contract_category?.[0] as string)
                      : 'contract_category'
                  }
                  label="Select contract category"
                  options={sortListAlphabetical(contractCategory)}
                  valueKey="id"
                  value={
                    filters?.contract_category ? filters.contract_category : []
                  }
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('contract_category', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        contract_category: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Category"
              width="140px"
              setIsSearch={setSearchCategory}
            />
          );
        },
      },
      {
        field: 'version',
        headerName: 'Version',
        minWidth: 90,
        flex: 1,
        renderCell: (Params: any) => (
          <Button
            sx={tableButtonStyle}
            onClick={() => {
              handleVersionDrawerOpen(Params?.row);
            }}
          >
            {Params?.row?.version}
          </Button>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 260,
        flex: 1,
        sortable: !searchStatus,
        renderHeader: () => {
          return searchStatus ? (
            <TableSearchInputComponent
              key="status"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, status: null }));
                setSearchStatus(false);
              }}
              placeholder="Search Status"
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.status)
                      ? (filters?.status?.[0] as string)
                      : 'status'
                  }
                  options={sortListAlphabetical(statusOptions)}
                  label="Select Status"
                  valueKey="name"
                  value={filters?.status ? filters.status : []}
                  onChange={(value: string[]) => {
                    if (value.length) {
                      handleFilterChange('status', value);
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <Stack onClick={(e) => e.stopPropagation()}>
              <TableHeaderWithSearch
                title="Status"
                setIsSearch={setSearchStatus}
              />
            </Stack>
          );
        },
        renderCell: (Params) => {
          const handleClick = (draftRowData: any) => {
            setOpenSignatoryDrawer(true);
            setDraftRowData(draftRowData);
          };
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Chip
                sx={{
                  background: '#88305f3d',
                  color: 'riSecondary.900',
                }}
                label={<OverflowTip value={Params?.row?.status} />}
                onClick={() => handleStatusClick(Params?.row?.status)}
              />
              {Params?.row?.status === 'Signature Pending' && (
                <IconButton onClick={() => handleClick(Params?.row)}>
                  <CallMadeIcon style={{ color: '#6D264C' }} />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'checklist',
        headerName: 'Checklist',
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (Params: any) => (
          <Button
            sx={tableButtonStyle}
            onClick={() => handleOpenChecklistDrawer(Params?.row)}
          >
            {Params?.row?.checklist}
          </Button>
        ),
      },
      {
        field: 'groups',
        headerName: 'Groups',
        maxWidth: 300,
        width: 260,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          const selectedFilters: any = filters?.groups || [];
          let reOrderGroups = params?.row?.groups || [];
          if (selectedFilters?.length) {
            const filterSelectedGroups = reOrderGroups?.filter((item: any) =>
              selectedFilters?.includes(item)
            );
            const filterNotSelectedGroups = reOrderGroups?.filter(
              (item: any) => !selectedFilters?.includes(item)
            );
            reOrderGroups = [
              ...filterSelectedGroups,
              ...filterNotSelectedGroups,
            ];
          }
          return (
            <TableChipList
              id={`groups-${params?.row?.id}`}
              labelKey=""
              valueKey=""
              chipColor="#DCBDE7"
              list={reOrderGroups}
              onClick={handleGroupClick}
            />
          );
        },
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="groups"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, groups: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Groups"
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.groups)
                      ? (filters?.groups?.[0] as string)
                      : 'groups'
                  }
                  options={sortListAlphabetical(groupData || [])}
                  label="Select Groups"
                  valueKey="name"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) => {
                    if (value.length) {
                      handleFilterChange('groups', value);
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Groups"
              setIsSearch={setSearchGroups}
            />
          );
        },
      },
      {
        field: 'assignee',
        headerName: 'Assigned to',
        maxWidth: searchAssignee ? 660 : 300,
        width: searchAssignee ? 600 : 250,
        sortable: false,
        minWidth: 150,
        renderHeader: () => {
          return searchAssignee ? (
            <TableAssigneeSelectInput
              setIsSearch={setSearchAssignee}
              onChangeAssignee={(value: string[]) => {
                handleFilterChange('users', value);
              }}
              onChangeRoles={(value: string[]) => {
                handleFilterChange('roles', value);
              }}
              isLoading={isLoading}
            />
          ) : (
            <TableHeaderWithSearch
              title="Assigned To"
              setIsSearch={setSearchAssignee}
            />
          );
        },
        renderCell: (Params) => {
          const signatories = Params?.row?.signatories || [];
          const pre_signatories = Params?.row?.pre_signatories || [];
          const collaborators =
            Params?.row?.signatories?.length === 0
              ? Params?.row?.collaborators
              : [];
          const owners = Params?.row?.owners || [];
          const approvers_ = Params?.row?.approvers || [];

          let assigneeData: any = [];
          const approvers = approvers_.map((i: any) => {
            i['assigned_role'] = ['Approver'];
            return i;
          });
          const collaborator = collaborators.map((i: any) => {
            i['assigned_role'] = ['Collaborators'];
            return i;
          });
          const signatoriesData = signatories.map((i: any) => {
            i['assigned_role'] = ['Signatories'];
            return i;
          });
          const preSignatories = pre_signatories?.map((i: any) => {
            i['assigned_role'] = ['Pre Signatories'];
            return i;
          });
          const ownersData = owners?.map((i: any) => {
            i['assigned_role'] = ['Owners'];
            return i;
          });

          let creator: any = null;
          if (Params?.row?.creator?.roles?.includes('Creators')) {
            creator = Params?.row?.creator;
            creator['assigned_role'] = ['Creators'];
          }

          // For AvatarList data
          assigneeData = [
            ...collaborator,
            ...approvers,
            ...signatoriesData,
            ...preSignatories,
            ...ownersData,
            ...(creator ? [creator] : []),
          ];
          const preSignatoryActiveStatus = pre_signatories?.map(
            (assignee: any) => {
              const matchingUser = authorData?.find(
                (user: any) =>
                  user?.email === assignee?.email || user?.id === assignee?.id
              );
              const isDisabled = matchingUser && !matchingUser.is_active;
              return { ...assignee, isDisabled };
            }
          );
          const signatoryActiveStatus = signatoriesData?.map(
            (signatory: any) => {
              const matchingUser = authorData?.find(
                (user: any) =>
                  user?.email === signatory?.email || user?.id === signatory?.id
              );
              const isDisabled = matchingUser && !matchingUser.is_active;
              return { ...signatory, isDisabled };
            }
          );
          assigneeData = assigneeData?.map((data: any) => {
            const preSignatory = preSignatoryActiveStatus?.find(
              (item: any) =>
                item?.email === data?.email || item?.id === data?.id
            );
            const signatory = signatoryActiveStatus?.find(
              (item: any) =>
                item?.email === data?.email || item?.id === data?.id
            );
            if (preSignatory) {
              data.isDisabled = preSignatory?.isDisabled;
            }
            if (signatory) {
              data.isDisabled = signatory?.isDisabled;
            }
            return data;
          });
          const ids = assigneeData.map(
            ({ id, email }: { id?: string; email?: string }) => id || email
          );
          assigneeData = assigneeData.filter(
            ({ id, email }: { id?: string; email?: string }, index: number) =>
              !ids.includes(id || email, index + 1)
          );
          assigneeData = assigneeData.map((data: any) => {
            if (
              data?.user_type === 'external' ||
              data?.assignee_type === 'external'
            ) {
              const firstName = data?.first_name;
              const lastName =
                data?.first_name === data?.last_name ? '' : data?.last_name;
              return { ...data, first_name: firstName, last_name: lastName };
            }
            return data;
          });

          const handleClick = (draftRowData: any) => {
            setOpenAssigneeDrawer(true);
            setDraftRowData(draftRowData);
          };
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box>
                {assigneeData?.length > 0 ? (
                  <AvatarList data={assigneeData} Params={Params} />
                ) : (
                  <Button onClick={() => handleClick(Params?.row)}>
                    Start Assignee
                  </Button>
                )}
              </Box>
              {assigneeData?.length > 0 && (
                <IconButton onClick={() => handleClick(Params?.row)}>
                  <CallMadeIcon style={{ color: '#6D264C' }} />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'Pending Since',
        headerName: 'Pending Since',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <Box color="#6D264C">
              {params?.row?.pending_since > 1
                ? `${params?.row?.pending_since} days`
                : `${params?.row?.pending_since} day`}
            </Box>
          );
        },
      },
    ],
    [
      searchDraft,
      setFilters,
      handleFilterChange,
      canAccessDraft,
      handleContractNameClick,
      handleContractTypeClick,
      handleCategoryClick,
      handleProjectClick,
      searchProjects,
      searchContract,
      searchCategory,
      filters,
      contracts,
      handleGroupClick,
      searchGroups,
      groupData,
      searchAssignee,
      draftAssignee,
      searchStatus,
      isLoading,
    ]
  );

  const handleCLoseChecklist = () => {
    setOpenChecklist(false);
  };

  return (
    <>
      <DataGridTable
        rows={showNoData ? [] : (data?.results ?? [])}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        checkboxSelection
        handleRowsSelection={handleRowsSelection}
      />

      <DrawerComponent
        open={openVersionDrawer}
        onClose={handleVersionDrawerClose}
        drawerHeader="Version History"
        sx={{
          '& .MuiDrawer-paper': {
            width: '550px',
          },
        }}
      >
        <VersionDrawer
          draftRowData={draftRowData}
          setDraftRowData={setDraftRowData}
          handleVersionDrawerClose={handleVersionDrawerClose}
        />
      </DrawerComponent>

      <DrawerComponent
        open={openChecklist}
        onClose={handleCLoseChecklist}
        sx={{
          '& .MuiDrawer-paper': {
            width: '550px',
          },
        }}
        drawerHeader={`Contract checklist: ${selectedDraftData?.contractName}`}
      >
        <CheckListComponent
          selectedDraftData={selectedDraftData}
          openChecklist={openChecklist}
          getDraftCheckList={getDraftCheckList}
        />
      </DrawerComponent>

      <DrawerComponent
        drawerHeader={`Assign Co-Owner : ${draftRowData?.contractName}`}
        open={openAssigneeDrawer}
        onClose={handleCloseAssigneeDrawer}
      >
        <AssigneeDrawer row={draftRowData} />
      </DrawerComponent>

      <DrawerComponent
        drawerHeader={` Signatory details : ${draftRowData?.contractName}`}
        open={openSignatoryDrawer}
        onClose={handleCloseSignatoryDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: '650px',
          },
        }}
      >
        <SignatoryDrawer row={draftRowData} />
      </DrawerComponent>
    </>
  );
};

export default DraftTable;
