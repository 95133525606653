import React, { useCallback, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';

import AddGroupsModal from './AddGroupsModal';
import AddRemoveRolesModal from './AddRemoveRolesModal';
import CreateUsersModal from './CreateUsersModal';
import PasswordModal from './PasswordModal';
import UsersTable from './UsersTable';
import { IObject } from '../interfaces/IObject';
import { getAllUsers } from '../Services/Approval';
import { fetchKeycloakGroups, fetchKeycloakRoles } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

const initialFilter = {
  users: null,
  roles: null,
  groups: null,
};

const UserManagement = () => {
  const [pageNumber, setPageNumberChange] = useState<number>(0);
  const [filters, setFilters] = useState<IObject>({
    roles: null,
    groups: null,
  });
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const [usersTableKey, setUsersTableKey] = useState<number>(0);
  const [openCreateApproval, setOpenCreateApproval] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [openAddGroup, setOpenAddGroup] = useState<boolean>(false);
  const [openAddRole, setOpenAddRole] = useState<boolean>(false);

  const {
    data: usersData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      QueryKeyGenerator.getRequestApproval(),
      'all-users',
      usersTableKey,
      pageNumber,
      filters,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${filterParam}`;
      return getAllUsers(params);
    },
    select: (response: any) => {
      return response;
    },
  });

  const { data: groupData } = useQuery({
    queryKey: ['keycloak_groups'],
    queryFn: fetchKeycloakGroups,
  });

  const { data: rolesData } = useQuery({
    queryKey: ['keycloak_roles'],
    queryFn: fetchKeycloakRoles,
  });

  const filteredRolesData = useMemo(() => {
    return Array.isArray(rolesData)
      ? rolesData?.filter(
          (role: any) =>
            role?.name !== 'uma_authorization' &&
            role?.name !== 'offline_access' &&
            !role?.name?.startsWith('default-roles-')
        )
      : [];
  }, [rolesData]);

  const handleClose = () => {
    setEditDetails('');
    setOpenCreateApproval(false);
  };

  const handleCloseCredentialsConfirmation = () => {
    setEditDetails('');
    setOpenPasswordModal(false);
  };

  const handleCredentials = (data: any) => {
    setEditDetails(data);
    setOpenPasswordModal(true);
  };

  const handleEdit = (data: any) => {
    setEditDetails(data);
    setOpenCreateApproval(true);
  };

  const handleRefresh = useCallback(() => {
    setUsersTableKey(Math.random());
    setFilters(initialFilter);
  }, []);

  const renderTable = React.useCallback(
    () => (
      <UsersTable
        key={usersTableKey}
        data={usersData?.results}
        isLoading={isLoading || isFetching}
        rowCount={usersData?.count}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setFilters={setFilters}
        handleCredentials={handleCredentials}
        editApproval={handleEdit}
        filters={filters}
        editDetails={editDetails}
        groupData={groupData}
        rolesData={filteredRolesData}
        setSelectedRow={setSelectedRow}
      />
    ),
    [
      usersData,
      handleCredentials,
      isFetching,
      isLoading,
      pageNumber,
      usersTableKey,
    ]
  );

  const selectedData = useMemo(() => {
    if (selectedRow.length === 1) {
      return (
        usersData?.results?.find((user: any) => user?.id === selectedRow[0]) ||
        []
      );
    }
    return null;
  }, [selectedRow, usersData]);

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="24px">
        <Stack direction="column" spacing="10px">
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            Create
          </Typography>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'riPrimary.500',
              padding: '4px 14px',
              borderRadius: '6px',
              fontSize: '13px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',
              height: '4rem',
              textTransform: 'capitalize',
              minWidth: '8rem',
              margin: '0',
              '&:hover': {
                backgroundColor: 'riPrimary.500',
              },
              '&:disabled': {
                border: '2px solid rgba(0, 0, 0, 0.26)',
                background: 'unset',
              },
            }}
            onClick={() => {
              setOpenCreateApproval(true);
            }}
          >
            <AddCircleOutlineRoundedIcon
              fontSize="small"
              sx={{ marginBottom: '6px' }}
            />
            New User
          </Button>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              {selectedRow.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  {selectedRow.length === 1 && (
                    <Tooltip
                      title={
                        !selectedData?.is_active
                          ? "This action can't be performed because the selected user is inactive."
                          : ''
                      }
                      arrow
                    >
                      <Stack>
                        <Button
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setEditDetails(selectedData);
                            setOpenAddGroup(true);
                          }}
                          disabled={!selectedData?.is_active}
                        >
                          Add Groups
                        </Button>
                      </Stack>
                    </Tooltip>
                  )}
                  {selectedRow.length === 1 && (
                    <Tooltip
                      title={
                        !selectedData?.is_active
                          ? "This action can't be performed because the selected user is inactive."
                          : ''
                      }
                      arrow
                    >
                      <Stack>
                        <Button
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setEditDetails(selectedData);
                            setOpenAddRole(true);
                          }}
                          disabled={!selectedData?.is_active}
                        >
                          Role Mapping
                        </Button>
                      </Stack>
                    </Tooltip>
                  )}
                </Box>
              ) : (
                <IconButton onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              )}
            </Box>
            {renderTable()}
          </Box>
        </Stack>
      </Stack>
      {openCreateApproval && (
        <CreateUsersModal
          open={openCreateApproval}
          onClose={handleClose}
          editDetails={editDetails}
          usersTableKey={usersTableKey}
          setUsersTableKey={setUsersTableKey}
          setFilters={setFilters}
          initialFilter={initialFilter}
        />
      )}
      {openPasswordModal && (
        <PasswordModal
          open={openPasswordModal}
          onClose={handleCloseCredentialsConfirmation}
          editDetails={editDetails}
        />
      )}
      {openAddGroup && (
        <AddGroupsModal
          open={openAddGroup}
          onClose={() => {
            setEditDetails('');
            setOpenAddGroup(false);
          }}
          groupData={groupData}
          editDetails={editDetails}
          usersTableKey={usersTableKey}
        />
      )}
      {openAddRole && (
        <AddRemoveRolesModal
          open={openAddRole}
          onClose={() => {
            setEditDetails('');
            setOpenAddRole(false);
          }}
          rolesData={filteredRolesData}
          editDetails={editDetails}
          usersTableKey={usersTableKey}
        />
      )}
    </Stack>
  );
};

export default UserManagement;
