import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import { sortListAlphabetical } from '../Draft/Component/Helper';
import { IObject } from '../interfaces/IObject';
import DataGridTable from '../RiverusUI/DataGrid/DataGridTable';
import TableAsyncSelectInput from '../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../RiverusUI/DataGrid/TableSearchInputComponent';
import { fetchGroups } from '../Services/Approval';
import { getTrackDetails } from '../Services/authApi';

const Sessions = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filters, setFilters] = useState<any>({});
  const [searchUser, setSearchUser] = useState<boolean>(false);
  const [searchIPAddress, setSearchIPAddress] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups,
  });

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumber(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleGroupClick = React.useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const { data: trackDetails, isPending } = useQuery({
    queryKey: ['track_details', pageNumber, sorting, filters],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }

      let sortingParam = '';

      if (sorting?.length > 0) {
        for (let i = 0; i < sorting?.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i]?.field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i]?.field}`;
          }
        }
      } else {
        sortingParam = '';
      }
      const page = pageNumber + 1;
      const params = `?page=${page}${sortingParam}${filterParam}`;
      return getTrackDetails(params);
    },
  });

  const columns = [
    {
      field: 'logged_in_by__first_name',
      headerName: 'User',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchUser ? (
          <TableSearchInputComponent
            key="user"
            setIsSearch={() => {
              setFilters((prev: any) => ({ ...prev, search: null }));
              setSearchUser(false);
            }}
            placeholder="Search User"
            handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
              handleFilterChange('search', e.target.value)
            }
          />
        ) : (
          <TableHeaderWithSearch title="User" setIsSearch={setSearchUser} />
        );
      },
      renderCell: (params: any) => {
        const firstName = params?.row?.logged_in_by?.first_name;
        const lastName = params?.row?.logged_in_by?.last_name;
        return `${firstName} ${lastName}`;
      },
    },
    {
      field: 'groups',
      headerName: 'Groups',
      maxWidth: 300,
      width: 250,
      sortable: false,
      minWidth: 150,
      renderCell: (params: any) => {
        const selectedFilters: any = filters?.groups || [];
        let reOrderGroups = params?.row?.logged_in_by?.groups || [];
        if (selectedFilters?.length) {
          const filterSelectedGroups = reOrderGroups?.filter((item: any) =>
            selectedFilters?.includes(item)
          );
          const filterNotSelectedGroups = reOrderGroups?.filter(
            (item: any) => !selectedFilters?.includes(item)
          );
          reOrderGroups = [...filterSelectedGroups, ...filterNotSelectedGroups];
        }
        return (
          <TableChipList
            id={`groups-${params?.row?.ip_address}`}
            labelKey=""
            valueKey=""
            chipColor="#DCBDE7"
            list={reOrderGroups}
            onClick={handleGroupClick}
          />
        );
      },
      renderHeader: () => {
        return searchGroups ? (
          <TableSearchInputComponent
            key="groups"
            setIsSearch={() => {
              setFilters((prev: any) => ({ ...prev, groups: null }));
              setSearchGroups(false);
            }}
            placeholder="Search Groups"
            renderCustomInput={() => (
              <TableAsyncSelectInput
                key={
                  isArray(filters?.groups)
                    ? (filters?.groups?.[0] as string)
                    : 'groups'
                }
                options={sortListAlphabetical(groupData || [])}
                label="Select Groups"
                valueKey="name"
                value={filters?.groups ? filters.groups : []}
                onChange={(value: string[]) =>
                  handleFilterChange('groups', value)
                }
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch title="Groups" setIsSearch={setSearchGroups} />
        );
      },
    },
    {
      field: 'logged_date',
      headerName: 'Login Date & Time',
      minWidth: 90,
      flex: 1,
      renderCell: (params: any) =>
        dayjs(params?.row?.logged_date)?.format('DD/MM/YYYY, h:mm A'),
    },
    {
      field: 'ip_address',
      headerName: 'IP Address',
      minWidth: 90,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchIPAddress ? (
          <TableSearchInputComponent
            key="ipAddress"
            setIsSearch={() => {
              setFilters((prev: any) => ({ ...prev, search: null }));
              setSearchIPAddress(false);
            }}
            placeholder="Search IP Address"
            handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
              handleFilterChange('search', e.target.value)
            }
          />
        ) : (
          <TableHeaderWithSearch
            title="IP Address"
            setIsSearch={setSearchIPAddress}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Typography
        sx={{
          fontSize: '25px',
          fontWeight: 'bold',
          color: '#88305F',
          mb: 3,
        }}
      >
        Sessions
      </Typography>

      <DataGridTable
        rows={trackDetails?.results || []}
        columns={columns}
        rowCount={trackDetails?.count}
        isLoading={isPending}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumber}
        setSorting={setSorting}
        checkboxSelection={false}
        getRowId={(row) => row?.logged_date}
      />
    </React.Fragment>
  );
};

export default Sessions;
