import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  // CircularProgress,
  // CircularProgressProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isArray } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import ContractingPartiesList from './ContractingPartiesList';
import TableTooltip from './TableTooltip';
import { useUserData } from '../../../App/Component/UserDataProvider';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import { IObject } from '../../../interfaces/IObject';
import DataGridTable from '../../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../../RiverusUI/DataGrid/GridCellExpand';
// import TableAsyncSelectInput from '../../../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../../../RiverusUI/DataGrid/TableSearchSelectInput';
import { fetchGroups, fetchProjects } from '../../../Services/DocumentLibrary';
import { getContractTypes } from '../../../Services/Template';
import { QueryKeyGenerator } from '../../../Utils/QueryKeyGenerator';
// import { statusFilterList } from '../DocLibStaticData';
import { useDocumentLibrary } from '../DocumentLibraryProvider';

interface Props {
  tableData: any;
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
  isLoading: boolean;
  pageNumber: number;
  refreshKey?: boolean;
  selectedRowData?: any;
  onStatusChange?: any;
}

interface TooltipTitleParams {
  isProcessing: boolean;
  notStarted: boolean;
  failed: boolean;
  progress: number;
}

interface CellStyleParams {
  notStarted: boolean;
  isProcessing: boolean;
}

const DocumentLibraryTable: React.FC<Props> = ({
  tableData,
  setSelectedRow,
  setPageNumberChange,
  setFilters,
  filters,
  isLoading,
  pageNumber,
  setSorting,
  refreshKey,
  selectedRowData,
  onStatusChange,
}) => {
  const { activeHeader } = useDocumentLibrary();
  const [searchContract, setSearchContract] = useState<boolean>(false);
  const [searchContractType, setSearchContractType] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [searchProjects, setSearchProjects] = useState<boolean>(false);
  const [searchContractingParties, setSearchContractingParties] =
    useState<boolean>(false);
  // const [searchStatus, setSearchStatus] = useState<boolean>(false);
  const [contractStatus, setContractStatus] = useState<any>([]);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: contracts } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: getContractTypes,
    select: (response: any) => response.results,
  });

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups,
  });

  const { data: projectData } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => await fetchProjects(),
    select: (response: any) => response?.results,
  });

  const handleContractTypeClick = useCallback(
    (contractType: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contract_type: contractType,
      }));
      if (contractType?.length) {
        setPageNumberChange(0);
      }
      setSearchContractType(true);
    },
    [handleFilterChange]
  );

  const handleGroupClick = useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const handleProjectClick = useCallback(
    (projects: any) => {
      handleFilterChange('projects', projects);
      setSearchProjects(true);
    },
    [handleFilterChange]
  );

  const { user_id } = useUserData();

  useEffect(() => {
    setTimeout(() => {
      if (user_id) {
        let ws = new WebSocket(
          process.env.REACT_APP_RIVERUS_CLM_WS_API +
            `ws/kdp_status/?token=${user_id}`
        );

        ws.onclose = () => {
          ws = new WebSocket(
            process.env.REACT_APP_RIVERUS_CLM_WS_API +
              `ws/kdp_status/?token=${user_id}`
          );
        };

        ws.onmessage = (event) => {
          const contract_status: any = JSON.parse(event.data)['message'];
          setContractStatus(contract_status);
        };

        // Cleanup the WebSocket connection on component unmount
        return () => {
          ws.close();
        };
      }
    }, 6000);
  }, [user_id]);

  // function CircularProgressWithLabel(
  //   props: CircularProgressProps & { value: number }
  // ) {
  //   return (
  //     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
  //       <CircularProgress variant="determinate" {...props} />
  //       <Box
  //         sx={{
  //           top: 0,
  //           left: 0,
  //           bottom: 0,
  //           right: 0,
  //           position: 'absolute',
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Typography
  //           variant="caption"
  //           component="div"
  //           color="text.secondary"
  //         >{`${Math.round(props.value)}%`}</Typography>
  //       </Box>
  //     </Box>
  //   );
  // }

  const progressStatus = useCallback(
    (id: string) => {
      return contractStatus?.find((item: any) => item?.id === id);
    },
    [contractStatus]
  );

  const { search } = useLocation();
  const queryParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  useEffect(() => {
    const getType = queryParams.get('type');
    const getName = queryParams.get('name');
    if (getType === 'contract_type') {
      setSearchContractType(true);
    } else if (getType === 'groups') {
      setSearchGroups(true);
    } else if (getType === 'projects') {
      setSearchProjects(true);
    }
    if (getType && getName) {
      setFilters((prev: IObject) => ({ ...prev, [getType]: [getName] }));
    }
  }, [queryParams]);

  const getFailedStatus = useCallback(
    (createdDate: string): boolean => {
      const createdTime = dayjs(createdDate);
      const currentTime = dayjs();

      const timeDifferenceInMilliseconds = currentTime.diff(createdTime);

      const timeDifferenceInMinutes =
        timeDifferenceInMilliseconds / (1000 * 60);

      const isMoreThan15Minutes = timeDifferenceInMinutes > 15;

      return !isMoreThan15Minutes;
    },
    [contractStatus]
  );

  const handleOpenContract = (contractData: any) => {
    window.open(
      '/document/documentlibrary/' + btoa(contractData?.id?.toString()),
      '_blank'
    );
  };

  useEffect(() => {
    if (!filters?.title) {
      setSearchContract(false);
    }
    if (!filters?.projects) {
      setSearchProjects(false);
    }
    if (!filters?.groups) {
      setSearchGroups(false);
    }
    if (!filters?.contracting_parties) {
      setSearchContractingParties(false);
    }
    // if (!filters?.status) {
    //   setSearchStatus(false);
    // }
  }, [filters, refreshKey]);

  useEffect(() => {
    if (!filters?.contract_type) {
      setSearchContractType(false);
    }
  }, [activeHeader, refreshKey]);

  const getProgressStatus = (row: any) => {
    const progress = parseInt(progressStatus(row?.id)?.percentage);
    const isProcessing = progress < 100;
    const notStarted =
      row?.status === 'Not Started' &&
      progressStatus(row?.id)?.status !== 'Done';
    const failed =
      row?.status === 'Failed' ||
      progressStatus(row?.id)?.status === 'Failed' ||
      (!getFailedStatus(row?.created_on) &&
        progressStatus(row?.id)?.status === 'Processing' &&
        parseInt(progressStatus(row?.id)?.percentage) === 0);
    return { progress, isProcessing, notStarted, failed };
  };

  const getTooltipTitle = ({
    isProcessing,
    notStarted,
    failed,
    progress,
  }: TooltipTitleParams): string => {
    if (isProcessing) {
      return `Processing: ${progress}%`;
    } else if (notStarted) {
      return 'Processing not yet started!';
    } else if (failed) {
      return 'Processing failed!';
    }
    return '';
  };

  const cellStyle = ({
    notStarted,
    isProcessing,
  }: CellStyleParams): React.CSSProperties => ({
    cursor: notStarted || isProcessing ? 'not-allowed' : 'pointer',
    pointerEvents: notStarted || isProcessing ? 'none' : 'auto',
    opacity: notStarted || isProcessing ? 0.6 : 1,
  });

  useEffect(() => {
    let shouldDisable = false;
    if (selectedRowData) {
      for (const row of selectedRowData) {
        const status = getProgressStatus(row);
        if (status.isProcessing || status.notStarted || status.failed) {
          shouldDisable = true;
          break;
        }
      }
    }
    onStatusChange({
      isProcessing: shouldDisable,
      notStarted: shouldDisable,
      failed: shouldDisable,
    });
  }, [selectedRowData, onStatusChange]);

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 290,
      flex: 1,
      sortable: !searchContract,
      renderHeader: () => {
        return searchContract ? (
          <TableSearchInputComponent
            key="contract"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, title: null }));
              setSearchContract(false);
            }}
            placeholder="Search Name"
            handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) => {
              if (e.target.value?.length) {
                handleFilterChange('title', e.target.value);
              }
            }}
          />
        ) : (
          <Stack onClick={(e) => e.stopPropagation()}>
            <TableHeaderWithSearch
              title="Title"
              setIsSearch={setSearchContract}
            />
          </Stack>
        );
      },
      renderCell: (params: any) => {
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 280 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                <Box sx={{ maxWidth: '200px' }}>
                  <GridCellExpand
                    value={params?.row?.title}
                    width={params?.colDef?.computedWidth}
                    onClick={() => handleOpenContract(params?.row)}
                  />
                </Box>
                <TableTooltip contractData={params?.row} />
              </Stack>
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      field: 'contracting_parties',
      headerName: 'Parties',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchContractingParties ? (
          <TableSearchInputComponent
            key="contracting_parties"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, contracting_parties: null }));
              setSearchContractingParties(false);
            }}
            placeholder="Search party name"
            handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) => {
              if (e.target.value?.length) {
                handleFilterChange('contracting_parties', e.target.value);
              }
            }}
          />
        ) : (
          <TableHeaderWithSearch
            title="Parties"
            setIsSearch={setSearchContractingParties}
          />
        );
      },
      renderCell: (params: any) => {
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 200 }}>
              <Stack
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                {params?.row?.contracting_parties?.length > 0 ||
                progressStatus(params?.row?.id)?.contracting_parties?.length >
                  0 ? (
                  <ContractingPartiesList
                    list={
                      params?.row?.contracting_parties?.length > 0
                        ? params?.row?.contracting_parties
                        : progressStatus(params?.row?.id)?.contracting_parties
                              ?.length > 0
                          ? progressStatus(params?.row?.id)
                              ?.contracting_parties || []
                          : ''
                    }
                    id={`parties-${params?.row?.id}`}
                    title={`${params?.row?.title} contracting parties`}
                    filters={filters}
                  />
                ) : (
                  <Typography sx={{ width: '100%', display: 'block' }}>
                    &nbsp;
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      field: 'contract_type',
      headerName: 'Contract Type',
      minWidth: 240,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 240 }}>
              <Stack
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                {params?.row?.contract_type?.length > 0 ? (
                  <TableChipList
                    id={`contract-${params?.row?.id}`}
                    labelKey="displayName"
                    valueKey="displayName"
                    list={params?.row?.contract_type || []}
                    chipColor="#C4DBFF"
                    title={`${params?.row?.title} contract types`}
                    onClick={handleContractTypeClick}
                  />
                ) : (
                  <Typography sx={{ width: '100%', display: 'block' }}>
                    &nbsp;
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Tooltip>
        );
      },
      renderHeader: () => {
        return searchContractType ? (
          <TableSearchInputComponent
            key="contract_type"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, created_by: null }));
              setSearchContractType(false);
            }}
            placeholder="Search contract type"
            selectedValue={
              filters?.contract_type ? filters.contract_type : null
            }
            renderCustomInput={() => (
              <TableSearchSelectInput
                key={
                  isArray(filters?.contract_type)
                    ? (filters?.contract_type?.[0] as string)
                    : 'contract_type'
                }
                label="Select contract type"
                labelKey="displayName"
                options={sortListAlphabetical(contracts, 'displayName')}
                valueKey="displayName"
                value={filters?.contract_type ? filters.contract_type : null}
                onChange={(value: string[]) => {
                  if (value.length > 0) {
                    handleFilterChange('contract_type', value);
                  } else {
                    setFilters((prev: IObject) => ({
                      ...prev,
                      contract_type: null,
                    }));
                  }
                }}
                isLoading={isLoading}
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch
            title="Contract Type"
            setIsSearch={setSearchContractType}
          />
        );
      },
    },
    {
      field: 'groups',
      headerName: 'Group',
      minWidth: 260,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        const selectedFilters: any = filters?.groups || [];
        let reOrderGroups = params?.row?.groups || [];
        if (selectedFilters?.length) {
          const filterSelectedGroups = reOrderGroups?.filter((item: any) =>
            selectedFilters?.includes(item?.name)
          );
          const filterNotSelectedGroups = reOrderGroups?.filter(
            (item: any) => !selectedFilters?.includes(item?.name)
          );
          reOrderGroups = [...filterSelectedGroups, ...filterNotSelectedGroups];
        }
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 260 }}>
              <Stack
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                {reOrderGroups.length > 0 ? (
                  <TableChipList
                    id={`groups-${params?.row?.id}`}
                    labelKey="name"
                    list={reOrderGroups}
                    chipColor="#DCBDE7"
                    title={`${params?.row?.title} group`}
                    onClick={handleGroupClick}
                  />
                ) : (
                  <Typography sx={{ width: '100%', display: 'block' }}>
                    &nbsp;
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Tooltip>
        );
      },
      renderHeader: () => {
        return searchGroups ? (
          <TableSearchInputComponent
            key="groups"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, groups: null }));
              setSearchGroups(false);
            }}
            placeholder="Search Groups"
            renderCustomInput={() => (
              <TableSearchSelectInput
                key={
                  isArray(filters?.groups)
                    ? (filters?.groups?.[0] as string)
                    : 'groups'
                }
                options={sortListAlphabetical(groupData)}
                label="Select Groups"
                valueKey="name"
                value={filters?.groups ? filters.groups : []}
                onChange={(value: string[]) => {
                  if (value?.length) {
                    handleFilterChange('groups', value);
                  }
                }}
                isLoading={isLoading}
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch
            width="80px"
            title="Groups"
            setIsSearch={setSearchGroups}
          />
        );
      },
    },
    {
      field: 'projects',
      headerName: 'Project',
      minWidth: 260,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        const selectedFilters: any = filters?.projects || [];
        let reOrderProjects = params?.row?.projects || [];
        if (selectedFilters?.length) {
          const filterSelectedGroups = reOrderProjects?.filter((item: any) =>
            selectedFilters?.includes(item?.name)
          );
          const filterNotSelectedGroups = reOrderProjects?.filter(
            (item: any) => !selectedFilters?.includes(item?.name)
          );
          reOrderProjects = [
            ...filterSelectedGroups,
            ...filterNotSelectedGroups,
          ];
        }
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 260 }}>
              <Stack
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                {reOrderProjects.length > 0 ? (
                  <TableChipList
                    id={`projects-${params?.row?.id}`}
                    labelKey="name"
                    list={reOrderProjects}
                    chipColor="#CDE7BD"
                    title={`${params?.row?.title} project`}
                    onClick={handleProjectClick}
                  />
                ) : (
                  <Typography sx={{ width: '100%', display: 'block' }}>
                    &nbsp;
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Tooltip>
        );
      },
      renderHeader: () => {
        return searchProjects ? (
          <TableSearchInputComponent
            key="projects"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, projects: null }));
              setSearchProjects(false);
            }}
            placeholder="Search Project"
            renderCustomInput={() => (
              <TableSearchSelectInput
                key={
                  isArray(filters?.projects)
                    ? (filters?.projects?.[0] as string)
                    : 'projects'
                }
                options={sortListAlphabetical(projectData)}
                label="Select Project"
                valueKey="name"
                value={filters?.projects ? filters?.projects : []}
                onChange={(value: string[]) => {
                  if (value?.length) {
                    handleFilterChange('projects', value);
                  }
                }}
                isLoading={isLoading}
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch
            width="80px"
            title="Project"
            setIsSearch={setSearchProjects}
          />
        );
      },
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) => {
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 120 }}>
              <Typography
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                {params?.row?.start_date
                  ? dayjs(params?.row?.start_date).format('DD-MM-YYYY')
                  : '_'}
              </Typography>
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      field: 'end_date',
      headerName: 'Expires on',
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) => {
        const { progress, isProcessing, notStarted, failed } =
          getProgressStatus(params?.row);
        return (
          <Tooltip
            title={getTooltipTitle({
              isProcessing,
              notStarted,
              failed,
              progress,
            })}
            arrow
          >
            <Stack sx={{ minWidth: 120 }}>
              <Typography
                sx={{
                  ...cellStyle({ notStarted, isProcessing }),
                }}
              >
                {params?.row?.end_date
                  ? dayjs(params?.row?.end_date).format('DD-MM-YYYY')
                  : '_'}
              </Typography>
            </Stack>
          </Tooltip>
        );
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   minWidth: 225,
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (params: any) => {
    //     return (
    //       <>
    //         {(params?.row?.status === 'Done' ||
    //           progressStatus(params?.row?.id)?.status === 'Done') && (
    //           <CheckCircleOutlineIcon style={{ color: 'green' }} />
    //         )}
    //         {progressStatus(params?.row?.id)?.status === 'Processing' &&
    //         getFailedStatus(params?.row?.created_on) ? (
    //           <CircularProgressWithLabel
    //             color="success"
    //             value={
    //               parseInt(progressStatus(params?.row?.id)?.percentage) || 0
    //             }
    //           />
    //         ) : (
    //           params?.row?.status === 'Not Started' &&
    //           progressStatus(params?.row?.id)?.status !== 'Done' && (
    //             <CircularProgressWithLabel color="success" value={0} />
    //           )
    //         )}
    //         {(params?.row?.status === 'Failed' ||
    //           progressStatus(params?.row?.id)?.status === 'Failed' ||
    //           (!getFailedStatus(params?.row?.created_on) &&
    //             progressStatus(params?.row?.id)?.status === 'Processing' &&
    //             parseInt(progressStatus(params?.row?.id)?.percentage) ===
    //               0)) && <CancelOutlinedIcon style={{ color: 'red' }} />}
    //       </>
    //     );
    //   },
    //   renderHeader: () => {
    //     return searchStatus ? (
    //       <TableSearchInputComponent
    //         key="status"
    //         setIsSearch={() => {
    //           setFilters((prev) => ({ ...prev, status: null }));
    //           setSearchStatus(false);
    //         }}
    //         placeholder="Search Status"
    //         renderCustomInput={() => (
    //           <TableAsyncSelectInput
    //             key={
    //               isArray(filters?.status)
    //                 ? (filters?.status?.[0] as string)
    //                 : 'status'
    //             }
    //             options={statusFilterList}
    //             label="Select Status"
    //             valueKey="value"
    //             value={filters?.status ? filters?.status : []}
    //             onChange={(value: string[]) => {
    //               if (value?.length) {
    //                 handleFilterChange('status', value);
    //               }
    //             }}
    //           />
    //         )}
    //       />
    //     ) : (
    //       <TableHeaderWithSearch
    //         width="80px"
    //         title="Status"
    //         setIsSearch={setSearchStatus}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <DataGridTable
      rows={tableData?.results || []}
      columns={columns}
      rowCount={tableData?.count}
      isLoading={isLoading}
      setSelectedRow={setSelectedRow}
      pageNumber={pageNumber}
      setPageNumberChange={setPageNumberChange}
      setSorting={setSorting}
      checkboxSelection
    />
  );
};

export default DocumentLibraryTable;
