import React, { useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';

import CreateNewClauseModal from '../../UniversalComponents/Modals/ClauseSectionModal/CreateNewClauseModal';

interface Props {
  clause: any;
  setSelectedRowData?: any;
  selectedRowData?: any;
  sectionNamesData?: any;
  contractOption?: any;
}

const ClauseTableTooltip: React.FC<Props> = ({
  clause,
  setSelectedRowData,
  selectedRowData,
  sectionNamesData,
  contractOption,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openTooltipClauseModal, setOpenTooltipClauseModal] =
    useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          handleClick(event);
        }}
        aria-describedby="table_popover"
      >
        <CallMadeIcon style={{ color: '#6D264C' }} />
      </IconButton>

      <Popover
        id="table_popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack
          direction="column"
          sx={{
            width: '400px',
            border: '1px solid #88305F',
            borderRadius: '6px',
          }}
          spacing={1}
          paddingTop="16px"
        >
          <Stack direction="column" spacing={1} padding="0 20px">
            <Typography fontSize="15px" fontWeight={600}>
              Clause Text:
            </Typography>
            <Typography fontSize="15px" sx={{ overflowWrap: 'break-word' }}>
              {clause?.clause_text}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" width="100%">
            <Button
              startIcon={<LaunchOutlinedIcon />}
              style={{ fontSize: '15px' }}
              onClick={() => {
                setSelectedRowData(clause);
                setOpenTooltipClauseModal(true);
                handleClose();
              }}
            >
              Open Clause
            </Button>
          </Stack>
        </Stack>
      </Popover>
      {selectedRowData && (
        <CreateNewClauseModal
          open={openTooltipClauseModal}
          onClose={() => setOpenTooltipClauseModal(false)}
          clauseId={selectedRowData?.id}
          sectionNamesData={sectionNamesData}
          contractOption={contractOption}
        />
      )}
    </>
  );
};

export default ClauseTableTooltip;
