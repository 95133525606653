import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import { IObject } from '../../../interfaces/IObject';
import DataGridTable from '../../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../../RiverusUI/DataGrid/GridCellExpand';
import TableChipList from '../../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../../../RiverusUI/DataGrid/TableSearchSelectInput';
import { getContractTypes } from '../../../Services/Template';
import { QueryKeyGenerator } from '../../../Utils/QueryKeyGenerator';

interface IProps {
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  refreshKey: boolean;
}

const CategoryTable: React.FC<IProps> = (props) => {
  const {
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
    setSelectedRow,
    refreshKey,
  } = props;

  const [searchDraft, setSearchDraft] = useState<boolean>(false);
  const [searchContract, setSearchContract] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: contracts } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: getContractTypes,
    select: (response: any) => response.results,
  });

  const handleContractTypeClick = useCallback(
    (contractType: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contract_types: contractType,
      }));
      if (contractType?.length) {
        setPageNumberChange(0);
      }
      setSearchContract(true);
    },
    [handleFilterChange]
  );

  useEffect(() => {
    if (!filters?.name) {
      setSearchDraft(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!filters?.contract_types) {
      setSearchContract(false);
    }
  }, [refreshKey]);

  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Category Name',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderHeader: () => {
          return searchDraft ? (
            <TableSearchInputComponent
              key="name"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, name: null }));
                setSearchDraft(false);
              }}
              placeholder="Search Name"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('name', e.target.value)
              }
            />
          ) : (
            <TableHeaderWithSearch
              title="Category Name"
              setIsSearch={setSearchDraft}
            />
          );
        },
        renderCell: (Params: any) => {
          return (
            <GridCellExpand
              value={Params?.row?.name}
              width={Params.colDef.computedWidth}
            />
          );
        },
      },
      {
        field: 'contract_types',
        headerName: 'Contract Type',
        minWidth: 240,
        flex: 1,
        sortable: false,
        renderCell: (params: any) =>
          params?.row?.contract_types?.length > 0 && (
            <TableChipList
              id={`contract-${params?.row?.id}`}
              labelKey="displayName"
              valueKey="id"
              list={params?.row?.contract_types || []}
              chipColor="#C4DBFF"
              title={`${params?.row?.name} contract types`}
              onClick={handleContractTypeClick}
            />
          ),
        renderHeader: () => {
          return searchContract ? (
            <TableSearchInputComponent
              key="contract_types"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contract_types: null }));
                setSearchContract(false);
              }}
              placeholder="Search contract type"
              selectedValue={
                filters?.contract_types ? filters.contract_types : []
              }
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.contract_types)
                      ? (filters?.contract_types?.[0] as string)
                      : 'contract_types'
                  }
                  label="Select contract type"
                  labelKey="displayName"
                  options={sortListAlphabetical(contracts, 'displayName')}
                  valueKey="id"
                  value={filters?.contract_types ? filters.contract_types : []}
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('contract_types', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        contract_types: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Type"
              setIsSearch={setSearchContract}
            />
          );
        },
      },
      {
        field: 'used_in_drafts',
        headerName: 'Contracts',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (Params: any) => {
          return (
            <div>
              {Params?.row?.used_in_templates
                ? `${Params?.row?.used_in_templates} Templates ${Params?.row?.used_in_drafts ? '/ ' : ''}`
                : null}
              {Params?.row?.used_in_drafts
                ? `${Params?.row?.used_in_drafts} Drafts`
                : null}
            </div>
          );
        },
      },
    ],
    [
      searchDraft,
      setFilters,
      handleFilterChange,
      searchContract,
      filters,
      contracts,
      isLoading,
    ]
  );

  return (
    <React.Fragment>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        setSelectedRow={setSelectedRow}
      />
    </React.Fragment>
  );
};

export default CategoryTable;
