import React from 'react';

import { Tab, TabProps, Tooltip, Typography } from '@mui/material';

interface IProps extends TabProps {
  count: string;
  status?: string;
  tooltip?: string;
}

const StaticTab: React.FC<IProps> = ({
  count,
  label,
  status,
  tooltip,
  ...props
}) => {
  return (
    <Tooltip title={tooltip} arrow>
      <Tab
        sx={{
          background: status || '#88305F14',
          padding: '4px 12px',
          borderRadius: '6px',
          marginX: '4px',
          alignItems: 'start',
          fontSize: '13px !important',
          minHeight: '3.8rem',
          width: '8rem',
          height: '4rem',
          textAlign: 'left',
          '&.Mui-selected': {
            color: '#410224',
          },
        }}
        icon={
          <Typography
            variant="h5"
            sx={{ marginBottom: 0, fontSize: '18px !important' }}
          >
            {count}
          </Typography>
        }
        label={label}
        {...props}
      />
    </Tooltip>
  );
};

export default StaticTab;
