import React, { useEffect } from 'react';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useUserData } from '../../../App/Component/UserDataProvider';
import ControlledCheckbox from '../../../RiverusUI/Components/ControlledCheckbox';
import NotepadComponent from '../../../RiverusUI/Components/NotepadComponent';
import UploadDocComponent from '../../../RiverusUI/Components/UploadDocComponent';
import LinkSelectedTemplateField from '../TemplateDrawer/LinkSelectedTemplateField';

interface Props {
  contract_type: string;
  contract_name: string;
  display_name: string;
  counterPartyDraft: any;
  setCounterPartyDraft: any;
  setSelectedLinkedDraft: any;
  contractRequestData: any;
  assigneeCombinedData?: any;
  form_type?: string;
  umbrellaContractData?: any;
}
const DraftForm: React.FC<Props> = ({
  contract_name,
  contract_type,
  display_name,
  counterPartyDraft,
  setCounterPartyDraft,
  setSelectedLinkedDraft,
  contractRequestData,
  assigneeCombinedData,
  form_type,
  umbrellaContractData,
}) => {
  const { userIsCreator } = useUserData();
  const { watch, control, setValue } = useFormContext();
  const can_upload_draft = watch('can_upload_draft') || '';
  const can_link_draft = watch('can_link_draft') || '';

  useEffect(() => {
    if (userIsCreator && contractRequestData && assigneeCombinedData) {
      const contractApprovers =
        contractRequestData?.results?.[0]?.approvers?.map(
          (approver: any) => approver?.id
        ) || [];

      const policyApprovers =
        assigneeCombinedData
          ?.filter((approver: any) => contractApprovers?.includes(approver?.id))
          ?.map((approver: any) => approver?.id) || [];

      const approverIds = Array.from(new Set([...policyApprovers]));
      setValue('approvalTypes', approverIds);
    }
  }, [userIsCreator, contractRequestData, assigneeCombinedData, setValue]);

  return (
    <Stack width="100%">
      <Grid item sm={12}>
        {form_type === 'counter_party' && (
          <Stack>
            <Stack direction="row" spacing={2}>
              <KeyboardReturnIcon
                sx={{
                  transform: 'scaleX(-1)',
                  mt: '15px !important',
                }}
              />
              <Box width="100%">
                <UploadDocComponent
                  label="Upload Counterparty draft"
                  control={control}
                  name="counter_party_draft"
                  allowedFileTypes={['.pdf', '.docx']}
                  files={counterPartyDraft}
                  setFiles={setCounterPartyDraft}
                />
              </Box>
            </Stack>
            <Stack>
              <NotepadComponent name="notes" control={control} />
              <Typography
                variant="caption"
                sx={{ paddingLeft: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
              >
                Please add additional requirements
              </Typography>
            </Stack>
          </Stack>
        )}
      </Grid>
      {form_type === 'request_draft' && (
        <Stack spacing={3}>
          <Grid item sm={12}>
            <NotepadComponent name="notes" control={control} />
            <Typography
              variant="caption"
              sx={{ paddingLeft: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
            >
              Please add additional requirements
            </Typography>
          </Grid>
          <Stack>
            <Typography fontWeight={'700'}>Define source reference</Typography>
            <ControlledCheckbox
              name="can_link_draft"
              label="Link an earlier or executed draft"
              control={control}
              disabled={umbrellaContractData ? true : false}
            />
            {can_link_draft && (
              <Stack direction="row" spacing={2}>
                <KeyboardReturnIcon
                  sx={{
                    transform: 'scaleX(-1)',
                    mt: '15px !important',
                  }}
                />
                <LinkSelectedTemplateField
                  control={control}
                  selectedContractId={contract_type}
                  name="linked_draft"
                  selectedContractName={contract_name}
                  type="draft"
                  selectedDisplayName={display_name}
                  setSelectedLinkedDraft={setSelectedLinkedDraft}
                  renewOptions={
                    umbrellaContractData ? [umbrellaContractData] : []
                  }
                  isRenewed={umbrellaContractData ? true : false}
                />
              </Stack>
            )}
            <ControlledCheckbox
              name="can_upload_draft"
              label="Upload your own reference document"
              control={control}
            />
            {can_upload_draft && (
              <Grid item sm={12}>
                <Stack direction="row" spacing={2}>
                  <KeyboardReturnIcon
                    sx={{
                      transform: 'scaleX(-1)',
                      mt: '15px !important',
                    }}
                  />
                  <Box width="100%">
                    <UploadDocComponent
                      label="Upload Document"
                      control={control}
                      name="counter_party_draft"
                      allowedFileTypes={['.pdf', '.docx']}
                      files={counterPartyDraft}
                      setFiles={setCounterPartyDraft}
                    />
                  </Box>
                </Stack>
              </Grid>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DraftForm;
