import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AddCircleOutline } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PercentIcon from '@mui/icons-material/Percent';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import AssigneeModal from './AssigneeModal';
import { currency_icon } from './static';
import { useUserData } from '../../../App/Component/UserDataProvider';
import { Field_INFO } from '../../../Constants/DataDictionary';
import {
  editExternalBulkReminder,
  fetchExternalCollaborators,
  saveExternalDraft,
} from '../../../ExternalUserFlow/Services/Draft';
import ControlledCheckbox from '../../../RiverusUI/Components/ControlledCheckbox';
import DatePickerElement from '../../../RiverusUI/Components/ControlledDatePicker';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import { FormContainer } from '../../../RiverusUI/Components/FormContainer';
import DeleteIcon from '../../../RiverusUI/Components/Icons/DeleteIcon';
import SelectElement from '../../../RiverusUI/Components/SelectElement';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import { fetchCollaborators } from '../../../Services/Approval';
import { editReminder } from '../../../Services/DocumentLibrary';
import { fetchGetFields, saveDraft } from '../../../Services/Draft';
import {
  createBulkReminder,
  editBulkReminder,
  fetchReminderById,
  fetchReminderDraftItem,
} from '../../../Services/Reminder';
import { generateTags } from '../../../Templates/Component/DocGenerate';

interface Props {
  draftData: any;
  instance: any;
  setCompleteDraftFormData?: Dispatch<SetStateAction<any>>;
  setFieldUpdated?: Dispatch<SetStateAction<boolean>>;
  updateDraftData?: any;
  isExternal?: boolean;
  setSaveDraftLoading?: Dispatch<SetStateAction<boolean>>;
  isTemplateView?: boolean;
  checklistUserIds?: any;
  isDraftDropped?: boolean;
  handleOpenGenerateDraftModal?: VoidFunction;
  accessUrl?: any;
  optionalFields?: any;
  isFetching?: boolean;
  fetchingOptionalField?: boolean;
  setFetchingOptionalField?: Dispatch<SetStateAction<boolean>>;
}

const CompleteDraft: React.FC<Props> = ({
  draftData,
  setCompleteDraftFormData,
  instance,
  setFieldUpdated,
  updateDraftData,
  isExternal,
  setSaveDraftLoading,
  isTemplateView = false,
  checklistUserIds,
  isDraftDropped,
  handleOpenGenerateDraftModal,
  accessUrl,
  optionalFields,
  isFetching,
  fetchingOptionalField,
  setFetchingOptionalField,
}) => {
  const [openAssigneeDialog, setOpenAssigneeDialog] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<string[]>([]);
  const [assigneeDialogPropData, setAssigneeDialogPropData] = useState<any>({});
  const [tags, setTags] = useState({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const [dynamicFields, setDynamicFields] = useState<any>([]);
  const [collaborators, setCollaborators] = useState<any>([]);
  const [tempReminder, setTempReminder] = useState<any>([]);
  const [retryCount, setRetryCount] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamsTypeValue = queryParams.get('reminder_type');
  const reminderId = queryParams.get('reminderId') || '';
  const hasBulkApiExecuted = useRef(false);

  const { user_data, user_id, userIsCreator, external_email_id } =
    useUserData();

  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_id),
    [draftData, user_id]
  );

  const { data: complete_reminder_data } = useQuery({
    queryKey: ['get_complete_reminder_data', assigneeDialogPropData?.id],
    queryFn: async () =>
      await fetchReminderDraftItem(assigneeDialogPropData?.id),
    enabled: !!(
      assigneeDialogPropData?.id && assigneeDialogPropData?.reminder?.id
    ),
  });

  const { data: reminderData } = useQuery({
    queryKey: ['get_reminder_by_id'],
    queryFn: async () => await fetchReminderById(reminderId),
    enabled: !!reminderId,
  });

  const draft_id = useMemo(() => draftData?.id, [draftData?.id]);
  const serial_no = useMemo(() => {
    return draft_id && draft_id.split('-')?.[0];
  }, [draft_id]);

  const contractTypeId = useMemo(
    () => draftData?.contractType?.id || draftData?.contract_type,
    [draftData]
  );

  const methods = useForm();

  const { control, reset } = methods;

  useEffect(() => {
    const collaborator = draftData?.collaborators?.map((data: any) => data.id);
    setCollaborators(collaborator);
  }, [draftData?.collaborators]);

  const handleCloseAssigneeDialog = useCallback(() => {
    if (queryParamsTypeValue === 'items') {
      navigate(pathname);
    }
    setOpenAssigneeDialog(false);
    setAssigneeDialogPropData({});
  }, [navigate, pathname, queryParamsTypeValue]);

  const handleAssignee = useCallback(
    (payload: any, selectUser: any, selectExternalUser: any) => {
      const updatedFields = dynamicFields.map((fieldInfo: any) => {
        return {
          ...fieldInfo,
          field_data: fieldInfo?.field_data?.map((element: any) => {
            if (element?.field_name === assigneeDialogPropData?.field_name) {
              const shouldAssignDate =
                selectUser !== payload?.id ||
                selectExternalUser !== payload?.id;
              return {
                ...element,
                user_name: payload?.user_name,
                user_id: payload?.id,
                user: payload?.id,
                email: payload?.email,
                user_type: payload?.assignee_type,
                ...(shouldAssignDate
                  ? { assigned_on: payload?.assigned_on }
                  : {}),
              };
            }
            return element;
          }),
        };
      });
      setDynamicFields(updatedFields);
      if (payload?.assignee_type === 'internal') {
        const index = collaborators.findIndex(
          (collaborator: any) => collaborator === payload.id
        );
        if (index === -1) {
          setCollaborators((prev: any) => [...prev, payload.id]);
        }
      }
      handleCloseAssigneeDialog();
    },
    [
      dynamicFields,
      handleCloseAssigneeDialog,
      assigneeDialogPropData?.field_id,
      collaborators,
    ]
  );

  const handleReminder = useCallback(
    (payload: any) => {
      const filterTempReminder = tempReminder?.filter(
        (item: any) =>
          item?.field_name !== payload?.field_name ||
          item?.draft_item !== payload?.draft_item
      );
      setTempReminder([payload, ...filterTempReminder]);
    },
    [tempReminder, setTempReminder]
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: getFields } = useQuery({
    queryKey: ['get_fields', contractTypeId],
    queryFn: async () => {
      const response = await fetchGetFields(contractTypeId);
      return response?.data;
    },
    enabled: !!contractTypeId && !isExternal,
  });

  const { data: approversData } = useQuery({
    queryKey: ['Approvers_list', isExternal],
    queryFn: async () => {
      const response = isExternal
        ? await fetchExternalCollaborators()
        : await fetchCollaborators();
      const groups = response.results.map((data: any) => ({
        ...data,
        name: data?.first_name + ' ' + data?.last_name,
      }));
      return groups;
    },
    enabled: !!draftData?.groups,
  });

  const { mutate: update_bulk_reminders } = useMutation({
    mutationKey: ['update_bulk_reminders', isExternal],
    mutationFn: isExternal ? editExternalBulkReminder : editBulkReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleOpenAssigneeDialog = useCallback(
    (data: any) => {
      if (isExternal || isTemplateView) return;
      setOpenAssigneeDialog(true);
      const matchingOptionalFieldData = optionalFields?.find(
        (field: any) =>
          Array.isArray(field?.field_data) &&
          field?.field_data?.some(
            (fieldData: any) =>
              fieldData?.id === data?.id &&
              (!data?.reminder?.id ||
                fieldData?.reminder?.id === data?.reminder?.id)
          )
      );
      const matchingFieldData = matchingOptionalFieldData?.field_data?.find(
        (fieldData: any) =>
          fieldData?.id === data?.id &&
          (!data?.reminder?.id ||
            fieldData?.reminder?.id === data?.reminder?.id)
      );
      setAssigneeDialogPropData(
        matchingFieldData?.reminder?.id ? matchingFieldData : data
      );
    },
    [isExternal, isTemplateView, optionalFields]
  );

  const { mutate: saveDraftMutation, isSuccess } = useMutation({
    mutationKey: ['save_draft_mutation', isExternal, draftData?.id, retryCount],
    mutationFn: isExternal ? saveExternalDraft : saveDraft,
    onMutate: () => {
      setSaveDraftLoading?.(true);
    },
    onSuccess: async () => {
      setRetryCount(0);
      queryClient.invalidateQueries({ queryKey: ['optional_fields'] });

      if (!isExternal) {
        const templateCollaborators = dynamicFields
          ?.flatMap((field: any) =>
            Array.isArray(field?.field_data) ? field?.field_data : []
          )
          .filter(
            (item: any) => item?.user !== null && item?.user !== undefined
          )
          .map((item: any) => item?.user);

        const uniqueCollaborators = Array.from(
          new Set([...templateCollaborators, ...checklistUserIds])
        );

        const groups = draftData?.groups;
        approversData?.forEach((approver: any) => {
          if (uniqueCollaborators?.includes(approver?.id)) {
            const missingInDraftGroups = approver?.groups.filter(
              (item: string) => !draftData?.groups.includes(item)
            );
            if (missingInDraftGroups?.length) {
              groups.push(...missingInDraftGroups);
            }
          }
        });

        const payload = {
          id: draftData?.id,
          body: {
            collaborators: uniqueCollaborators,
            groups: groups,
          },
        };

        if (uniqueCollaborators?.length) {
          updateDraftData?.(payload);
        }
        setFieldUpdated?.(false);
      }

      enqueueSnackbar('Saved successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      if (retryCount < 5) {
        queryClient.invalidateQueries({
          queryKey: ['save_draft_mutation'],
        });
        setRetryCount((prev: number) => prev + 1);
      } else {
        enqueueSnackbar('Failed to save!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    onSettled: () => {
      setSaveDraftLoading?.(false);
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  });

  useEffect(() => {
    if (isSuccess) {
      const updateFieldReminderIds = optionalFields?.results
        ?.flatMap((fields: any) =>
          fields?.field_data
            ?.filter((data: any) => data?.field_value !== '')
            ?.map((data: any) => data?.reminder?.id)
        )
        .filter(Boolean);

      if (updateFieldReminderIds?.length > 0) {
        const updateChecklistPayload = {
          ids: updateFieldReminderIds,
          status: 'Completed',
        };
        update_bulk_reminders(updateChecklistPayload);
      }
    }
  }, [optionalFields, isSuccess]);

  const { mutate: bulkReminderMutation } = useMutation({
    mutationKey: ['create_bulk_reminder'],
    mutationFn: createBulkReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder created successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setTempReminder([]);
    },
    onError: () => {
      enqueueSnackbar('Failed to create reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: update_reminder, isPending: loadingEditReminder } =
    useMutation({
      mutationKey: ['update_reminders'],
      mutationFn: editReminder,
      onSuccess: () => {
        enqueueSnackbar('Reminder updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to update Reminder!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const handleUpdateReminder = useCallback((payload: any) => {
    const { id, ...remainingData } = payload;
    const updatedReminderPayload = {
      id: id,
      body: { ...remainingData },
    };
    update_reminder(updatedReminderPayload);
  }, []);

  const handleSaveDraft = React.useCallback(() => {
    if (dynamicFields?.length) {
      if (tempReminder?.length > 0) {
        bulkReminderMutation(tempReminder);
      }
      const dynamicFields_ = JSON.parse(JSON.stringify(dynamicFields));
      dynamicFields_?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((fields: any) => {
          if (
            fields.data_type.toLowerCase() === 'boolean' ||
            fields.data_type.toLowerCase() === 'bulleted list' ||
            fields.data_type.toLowerCase() === 'numbered list'
          ) {
            const value = fields.field_value;
            if (fields.data_type.toLowerCase() !== 'boolean') {
              if (value && value[value.length - 1]?.list !== '') {
                value.push({ list: '' });
              }
            }
            fields.field_value = JSON.stringify(value);
          }
        });
      });
      const payload = {
        draft: draftData?.id,
        optional_data: dynamicFields_,
      };
      saveDraftMutation(payload);
    }
  }, [
    bulkReminderMutation,
    draftData,
    dynamicFields,
    saveDraftMutation,
    tempReminder,
  ]);

  useEffect(() => {
    if (queryParamsTypeValue === 'items' && reminderId) {
      dynamicFields?.find((value: any) => {
        const fieldData = value?.field_data?.find(
          (item: any) => item?.id === reminderData?.draft_item
        );
        handleOpenAssigneeDialog(fieldData);
        return fieldData;
      });
    }
  }, [
    queryParamsTypeValue,
    reminderId,
    handleOpenAssigneeDialog,
    dynamicFields,
    reminderData,
  ]);

  useEffect(() => {
    if (
      !hasBulkApiExecuted.current &&
      !isFetching &&
      optionalFields?.length === 0 &&
      dynamicFields?.length > 0 &&
      !isTemplateView
    ) {
      handleSaveDraft();
      hasBulkApiExecuted.current = true;
    }
  }, [
    dynamicFields,
    handleSaveDraft,
    isFetching,
    isTemplateView,
    optionalFields,
  ]);

  useEffect(() => {
    if (optionalFields?.length && !isFetching && fetchingOptionalField) {
      setFormFields(optionalFields);
      const optionalData = optionalFields.map((optionalField: any) => {
        optionalField.field_data.forEach((data: any) => {
          data.field_value = getFieldValue(
            data.data_type.toLowerCase(),
            data.data_type.toLowerCase() === 'serial no'
              ? serial_no
              : data.field_value
          );
        });
        return optionalField;
      });
      setDynamicFields(optionalData);
      setFetchingOptionalField?.(false);
    } else if (getFields?.length) {
      setFormFields(getFields);
    }
  }, [getFields, optionalFields, isFetching, fetchingOptionalField]);

  useEffect(() => {
    if (optionalFields?.length === 0 && !isFetching) {
      setFetchingOptionalField?.(false);
    }
  }, [optionalFields, isFetching]);

  const updateDynamicFieldValue = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    type: string
  ) => {
    const fields = [...dynamicFields];
    setFieldUpdated?.(true);
    if (type === 'select') {
      fields[sectionIndex].field_data[fieldIndex].field_value = event;
      const { required, field_value, field_validation, validation } =
        fields[sectionIndex].field_data[fieldIndex];
      if (validation) {
        if (required && field_value.length > 0) {
          validation['errorMsg'] = '';
        }
        if (field_validation && event.target.value.length > field_validation) {
          validation['errorMsg'] =
            `Max character limit allowed is ${validation.maxCharLength} chars`;
        } else validation['errorMsg'] = '';
      }
    }
    if (type === 'text' || type.toLowerCase() === 'currency') {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.value;
      if (type.toLowerCase() === 'currency') {
        fields[sectionIndex].field_data[fieldIndex].unit =
          fields[sectionIndex].field_data[
            fieldIndex
          ]?.set_recommended_value?.default_field_unit;
      }
      const { required, field_value, field_validation, validation } =
        fields[sectionIndex].field_data[fieldIndex];
      if (validation) {
        if (required && field_value.length > 0) {
          validation['errorMsg'] = '';
        }
        if (field_validation && event.target.value.length > field_validation) {
          validation['errorMsg'] =
            `Max character limit allowed is ${validation.maxCharLength} chars`;
        } else validation['errorMsg'] = '';
      }
    }
    if (type === 'duration') {
      fields[sectionIndex].field_data[fieldIndex].unit =
        event.target.value || 'Days';
    }
    if (type === 'percentage') {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.value;
    }
    if (type.toLowerCase() === 'date') {
      const date = event.$d.toLocaleDateString('en-CA');
      fields[sectionIndex].field_data[fieldIndex].field_value = date;
    }
    if (type === 'boolean') {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.checked;
    }
    setDynamicFields(fields);
  };

  const parseTagList = (tags: any) => {
    const parsedTags = Object.keys(tags).reduce((acc, curr) => {
      let innerObject = {};
      if (Object.keys(tags[curr]).length > 0) {
        innerObject = { ...innerObject, ...tags[curr] };
      }
      return { ...acc, [curr.split(' ')[0]]: {}, ...innerObject };
    }, {});
    return parsedTags;
  };

  useEffect(() => {
    if (draft_id && optionalFields?.length === 0) {
      generateTags(accessUrl, (tags: any) => {
        const parseTags: any = parseTagList(tags);
        setTags(parseTags);
      });
    }
  }, [accessUrl, getFields, draft_id, optionalFields]);

  const getFieldName = (mergeTag: string, fieldName: string) => {
    let name = fieldName;
    if (optionalFields?.length) {
      return name;
    } else {
      try {
        if (
          mergeTag.charCodeAt(mergeTag.length - 1) >= 49 &&
          mergeTag.charCodeAt(mergeTag.length - 1) <= 57
        ) {
          name = name + ' ' + mergeTag[mergeTag.length - 1];
        }
        return name;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getFieldValue = (type: string, value: any) => {
    if (type === 'boolean') {
      if (value === 'true') {
        return true;
      }
      return false;
    } else if (type === 'bulleted list' || type === 'numbered list') {
      if (value && value !== '') {
        return JSON.parse(value);
      }
      return [{ list: '' }];
    } else return value || '';
  };

  const constructDocFields = useCallback(() => {
    if (Object.keys(tags).length > 0) {
      const tagList = Object.keys(tags);
      const fields: any[] = [];
      tagList.forEach((tag) => {
        let sectionObj: any = {};
        for (let index = 0; index < formFields?.length; index++) {
          const section = JSON.parse(JSON.stringify(formFields[index]));
          let filteredTags: Field_INFO[] = [];
          filteredTags = section.field_data.filter((field: any) => {
            const tagToCheck = tag.replace(/\d+/g, '');
            const fieldName = field.field_name
              .replaceAll(/{|}/g, '')
              .replace(/\d+/g, '');
            return tagToCheck === fieldName;
          });

          if (filteredTags.length > 1) {
            filteredTags = filteredTags.filter((field: any) =>
              field.field_name.replaceAll(/{|}/g, '').includes(tag)
            );
          }
          if (filteredTags.length > 0) {
            sectionObj = { ...section };
            if (fields.length > 0) {
              const fieldIndex = fields.findIndex(
                (value) => value.section_name === section.section_name
              );
              if (fieldIndex > -1) {
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.field_value = getFieldValue(
                  field.data_type.toLowerCase(),
                  field.field_value
                );
                field.unit = 'Days';
                field.field_name = tag;
                field.field = tag.replace(/[0-9]/g, '');
                field.validation = {};
                fields[fieldIndex].field_data = [
                  ...fields[fieldIndex].field_data,
                  field,
                ];
                return;
              } else {
                sectionObj.field_data = [];
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.field_value = getFieldValue(
                  field.data_type.toLowerCase(),
                  field.field_value
                );
                field.unit = 'Days';
                field.validation = {};
                field.field = tag.replace(/[0-9]/g, '');
                field.field_name = tag;
                sectionObj.field_data = [field];
                fields.push(sectionObj);
                return;
              }
            } else {
              sectionObj.field_data = [];
              const field = JSON.parse(JSON.stringify(filteredTags[0]));
              field.display_name = getFieldName(tag, field.display_name);
              field.field_value = getFieldValue(
                field.data_type.toLowerCase(),
                field.field_value
              );
              field.unit = 'Days';
              field.validation = {};
              field.field = tag.replace(/[0-9]/g, '');
              field.field_name = tag;
              sectionObj.field_data = [field];
              fields.push(sectionObj);
              return;
            }
          }
        }
      });
      setDynamicFields(fields);
    }
  }, [tags, formFields]);

  useEffect(() => {
    if (tags && optionalFields?.length === 0 && !isFetching) {
      constructDocFields();
    }
  }, [tags, optionalFields, isFetching]);

  useEffect(() => {
    if (dynamicFields?.length) {
      let defaultFields = {};
      dynamicFields.map((fields: any) => {
        fields.field_data?.map((data: any) => {
          const form_fields = {
            [data.field_name]: data.field_value,
          };
          defaultFields = {
            ...defaultFields,
            ...form_fields,
          };
        });
      });
      reset(defaultFields);
    }
  }, [dynamicFields, reset]);

  useEffect(() => {
    if (dynamicFields?.length) {
      const dynamicFields_ = JSON.parse(JSON.stringify(dynamicFields));
      dynamicFields_?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((fields: any) => {
          if (!fields.field_value) {
            fields.field_value = `{${fields.field}}`;
          }
          if (
            fields.data_type.toLowerCase() === 'boolean' ||
            fields.data_type.toLowerCase() === 'bulleted list' ||
            fields.data_type.toLowerCase() === 'numbered list'
          ) {
            fields.field_value = fields.field_value
              ? JSON.stringify(fields.field_value)
              : `{${fields.field}}`;
          }
        });
      });
      setCompleteDraftFormData?.(dynamicFields_);
    }
  }, [dynamicFields, setCompleteDraftFormData]);

  const highlightTag = useCallback(
    (tag: string) => {
      if (instance) {
        const { Core } = instance;
        const { documentViewer, Annotations, annotationManager } = Core;
        const annotations = annotationManager.getAnnotationsList();
        annotationManager.deleteAnnotations(annotations);
        const searchData = '{' + tag + '}';
        const searchMode =
          instance.Core.Search.Mode.REGEX |
          instance.Core.Search.Mode.HIGHLIGHT |
          instance.Core.Search.Mode.PAGE_STOP;
        let isMoveToPage = true;
        const searchOptions = {
          fullSearch: true,
          onResult: (result: any) => {
            if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
              const highlight = new Annotations.TextHighlightAnnotation();
              result.quads.map((quad: { getPoints: () => any }) => {
                highlight.Quads = [quad.getPoints()] as any[];
              });
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
              if (documentViewer.getCurrentPage() === result.pageNum) {
                isMoveToPage = false;
              }
              if (isMoveToPage) {
                documentViewer.setCurrentPage(result.pageNum, true);
              }
            }
          },
        };
        documentViewer.textSearchInit(searchData, searchMode, searchOptions);
      }
    },
    [instance]
  );

  const updateListData = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    itemIndex: number
  ) => {
    const fields = [...dynamicFields];
    fields[sectionIndex].field_data[fieldIndex].field_value[itemIndex].list =
      event.target.value;
    setDynamicFields(fields);
  };

  const addItemList = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    ItemIndex: number
  ) => {
    event.preventDefault();
    const fields = [...dynamicFields];
    if (
      fields[sectionIndex].field_data[fieldIndex].field_value[ItemIndex][
        'list'
      ] === ''
    )
      return;
    fields[sectionIndex].field_data[fieldIndex].field_value.push({ list: '' });
    setDynamicFields(fields);
  };

  const removeItemFromField = (
    sectionIndex: number,
    fieldIndex: number,
    index: number
  ) => {
    const fields = [...dynamicFields];
    fields[sectionIndex].field_data[fieldIndex].field_value.splice(index, 1);
    setDynamicFields(fields);
    enqueueSnackbar('Click "Save Draft" to save the delete action!', {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  const onSubmit = React.useCallback(() => {
    handleSaveDraft();
    handleOpenGenerateDraftModal?.();
  }, [handleSaveDraft]);

  const isDisabled = (email: string) => {
    if (userIsOwner || userIsCreator) {
      return false;
    } else if (
      (user_data?.email && email === user_data?.email) ||
      (external_email_id && external_email_id === email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const sectionUserCounts = useMemo(() => {
    return dynamicFields?.reduce(
      (acc: any, section: any) => {
        const userCount =
          section?.field_data?.filter(
            (data: any) =>
              data?.email === user_data?.email &&
              (data?.field_value === '' || data?.field_value === null)
          ).length || 0;

        return {
          ...acc,
          [section?.section_name]: userCount,
        };
      },
      {} as Record<string, number>
    );
  }, [dynamicFields, user_data?.email]);

  const showField = (field: any, section: any) => {
    if (field.parent && field.parent !== 'None') {
      const parentField = section.field_data.find(
        (item: any) => item.field_id === field.parent
      );
      if (parentField) {
        if (parentField.data_type === 'Select') {
          if (parentField.field_value) {
            const index = field.parent_field_value.indexOf(
              parentField.field_value
            );
            if (index > -1) {
              return true;
            }
          }
        } else if (parentField.data_type === 'Boolean') {
          if (parentField.field_value) {
            if (field.parent_field_value === 'true') {
              return true;
            }
          }
        }
        return false;
      }
    }
    return true;
  };

  return (
    <React.Fragment>
      <FormContainer
        formContext={methods}
        onSuccess={onSubmit}
        FormProps={{
          id: 'complete_draft_form',
        }}
        onError={() =>
          enqueueSnackbar('Please fill all the required fields!', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          })
        }
      >
        <Stack spacing={1} margin="15px">
          {!isTemplateView && dynamicFields?.length > 0 && (
            <Typography variant="caption">
              Click &quot;Save Draft&quot; to avoid loss of inserted data below
              due to refresh or navigation.
            </Typography>
          )}
          {dynamicFields?.map((section: any, sectionIndex: number) => (
            <Accordion
              sx={{
                background: '#88305F24',
                boxShadow: 'none',
              }}
              key={`${section.section_name}-${sectionIndex}`}
              expanded={expanded === section.section_id}
              onChange={handleAccordionChange(section.section_id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>{section?.section_name}</Typography>
                  {expanded !== section?.section_id &&
                    section?.field_data?.some(
                      (data: any) => data?.email === user_data?.email
                    ) && (
                      <Stack direction="row">
                        <Badge
                          badgeContent={sectionUserCounts[section.section_name]}
                          sx={{
                            '& .MuiBadge-badge': {
                              backgroundColor: '#A0597F',
                              color: '#fff',
                            },
                          }}
                        >
                          <NameAvatar
                            firstName={user_data?.first_name}
                            lastName={user_data?.last_name}
                            sx={{
                              color: '#A0597F',
                              border: '3px solid #A0597F',
                              backgroundColor: 'unset',
                              width: 24,
                              height: 24,
                              fontSize: '12px',
                            }}
                          />
                        </Badge>
                      </Stack>
                    )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {section?.field_data?.map(
                    (fieldData: any, fieldIndex: number) => {
                      const fullName: string[] =
                        fieldData?.user_name?.split(' ');
                      return (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                          key={`${fieldData.field_name}-${fieldIndex}`}
                        >
                          {showField(fieldData, section) &&
                            (fieldData?.data_type?.toLowerCase() === 'text' ||
                              fieldData?.data_type?.toLowerCase() ===
                                'numeric' ||
                              fieldData?.data_type?.toLowerCase() ===
                                'text box' ||
                              fieldData?.data_type?.toLowerCase() ===
                                'currency verbal' ||
                              fieldData?.data_type?.toLowerCase() ===
                                'date verbal' ||
                              fieldData?.data_type.toLowerCase() ===
                                'serial no') && (
                              <ControlledTextField
                                key={fieldData?.id}
                                defaultValue={fieldData?.field_value}
                                control={control}
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                disabled={isDisabled(fieldData?.email)}
                                variant="outlined"
                                fullWidth
                                type={fieldData?.data_type.toLowerCase()}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'text'
                                  )
                                }
                                required={fieldData?.required}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                InputProps={{
                                  readOnly:
                                    isTemplateView ||
                                    fieldData?.data_type.toLowerCase() ===
                                      'serial no',
                                }}
                              />
                            )}
                          {showField(fieldData, section) &&
                            fieldData?.data_type.toLowerCase() === 'date' && (
                              <DatePickerElement
                                label={fieldData.display_name}
                                name={fieldData.field_name}
                                control={control}
                                format="DD-MM-YYYY"
                                disablePast
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'date'
                                  )
                                }
                                disabled={isDisabled(fieldData?.email)}
                                readOnly={isTemplateView}
                                inputProps={{
                                  onFocus: () =>
                                    highlightTag(fieldData.field_name),
                                }}
                                required={fieldData.required}
                              />
                            )}
                          {showField(fieldData, section) &&
                            (fieldData?.data_type.toLowerCase() ===
                              'percentage' ||
                              fieldData?.data_type.toLowerCase() ===
                                'currency') && (
                              <ControlledTextField
                                key={fieldData?.id}
                                control={control}
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                variant="outlined"
                                fullWidth
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {fieldData?.data_type.toLowerCase() ===
                                        'percentage' && <PercentIcon />}
                                      {fieldData?.data_type.toLowerCase() ===
                                        'currency' &&
                                        currency_icon[
                                          fieldData?.set_recommended_value
                                            ?.default_field_unit
                                        ]}
                                    </InputAdornment>
                                  ),
                                  readOnly: isTemplateView,
                                }}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    fieldData?.data_type.toLowerCase()
                                  )
                                }
                                required={fieldData?.required}
                                disabled={isDisabled(fieldData?.email)}
                              />
                            )}

                          {showField(fieldData, section) &&
                            fieldData?.data_type.toLowerCase() ===
                              'duration' && (
                              <Stack direction="row" spacing={1}>
                                <ControlledTextField
                                  key={fieldData?.id}
                                  control={control}
                                  name={fieldData?.field_name}
                                  label={fieldData?.display_name}
                                  type="number"
                                  sx={{ flex: 1 }}
                                  inputProps={{
                                    readOnly: isTemplateView,
                                  }}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      'text'
                                    )
                                  }
                                  required={fieldData?.required}
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                  disabled={isDisabled(fieldData?.email)}
                                />
                                <Select
                                  value={fieldData.unit || 'Days'}
                                  label="Duration"
                                  variant="outlined"
                                  required={fieldData?.required}
                                  inputProps={{
                                    id: 'Select-duration',
                                  }}
                                  readOnly={isTemplateView}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      'duration'
                                    )
                                  }
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                  disabled={isDisabled(fieldData?.email)}
                                >
                                  <MenuItem key={'Days'} value={'Days'}>
                                    Day(s)
                                  </MenuItem>
                                  <MenuItem key={'Months'} value={'Months'}>
                                    Month(s)
                                  </MenuItem>
                                  <MenuItem key={'Years'} value={'Years'}>
                                    Years(s)
                                  </MenuItem>
                                </Select>
                              </Stack>
                            )}
                          {showField(fieldData, section) &&
                            fieldData.data_type.toLowerCase() === 'select' && (
                              <SelectElement
                                key={fieldData?.id}
                                fullWidth
                                name={fieldData?.field_name}
                                control={control}
                                label={fieldData?.display_name}
                                options={
                                  fieldData?.set_recommended_value
                                    ?.default_field_value || []
                                }
                                required={fieldData?.required}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                disabled={
                                  isDisabled(fieldData?.email) || isTemplateView
                                }
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'select'
                                  )
                                }
                                labelKey="name"
                                valueKey="name"
                              />
                            )}
                          {showField(fieldData, section) &&
                            fieldData.data_type.toLowerCase() === 'boolean' && (
                              <ControlledCheckbox
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                required={fieldData?.required}
                                control={control}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'boolean'
                                  )
                                }
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                inputProps={{
                                  readOnly: isTemplateView,
                                }}
                                disabled={isDisabled(fieldData?.email)}
                              />
                            )}
                          {showField(fieldData, section) &&
                            (fieldData?.data_type?.toLowerCase() ===
                              'bulleted list' ||
                              fieldData?.data_type?.toLowerCase() ===
                                'numbered list') && (
                              <Stack spacing={1} mb={3}>
                                {Array.isArray(fieldData?.field_value) &&
                                  fieldData?.field_value?.map(
                                    (
                                      item: any,
                                      index: number,
                                      items: any[]
                                    ) => (
                                      <Stack direction="row" key={index}>
                                        <TextField
                                          key={fieldData?.id}
                                          value={item.list}
                                          label={fieldData?.display_name}
                                          variant="outlined"
                                          fullWidth
                                          inputProps={{
                                            readOnly: isTemplateView,
                                          }}
                                          type="text"
                                          onChange={(event: any) =>
                                            updateListData(
                                              event,
                                              sectionIndex,
                                              fieldIndex,
                                              index
                                            )
                                          }
                                          required={fieldData?.required}
                                          onFocus={() => {
                                            highlightTag(fieldData.field_name);
                                          }}
                                          disabled={isDisabled(
                                            fieldData?.email
                                          )}
                                        />
                                        {items.length - 1 === index && (
                                          <IconButton
                                            disabled={isDisabled(
                                              fieldData?.email
                                            )}
                                            onClick={(e) =>
                                              addItemList(
                                                e,
                                                sectionIndex,
                                                fieldIndex,
                                                index
                                              )
                                            }
                                          >
                                            <AddCircleOutline />
                                          </IconButton>
                                        )}
                                        {items.length - 1 !== index && (
                                          <IconButton
                                            disabled={isDisabled(
                                              fieldData?.email
                                            )}
                                            onClick={() =>
                                              removeItemFromField(
                                                sectionIndex,
                                                fieldIndex,
                                                index
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    )
                                  )}
                              </Stack>
                            )}
                          {showField(fieldData, section) &&
                            fieldData?.user_name && (
                              <Stack direction="row" alignItems="center">
                                <Tooltip
                                  title={
                                    !fieldData?.assigned_user?.is_active &&
                                    fieldData?.assigned_user?.id ===
                                      fieldData?.user &&
                                    (fieldData?.assignee_type !== 'external' ||
                                      fieldData?.user_type !== 'external')
                                      ? `${fieldData?.assigned_user?.first_name} ${fieldData?.assigned_user?.last_name} is now inactive, please assign another user.`
                                      : ''
                                  }
                                >
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      handleOpenAssigneeDialog(fieldData)
                                    }
                                    disabled={isDraftDropped}
                                  >
                                    <NameAvatar
                                      firstName={fullName?.[0]}
                                      lastName={fullName?.[1]}
                                      avatarStyle={{
                                        background:
                                          !fieldData?.assigned_user
                                            ?.is_active &&
                                          fieldData?.assigned_user?.id ===
                                            fieldData?.user &&
                                          (fieldData?.assignee_type !==
                                            'external' ||
                                            fieldData?.user_type !== 'external')
                                            ? 'rgba(0, 0, 0, 0.25)'
                                            : '#A0597F',
                                      }}
                                      disableTooltip={
                                        !fieldData?.assigned_user?.is_active &&
                                        fieldData?.assigned_user?.id ===
                                          fieldData?.user &&
                                        (fieldData?.assignee_type !==
                                          'external' ||
                                          fieldData?.user_type !== 'external')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>

                                {!fieldData?.reminder?.due_date &&
                                  userIsOwner && (
                                    <Tooltip title="Create Reminder">
                                      <EventIcon
                                        onClick={() => {
                                          if (!isDraftDropped) {
                                            handleOpenAssigneeDialog(fieldData);
                                          }
                                        }}
                                        sx={{
                                          color: '#88305F',
                                          fontSize: '20px',
                                          cursor: 'pointer',
                                          marginLeft: '10px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                              </Stack>
                            )}
                          {showField(fieldData, section) &&
                            !isTemplateView &&
                            !fieldData?.user_name &&
                            fieldData?.data_type.toLowerCase() !==
                              'serial no' && (
                              <IconButton
                                edge="end"
                                disabled={
                                  (!userIsOwner && !userIsCreator) ||
                                  isDraftDropped
                                }
                                onClick={() =>
                                  handleOpenAssigneeDialog(fieldData)
                                }
                              >
                                <PersonAddAltOutlinedIcon />
                              </IconButton>
                            )}
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Button
          id="save_draft_button"
          onClick={handleSaveDraft}
          sx={{ display: 'none' }}
        >
          Save draft
        </Button>
      </FormContainer>

      {openAssigneeDialog && (
        <AssigneeModal
          open={openAssigneeDialog}
          onClose={handleCloseAssigneeDialog}
          approversData={approversData}
          title="Assign this template field to someone"
          heading="Assign this template field to"
          handleAssignee={handleAssignee}
          handleReminder={handleReminder}
          assigneeDialogPropData={assigneeDialogPropData}
          completeDraftReminder={
            complete_reminder_data?.results?.[0] || reminderData
          }
          handleUpdateReminder={handleUpdateReminder}
          draftData={draftData}
          optionalFields={optionalFields}
          isExternal={isExternal}
          updateDraftData={updateDraftData}
          loadingEditReminder={loadingEditReminder}
        />
      )}
    </React.Fragment>
  );
};

export default CompleteDraft;
