import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Docxtemplater from 'docxtemplater';
import { useSnackbar } from 'notistack';
import PizZip from 'pizzip';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import DraftForm from './DraftForm';
import MultiUploadNotes from './MultiUploadNotes';
import { formTypeOptions } from './staticData';
import { useUserData } from '../../../App/Component/UserDataProvider';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { Blank_Document_link } from '../../../Constants/const';
import { isRiverusAdmin } from '../../../DataDictionary/DDUtils';
import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import NotepadComponent from '../../../RiverusUI/Components/NotepadComponent';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  fetchAllContractCategory,
  fetchContractType,
  fetchDraftGroups,
  fetchGroups,
  fetchRequestApprovals,
} from '../../../Services/Approval';
import {
  addProject,
  editContract,
  fetchContractById,
  fetchProjects,
} from '../../../Services/DocumentLibrary';
import {
  createDraft,
  fetchPolicyContractTypes,
  fetchTemplate,
  getDraftById,
  getS3PresignedUrl,
  getS3ReferencePresignedURL,
  referenceDocument,
  sendEmail,
  upload_file_in_s3_bucket,
} from '../../../Services/Draft';
import { fetchInternalUsers } from '../../../Services/Share';
import { draftStatus } from '../../State/DraftState';
import { sortListAlphabetical } from '../Helper';
import LinkSelectedTemplateField from '../TemplateDrawer/LinkSelectedTemplateField';

interface IProps {
  onClose: VoidFunction;
  setRefreshKey: any;
  setFilters: any;
  setSorting: any;
  initialFilter: any;
}

const SparkMD5 = require('spark-md5');

const CreateDraft: React.FC<IProps> = ({
  onClose,
  setRefreshKey,
  setFilters,
  setSorting,
  initialFilter,
}) => {
  const [formTypes, setFormTypes] = useState(formTypeOptions);
  const [counterPartyDraft, setCounterPartyDraft] = useState<any>([]);
  const [selectedLinkedDraft, setSelectedLinkedDraft] = useState<any>(null);
  const [formData, setFormData] = useState<any>();
  const [uploadedFile, setUploadedFile] = useState<any[]>([]);
  const [draftDetails, setDraftDetails] = useState<any>({});
  const [allReferenceDocs, setAllReferenceDocs] = useState<any[]>([]);
  const [ownReferenceFileData, setOwnReferenceFileData] = useState<any>({});
  const [draftPayloadLink, setDraftPayloadLink] = useState<any[]>([]);
  const [completedUploads, setCompletedUploads] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  const draftTriggeredRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams: any = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const queryParamsContractId = queryParams.get('contract_id');
  const is_renewed = queryParams.get('is_renewed');

  const methods = useForm();

  const { handleSubmit, control, watch, resetField, setValue } = methods;

  const form_type = watch('createFrom');
  const contract_type = watch('contractType');
  const template = watch('template');
  const can_upload_draft = watch('can_upload_draft');
  const can_link_draft = watch('can_link_draft');
  const selectedApprovers = watch('approvalTypes');
  const contract_category = watch('contract_category') || '';
  const selectedProject = watch('projects');

  useEffect(() => {
    resetField('createFrom');
  }, [contract_type, resetField]);

  useEffect(() => {
    if (!contract_type) {
      resetField('contract_category');
    }
  }, [contract_type, resetField]);

  const { user_data, user_id, userIsCreator } = useUserData();

  const { data: umbrellaContractData } = useQuery({
    queryKey: ['get_contract_data_by_id', queryParamsContractId],
    queryFn: () => fetchContractById(queryParamsContractId),
    enabled: !!queryParamsContractId,
  });

  const { data: draft_data } = useQuery({
    queryKey: ['get_draft_data_by_id', umbrellaContractData?.draft],
    queryFn: () => getDraftById(umbrellaContractData?.draft),
    enabled: !!umbrellaContractData?.draft,
  });

  const { data: version0Data } = useQuery({
    queryKey: ['get_version_0_draft_by_id', draft_data?.draftID],
    queryFn: () => getDraftById(draft_data?.draftID),
    enabled: !!draft_data?.draftID,
  });

  useEffect(() => {
    if (umbrellaContractData?.contract_type?.length) {
      setValue('contractType', umbrellaContractData?.contract_type?.[0]?.id);
    }
  }, [umbrellaContractData]);

  const { data: internalUsers, isLoading: usersLoading } = useQuery({
    queryKey: ['get-internal-users'],
    queryFn: fetchInternalUsers,
    select: (response: any) => {
      const users = response?.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return users;
    },
  });

  const { data: contracts, isLoading: contractLoading } = useQuery({
    queryKey: ['contract_data'],
    queryFn: fetchContractType,
    select: (response: any) => response.results,
    enabled: !userIsCreator,
  });

  const { data: contractCategory, isLoading: categoryLoading } = useQuery({
    queryKey: ['contract_category_data'],
    queryFn: fetchAllContractCategory,
    select: (response: any) => response.results,
  });

  const { data: creatorContractType, isLoading: contractTypeLoading } =
    useQuery({
      queryKey: ['creator_contract_type'],
      queryFn: fetchPolicyContractTypes,
      select: (response: any) => response.results,
      enabled: !!userIsCreator,
    });

  const { data: contractRequestData } = useQuery({
    queryKey: ['contract_type_has_policy', contract_type, contract_category],
    queryFn: () => {
      let params = `?approval_type=contract_request&contract=${contract_type}`;
      if (contract_category) {
        params += `&contract_category=${contract_category}`;
      }
      return fetchRequestApprovals(params);
    },
    select: (response: any) => {
      const filterContractType = response?.results?.filter((item: any) => {
        if (item?.contract_category?.length) {
          return item?.contract_category?.some(
            (categoryItem: any) => categoryItem?.id === contract_category
          );
        } else {
          return item;
        }
      });
      if (response?.results) {
        response.results = filterContractType;
      }
      return response;
    },
    enabled: !!contract_type,
  });

  const { data: creatorCategory } = useQuery({
    queryKey: ['contract_category_has_policy', contract_type],
    queryFn: () => {
      const params = `?approval_type=contract_request&contract=${contract_type}`;
      return fetchRequestApprovals(params);
    },
    select: (response: any) => {
      const filterCategory = response?.results
        ?.map((item: any) => {
          const categoryItem = item?.contract_category?.filter(
            (category: any) =>
              category?.contract_types?.some(
                (type: any) => type?.id === contract_type
              )
          );
          return categoryItem;
        })
        .flat();
      const isCategoryRequired = response?.results?.filter(
        (item: any) => !item?.contract_category?.length
      );
      return {
        isCategoryRequired: isCategoryRequired?.length ? false : true,
        creatorCategoryData: filterCategory,
      };
    },
    enabled: !!(contract_type && userIsCreator),
  });

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
  });

  const { data: draftGroups } = useQuery({
    queryKey: ['draft_groups', contract_type],
    queryFn: async () => await fetchDraftGroups(contract_type),
    select: (response: any) => response?.groups,
    enabled: !!contract_type,
  });

  const { data: projectData, isLoading: projectLoading } = useQuery({
    queryKey: ['project_data'],
    queryFn: async () => await fetchProjects(),
    select: (response: any) =>
      response.results.filter((data: any) => data.name !== ''),
  });

  const { mutate: addProjectMutation } = useMutation({
    mutationKey: ['add-project'],
    mutationFn: addProject,
    onSuccess: () => {
      enqueueSnackbar('Project added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({ queryKey: ['project_data'] });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create project'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    const groups = [
      ...new Set([...(draftGroups || []), ...(user_data?.groups || [])]),
    ];
    if (is_renewed && selectedApprovers?.length > 0) {
      internalUsers?.forEach((approver: any) => {
        if (selectedApprovers.includes(approver?.id)) {
          const missingInDraftGroups = approver?.groups.filter(
            (item: string) => !groups?.includes(item)
          );
          if (missingInDraftGroups?.length) {
            groups.push(...missingInDraftGroups);
          }
        }
      });
    }
    setValue('groups', groups);
  }, [draftGroups, setValue, user_data?.groups, selectedApprovers]);

  const { data: getTemplateList, isLoading: loadingTemplateList } = useQuery({
    queryKey: ['template_list'],
    queryFn: async () => {
      const response = await fetchTemplate();
      return response?.results;
    },
  });

  const templateList = useMemo(() => {
    return Array.isArray(getTemplateList)
      ? getTemplateList?.filter(
          (items: any) => items?.contract_type?.id === contract_type
        )
      : [];
  }, [getTemplateList, contract_type]);

  const selectedContractData = useMemo(() => {
    if (userIsCreator) {
      const selectedContract = creatorContractType?.filter(
        (data: any) => data?.id === contract_type
      );
      return selectedContract?.[0];
    } else {
      const selectedContract = contracts?.filter(
        (data: any) => data?.id === contract_type
      );
      return selectedContract?.[0];
    }
  }, [contracts, contract_type, creatorContractType]);

  useEffect(() => {
    if (contract_type && templateList) {
      const updateDraftOptions = [...formTypeOptions];
      if (templateList?.length === 0) {
        const option = {
          value: 'template',
          title: '',
          description: `No templates found under contract type “${selectedContractData?.name}".`,
          disabled: true,
        };
        updateDraftOptions[0] = option;
      } else {
        updateDraftOptions[0].disabled = false;
      }
      setFormTypes(updateDraftOptions);
    }
  }, [selectedContractData, contract_type, templateList]);

  const selectedTemplate = React.useMemo(() => {
    const temp = templateList?.filter((data: any) => data.id === template);
    return temp?.[0];
  }, [template, templateList]);

  const handleUploadAllRefDoc = (refPayload: any[] = []) => {
    const reference_documents: any[] = [];
    if (form_type !== 'request_draft') {
      uploadedFile?.map((file: any) => {
        const fileData = {
          file_name: file?.[0]?.name,
          file_type: 'note_for_approval',
        };
        reference_documents.push(fileData);
      });
    }

    const payload = {
      reference_documents: [...refPayload, ...reference_documents],
      file_type: 'reference_document',
    };

    uploadReferenceDocument({ body: payload });
  };

  const uploadBlankDoc = async (data: any) => {
    if (data) {
      const reference_documents: any[] = [];
      let noteFile: any[] = [];
      let policyFile: any[] = [];

      const processDocument = async (
        content: string,
        file_name_suffix: string,
        file_type: string
      ) => {
        const templateFile = await fetch(Blank_Document_link.NOTES_BLANK_DOC);
        const templateData = await templateFile.arrayBuffer();
        const zip = new PizZip(templateData);
        const doc = new Docxtemplater().loadZip(zip);
        const docContent = content
          .replace(/<\/?[^>]+>/g, '\n')
          .replace(/&nbsp;/g, ' ');
        doc.setData({ content: docContent });
        doc.render();

        const generatedDocx = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        const file = generatedDocx;
        file['name'] = `Additional Requirements_${file_name_suffix}.docx`;
        const reader = new FileReader();

        if (file) {
          reader.readAsDataURL(file);
          reader.onload = async () => {
            reference_documents.push({
              file_name: file?.name,
              file_type: file_type,
            });
            if (file_type === 'draft_notes') {
              noteFile = [file];
            } else if (file_type === 'policy_notes') {
              policyFile = [file];
            }
          };
        }

        return new Promise<void>((resolve) => {
          reader.onloadend = () => {
            resolve();
          };
        });
      };

      if (data?.notes && form_type !== 'request_draft') {
        await Promise.all([
          processDocument(data?.notes, 'notes', 'draft_notes'),
        ]);

        if (!data?.policy_note) {
          setAllReferenceDocs([noteFile, ...uploadedFile]);
          handleUploadAllRefDoc(reference_documents);
        }
      }

      if (data?.policy_note) {
        await Promise.all([
          processDocument(data?.policy_note, 'policy_notes', 'policy_notes'),
        ]);
        if (form_type === 'request_draft') {
          const allReferenceDocs = [
            ...(uploadedFile ? uploadedFile : []),
            ...(data?.notes ? [noteFile] : []),
            ...(data?.policy_note ? [policyFile] : []),
            ...(can_upload_draft ? [counterPartyDraft] : []),
          ];
          setAllReferenceDocs(allReferenceDocs);
        } else {
          if (!data?.notes) {
            setAllReferenceDocs([policyFile, ...uploadedFile]);
          } else {
            setAllReferenceDocs([noteFile, policyFile, ...uploadedFile]);
          }
        }
        handleUploadAllRefDoc(reference_documents);
      }
    }
  };

  const getFileType = (url: string) => {
    if (url.includes('draft_notes')) {
      return 'draft_notes';
    } else if (url.includes('policy_notes')) {
      return 'policy_notes';
    } else if (url.includes('note_for_approval')) {
      return 'note_for_approval';
    } else if (url.includes('earlier_draft')) {
      return 'earlier_draft';
    } else if (url.includes('own_reference_document')) {
      return 'own_reference_document';
    }
  };

  const { mutate: emailMutation } = useMutation({
    mutationKey: ['send_email'],
    mutationFn: sendEmail,
    onSuccess: (response: any) => {
      if (response?.message !== 'No Emails Sent') {
        enqueueSnackbar('Email sent successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      onClose();
      window.open(`/draft/${draftDetails?.version}/${draftDetails?.id}`);
      if (draftDetails?.renewal_contract_id) {
        navigate('/draftingreview');
      }
      queryClient.invalidateQueries({
        queryKey: ['draft_statistics'],
      });
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      setFilters(initialFilter);
      setSorting([]);
      setRefreshKey((prevKey: any) => !prevKey);
    },
    onError: () => {
      enqueueSnackbar('Email is not sent successfully!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: updateContract } = useMutation({
    mutationFn: () =>
      editContract({
        id: umbrellaContractData?.id,
        body: { is_renewed: 'Under_Renewal' },
      }),
    onError: () => {
      enqueueSnackbar('Failed to update contract!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: createDraftMutation, isPending: createDraftLoading } =
    useMutation({
      mutationKey: ['create_draft'],
      mutationFn: createDraft,
      onSuccess: async (response: any) => {
        if (umbrellaContractData?.id) {
          updateContract();
        }
        setDraftDetails(response);
        enqueueSnackbar('Draft added successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        if (form_type === 'request_draft') {
          setDraftDetails(response);
          if (response?.policy_note) {
            await uploadBlankDoc(response);
          }
          if (!response?.policy_note) {
            const processFiles = async () => {
              const referencePresignedUrls: any[] = [];
              await Promise.all(
                draftPayloadLink?.map(
                  async (presigned_url: any, index: number) => {
                    const fileData = allReferenceDocs?.[index]?.[0];
                    if (fileData) {
                      const reader = new FileReader();
                      const hexHash = await new Promise<string>((resolve) => {
                        reader.readAsDataURL(fileData);
                        reader.onload = () => {
                          const hash = SparkMD5.hash(reader.result);
                          resolve(hash);
                        };
                      });

                      const file_ = {
                        file_name: presigned_url?.file_name,
                        file_type: getFileType(presigned_url?.file_path),
                        file_hash: hexHash,
                        file_size: fileData?.size,
                        draft: response?.draftID,
                        link: presigned_url?.file_path,
                      };
                      referencePresignedUrls.push(file_);
                    }
                  }
                )
              );
              const processFileType = (fileType: string) => {
                const file = draftPayloadLink?.find(
                  (url: any) => url?.file_type === fileType
                );
                if (file) {
                  const fileData = {
                    file_name: file?.file_name,
                    file_type: file?.file_type,
                    draft: response?.draftID,
                    link: file?.file_path,
                    file_size: selectedLinkedDraft?.file_size,
                    file_hash: selectedLinkedDraft?.file_hash,
                  };
                  referencePresignedUrls.push(fileData);
                }
              };
              processFileType('earlier_draft');
              processFileType('executed_contract');
              if (referencePresignedUrls.length > 0) {
                uploadReferenceDocLinkinDraft(referencePresignedUrls);
              }
            };
            await processFiles();
          }
        } else {
          if (
            !response?.policy_note &&
            !response?.notes &&
            uploadedFile?.length === 0 &&
            !can_upload_draft
          ) {
            const payload: any = {
              draft: response?.draftID,
            };
            emailMutation(payload);
          } else {
            setDraftDetails(response);
            if (response?.notes || response?.policy_note) {
              uploadBlankDoc(response);
            } else if (uploadedFile?.length > 0) {
              handleUploadAllRefDoc();
              setAllReferenceDocs([...uploadedFile]);
            } else if (can_upload_draft) {
              const payload = [
                {
                  ...ownReferenceFileData,
                  draft: response?.draftID,
                },
              ];
              uploadReferenceDocLinkinDraft(payload);
            }
          }
        }
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.non_field_errors?.[0];
        enqueueSnackbar(`${responseData || 'Failed to create Draft!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: uploadFileInS3Bucket, isPending: loadingS3 } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: (response: any) => {
      if (form_type !== 'request_draft') {
        createDraftMutation({ ...formData, link: response.file_path });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadReferenceFileInS3Bucket } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: () => {
      setCompletedUploads((prev) => prev + 1);
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadReferenceDocLinkinDraft } = useMutation({
    mutationKey: ['upload_reference_doc_link_in_draft'],
    mutationFn: referenceDocument,
    onSuccess: () => {
      const payload: any = {
        draft: draftDetails?.draftID,
        notes: true,
      };
      emailMutation(payload);
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = counterPartyDraft[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [counterPartyDraft]
  );

  const { mutate: uploadDocument, isPending: loadingUpload } = useMutation({
    mutationKey: ['upload_draft_document', counterPartyDraft],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response: any) => {
      if (response) {
        if (can_upload_draft) {
          setOwnReferenceFileData((prevState: any) => ({
            ...prevState,
            link: response?.presigned_url.file_path,
          }));
        }
        const file = counterPartyDraft[0];
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: response?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (
      completedUploads === totalFiles &&
      totalFiles > 0 &&
      form_type === 'request_draft' &&
      !draftTriggeredRef.current
    ) {
      draftTriggeredRef.current = true;
      const noteForApprovalFile =
        draftPayloadLink?.find(
          (file) => file?.file_type === 'note_for_approval'
        ) ||
        draftPayloadLink?.find((file) => file?.file_type === 'draft_notes');
      const link = noteForApprovalFile?.file_path;
      const payload = {
        ...formData,
        ...(formData.createFrom === 'request_draft' && { link: link }),
      };
      createDraftMutation(payload);
    }
  }, [completedUploads, totalFiles, draftPayloadLink]);

  const { mutate: uploadReferenceDocument } = useMutation({
    mutationKey: ['upload_reference_document'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: async (response: any) => {
      const presigned_urls = response?.response?.presigned_url;

      setDraftPayloadLink(presigned_urls);
      if (presigned_urls) {
        const referencePresignedUrls: any = [];
        const filteredReferenceDocs = allReferenceDocs?.filter(
          (docArray) => docArray.length > 0
        );
        const totalFilesCount = filteredReferenceDocs?.length || 0;
        setTotalFiles(totalFilesCount);

        if (
          presigned_urls?.some(
            (urls: any) => urls?.file_type === 'policy_notes'
          ) &&
          form_type === 'request_draft'
        ) {
          const processDocument = async (content: string): Promise<Blob> => {
            const templateFile = await fetch(
              Blank_Document_link.NOTES_BLANK_DOC
            );
            const templateData = await templateFile.arrayBuffer();
            const zip = new PizZip(templateData);
            const doc = new Docxtemplater().loadZip(zip);
            const docContent = content.replace(/<\/?p>/g, '');
            doc.setData({ content: docContent });
            doc.render();
            return doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
          };

          const handleDocumentUpload = async (
            content: string,
            file_type: string,
            presigned_url: any
          ) => {
            const policyBlob = await processDocument(content);
            const fileData = new File(
              [policyBlob],
              `Additional Requirements_${file_type}.docx`,
              {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              }
            );
            const onHandleFileProgress = {
              onUploadProgress: (progressEvent: any) =>
                onUploadProgress(progressEvent),
            };
            await uploadReferenceFileInS3Bucket({
              presignedPostData: presigned_url,
              file: fileData,
              onHandleFileProgress: onHandleFileProgress,
            });
          };
          const combinedData = [...presigned_urls, ...draftPayloadLink];
          combinedData.forEach((presigned_url: any) => {
            if (presigned_url.file_type === 'policy_notes') {
              handleDocumentUpload(
                draftDetails?.policy_note,
                'policy_notes',
                presigned_url
              );
            }
          });

          const uniqueFileMap = new Set();
          combinedData.forEach((presigned_url: any, index: number) => {
            if (
              presigned_url?.file_type === 'executed_contract' ||
              presigned_url?.file_type === 'earlier_draft'
            ) {
              const file_ = {
                file_name: presigned_url?.file_name,
                file_type: presigned_url?.file_type,
                file_size: selectedLinkedDraft?.file_size,
                file_hash: selectedLinkedDraft?.file_hash,
                draft: draftDetails?.draftID,
                link: presigned_url?.file_path,
              };

              const fileKey = `${file_.file_name}-${file_.file_type}`;
              if (!uniqueFileMap.has(fileKey)) {
                uniqueFileMap.add(fileKey);
                referencePresignedUrls.push(file_);
              }
            }

            allReferenceDocs?.forEach((fileData: any, i: number) => {
              if (index === i) {
                const reader = new FileReader();
                const file = fileData[0];
                if (file) {
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const hexHash = SparkMD5.hash(reader.result);
                    const file_ = {
                      file_name: presigned_url?.file_name,
                      file_type: getFileType(presigned_url?.file_path),
                      file_hash: hexHash,
                      file_size: file?.size,
                      draft: draftDetails?.draftID,
                      link: presigned_url?.file_path,
                    };
                    const fileKey = `${file_.file_name}-${file_.file_hash}`;
                    if (!uniqueFileMap.has(fileKey)) {
                      uniqueFileMap.add(fileKey);
                      referencePresignedUrls.push(file_);
                    }
                  };
                }
              }
            });
          });
          setTimeout(() => {
            if (referencePresignedUrls.length > 0) {
              uploadReferenceDocLinkinDraft(referencePresignedUrls);
            }
          }, 2000);
        } else {
          presigned_urls?.map((presigned_url: any, index: number) => {
            allReferenceDocs?.map((fileData: any, i: number) => {
              if (index === i) {
                const reader = new FileReader();
                const file = fileData[0];
                if (file) {
                  reader.readAsDataURL(file);
                  const onHandleFileProgress = {
                    onUploadProgress: (progressEvent: any) =>
                      onUploadProgress(progressEvent),
                  };
                  uploadReferenceFileInS3Bucket({
                    presignedPostData: presigned_url,
                    file: file,
                    onHandleFileProgress: onHandleFileProgress,
                  });
                  reader.onload = async () => {
                    const hexHash = SparkMD5.hash(reader.result);
                    const file_ = {
                      file_hash: hexHash,
                      file_name: file.name,
                      file_size: file.size,
                      file_type: getFileType(presigned_url?.file_path),
                      draft: draftDetails?.draftID,
                      link: presigned_url?.file_path,
                    };
                    referencePresignedUrls.push(file_);
                    if (
                      presigned_urls?.length - 1 === index &&
                      form_type !== 'request_draft'
                    ) {
                      if (can_upload_draft) {
                        const payload = {
                          ...ownReferenceFileData,
                          draft: draftDetails?.draftID,
                        };
                        referencePresignedUrls.push(payload);
                      }
                      uploadReferenceDocLinkinDraft(referencePresignedUrls);
                    }
                  };
                }
              }
            });
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const contractApproversId = useMemo(() => {
    return contractRequestData?.results?.[0]?.approvers?.map(
      (approver: any) => approver?.id
    );
  }, [contractRequestData]);

  const generatePresignedPayload = async (
    uploadedFile: File[],
    counterPartyDraft?: File[],
    selectedLinkedDraft?: any,
    data?: any
  ) => {
    const referenceDocuments: any[] = [];
    let noteFile: any[] = [];
    let policyFile: any[] = [];
    let linkParam: any = null;

    if (uploadedFile?.length > 0) {
      uploadedFile?.forEach((fileArray: any) => {
        const file = fileArray[0];
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'note_for_approval',
        });
      });
    }

    const processDraftNotes = async (
      content: string,
      suffix: string,
      file_type: string
    ) => {
      const templateFile = await fetch(Blank_Document_link.NOTES_BLANK_DOC);
      const templateData = await templateFile.arrayBuffer();
      const zip = new PizZip(templateData);
      const doc = new Docxtemplater().loadZip(zip);
      doc.setData({ content: content.replace(/<\/?p>/g, '') });
      doc.render();
      const generatedDocx = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const file = new File(
        [generatedDocx],
        `Additional Requirements_${suffix}.docx`
      );

      if (file_type === 'draft_notes') {
        noteFile = [file];
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'draft_notes',
        });
      } else if (file_type === 'policy_notes') {
        policyFile = [file];
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'policy_notes',
        });
      }
      return file;
    };
    if (data?.notes)
      await processDraftNotes(data.notes, 'notes', 'draft_notes');
    if (data?.policy_note)
      await processDraftNotes(data.policy_note, 'policy_notes', 'policy_notes');

    if (can_upload_draft) {
      counterPartyDraft?.forEach((file: File) => {
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'own_reference_document',
        });
      });
    }

    if (can_link_draft && selectedLinkedDraft) {
      if (selectedLinkedDraft?.link) {
        const fileExtension = selectedLinkedDraft?.link?.split('.').pop();
        linkParam = { earlier_draft_link: selectedLinkedDraft?.link };
        referenceDocuments.push({
          file_name: `${selectedLinkedDraft?.contractName || selectedLinkedDraft?.title}.${fileExtension}`,
          file_type: 'earlier_draft',
          earlier_draft_link: selectedLinkedDraft?.link,
        });
      } else {
        const fileExtension = selectedLinkedDraft?.file_name.split('.').pop();
        linkParam = {
          executed_contract_link: `${data.linked_draft}.${fileExtension}`,
        };
        referenceDocuments.push({
          file_name: selectedLinkedDraft?.file_name,
          file_type: 'executed_contract',
          executed_contract_link: `${data.linked_draft}.${fileExtension}`,
        });
      }
    }

    const allReferenceDocs = [
      ...uploadedFile,
      ...(data?.notes ? [noteFile] : []),
      ...(data?.policy_note ? [policyFile] : []),
      ...(can_upload_draft ? [counterPartyDraft] : []),
    ];
    const validReferenceDocs = allReferenceDocs.filter((doc) => doc != null);
    setAllReferenceDocs(validReferenceDocs);

    const payload = {
      ...(linkParam || {}),
    };
    setFormData(payload);

    return {
      reference_documents: referenceDocuments,
      file_type: 'reference_document',
    };
  };

  const requisitionContractData = useMemo(() => {
    return creatorContractType?.filter(
      (contractType: any) => contractType?.has_requisition_approval
    );
  }, [creatorContractType]);

  const filteredContractCategory = useMemo(() => {
    return contractCategory?.filter((category: any) =>
      category?.contract_types?.some((type: any) => type?.id === contract_type)
    );
  }, [contractCategory, contract_type]);

  const noContractTypeCategory = useMemo(() => {
    return contractCategory?.filter(
      (category: any) => category?.contract_types?.length === 0
    );
  }, [contractCategory]);

  const combinedContractCategories = useMemo(() => {
    return [
      ...(creatorCategory?.creatorCategoryData || []),
      ...(noContractTypeCategory || []),
      ...(filteredContractCategory || []),
    ];
  }, [
    filteredContractCategory,
    noContractTypeCategory,
    creatorCategory?.creatorCategoryData,
  ]);

  const assigneeCombinedData = useMemo(() => {
    const filteredData = internalUsers?.filter(
      (assignee: any) =>
        assignee?.is_active &&
        (userIsCreator || assignee?.groups?.includes('/Legal'))
    );
    return Array.from(
      new Map(filteredData?.map((item: any) => [item?.id, item])).values()
    );
  }, [internalUsers, userIsCreator]);

  useEffect(() => {
    if (is_renewed) {
      if (contract_type === version0Data?.contractType?.id) {
        setValue('contract_category', version0Data?.contract_category?.id);
      }

      const contractProjects = umbrellaContractData?.projects?.map(
        (project: any) => project?.id
      );
      if (contractProjects?.includes(version0Data?.projects?.[0]?.id)) {
        setValue('projects', version0Data?.projects?.[0]?.id);
      }

      // set the assignee
      const umbrellaOwnerIds =
        umbrellaContractData?.owners?.map((owner: any) => owner?.id) || [];
      const creatorAndUmbrellaIds = [
        ...umbrellaOwnerIds,
        umbrellaContractData?.creator_details?.id,
      ];
      const renewOwners = assigneeCombinedData
        ?.filter((approvers: any) =>
          creatorAndUmbrellaIds?.includes(approvers?.id)
        )
        ?.map((owner: any) => owner.id);
      setValue('approvalTypes', renewOwners);

      if (umbrellaContractData?.renewal_draft_type === 'template') {
        setValue('createFrom', 'template');
        const tempData = templateList?.find(
          (data: any) => data?.file_path === version0Data?.template_link
        );
        setValue('template', tempData?.id);
      } else if (umbrellaContractData?.renewal_draft_type === 'request_draft') {
        setValue('createFrom', 'request_draft');
        setValue('counter_party_name', version0Data?.counter_party_name);
        setValue('can_link_draft', true);
        setValue('linked_draft', umbrellaContractData?.id);
      }
    }
  }, [
    version0Data,
    contract_type,
    templateList,
    is_renewed,
    assigneeCombinedData,
    umbrellaContractData,
  ]);

  useEffect(() => {
    if (!is_renewed) {
      setValue(
        'approvalTypes',
        userIsCreator && contractApproversId ? contractApproversId : ''
      );
    }
  }, [contractApproversId, userIsCreator, is_renewed]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (!selectedProject) {
        enqueueSnackbar('Please add or select a project!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }
      if (!data.groups) {
        data.groups = ['/Org'];
      } else {
        data.groups = data?.groups.filter((group: string) => group !== null);
      }
      delete data.counter_party_draft;
      const commonPayload = {
        status:
          contractApproversId || contractRequestData?.results?.length > 0
            ? draftStatus.REQUISITION_APPROVAL_PENDING
            : draftStatus.DRAFT_CREATION_PENDING,
        owners: is_renewed
          ? [user_id, ...(data?.approvalTypes || [])]
          : user_data?.roles?.includes('Creators') &&
              contractRequestData?.results?.[0]?.approvers?.length
            ? contractApproversId
            : [user_id, ...(data?.approvalTypes || [])],
        creator: userIsCreator ? user_id : '',
        approvers: contractApproversId || [],
        source: '',
        version: 0,
        projects: [data?.projects],
        renewal_contract_id: umbrellaContractData?.id
          ? umbrellaContractData?.id
          : '',
      };

      const ownerApproverIds = [
        ...commonPayload.owners,
        ...commonPayload.approvers,
      ];

      internalUsers?.forEach((approver: any) => {
        if (ownerApproverIds.includes(approver?.id)) {
          const missingInDraftGroups = approver?.groups.filter(
            (item: string) => !data.groups?.includes(item)
          );
          if (missingInDraftGroups?.length) {
            data.groups.push(...missingInDraftGroups);
          }
        }
      });

      let payload;
      if (form_type === 'template') {
        payload = {
          ...data,
          ...commonPayload,
          template_link: selectedTemplate?.file_path,
        };
        createDraftMutation(payload);
      } else {
        payload = {
          ...data,
          ...commonPayload,
        };
        if (form_type === 'counter_party') {
          if (!counterPartyDraft[0]) {
            enqueueSnackbar(
              'Please select the counter party draft to continue!',
              {
                variant: 'info',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
          } else {
            const reader = new FileReader();
            const file = counterPartyDraft[0];
            if (file) {
              reader.readAsDataURL(file);
              reader.onload = async () => {
                const hexHash = SparkMD5.hash(reader.result);
                const file_ = {
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  template_type: file['template_type'],
                  file_type: 'counter_party_drafts',
                  creation_type: isRiverusAdmin() ? 'system' : 'custom',
                };
                uploadDocument(file_);
              };
              payload.createFrom = 'counter_party';
              setFormData(payload);
            }
          }
        } else if (form_type === 'request_draft') {
          if (
            uploadedFile?.length === 0 &&
            (!data?.notes || data?.notes === '<p><br></p>') &&
            contractRequestData?.count > 0
          ) {
            enqueueSnackbar(
              'Please either upload or type additional requirements!',
              {
                variant: 'info',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
            return;
          } else if (
            (!data?.notes || data?.notes === '<p><br></p>') &&
            contractRequestData?.count === 0
          ) {
            enqueueSnackbar('Please type additional requirements!', {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
          if (!selectedApprovers?.length) {
            enqueueSnackbar(
              'Select assignees and then click the Continue button!',
              {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
          } else {
            const reference_documents = await generatePresignedPayload(
              uploadedFile,
              counterPartyDraft,
              selectedLinkedDraft,
              data
            );
            let executed_contract_link = null;
            let earlier_draft_link = null;
            if (can_link_draft) {
              if (selectedLinkedDraft?.link) {
                earlier_draft_link = selectedLinkedDraft?.link;
              } else {
                const fileExtension = selectedLinkedDraft?.file_name
                  .split('.')
                  .pop();
                executed_contract_link = `${selectedLinkedDraft?.id}.${fileExtension}`;
              }
            }
            if (reference_documents.reference_documents.length) {
              uploadReferenceDocument({ body: reference_documents });
            }
            payload = {
              ...data,
              ...commonPayload,
              executed_contract_link: executed_contract_link,
              earlier_draft_link: earlier_draft_link,
              createFrom: 'request_draft',
            };
            setFormData(payload);
          }
        }
      }
    },
    [
      contractRequestData,
      counterPartyDraft,
      createDraftMutation,
      form_type,
      enqueueSnackbar,
      form_type,
      selectedLinkedDraft,
      selectedTemplate,
      uploadDocument,
      user_data,
      user_id,
      userIsCreator,
      uploadedFile,
      generatePresignedPayload,
    ]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ padding: '30px 10px' }}>
          <Grid container gap={2}>
            <Grid item sm={12}>
              <Stack spacing={2}>
                <Typography marginBottom={2} fontWeight={'700'}>
                  Choose a starting point
                </Typography>
                <RISelectComponent
                  readOnly={is_renewed}
                  required
                  name="contractType"
                  control={control}
                  label="Select a contract type"
                  labelKey="displayName"
                  options={
                    userIsCreator
                      ? sortListAlphabetical(requisitionContractData)
                      : sortListAlphabetical(contracts)
                  }
                  loading={
                    userIsCreator ? contractTypeLoading : contractLoading
                  }
                  renderAction={(value: any) => (
                    <Stack direction="row" alignItems="center">
                      {value?.used_in_templates > 0 && (
                        <Typography variant="body2" color="textSecondary">
                          {`${value.used_in_templates} Template${
                            value.used_in_templates > 1 ? 's' : ''
                          }`}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
                {(userIsCreator
                  ? creatorCategory?.creatorCategoryData?.length
                  : combinedContractCategories?.length) > 0 && (
                  <RISelectComponent
                    name="contract_category"
                    control={control}
                    label="Contract Category"
                    options={
                      userIsCreator
                        ? sortListAlphabetical(
                            creatorCategory?.creatorCategoryData
                          )
                        : sortListAlphabetical(combinedContractCategories)
                    }
                    loading={categoryLoading}
                    required={
                      userIsCreator && creatorCategory?.isCategoryRequired
                    }
                  />
                )}
                {contractRequestData?.results?.length > 0 && (
                  <>
                    <Typography fontWeight="700">
                      Requisition Approval Note
                    </Typography>
                    <Stack direction="row" spacing={2} width="100%">
                      <KeyboardReturnIcon
                        sx={{
                          transform: 'scaleX(-1)',
                          mt: '15px !important',
                        }}
                      />
                      <Box width="100%">
                        <MultiUploadNotes
                          allowedFileTypes={['.pdf', '.docx']}
                          label="Upload note for Requisition Approval"
                          name="note_for_approval"
                          files={uploadedFile}
                          setFiles={setUploadedFile}
                        />
                      </Box>
                    </Stack>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <Stack spacing={2}>
                <Typography fontWeight="700">Select Draft Type</Typography>
                <RadioButtonGroup
                  disabled={is_renewed}
                  row
                  required
                  name="createFrom"
                  options={formTypes}
                  valueKey="value"
                  isDescription
                  control={control}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={2}>
                <Typography fontWeight="700">Add Details</Typography>
                <ControlledTextField
                  name="counter_party_name"
                  control={control}
                  label="Counterparty Name"
                  fullWidth
                  required
                />
              </Stack>
            </Grid>

            {form_type === 'template' && (
              <Stack spacing={2} width="100%">
                <Stack direction="row" spacing={2}>
                  <KeyboardReturnIcon
                    sx={{
                      transform: 'scaleX(-1)',
                      mt: '15px !important',
                    }}
                  />
                  <LinkSelectedTemplateField
                    control={control}
                    selectedContractId={contract_type}
                    name="template"
                    selectedContractName={selectedContractData?.name}
                    options={sortListAlphabetical(templateList)}
                    loading={loadingTemplateList}
                    type={form_type}
                    selectedDisplayName={selectedContractData?.displayName}
                    isRenewed={is_renewed}
                  />
                </Stack>
              </Stack>
            )}
            {form_type === 'template' && (
              <Grid container>
                <Grid item xs={12}>
                  <NotepadComponent name="notes" control={control} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ paddingLeft: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
                  >
                    Please add additional requirements
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(form_type === 'request_draft' ||
              form_type === 'counter_party') && (
              <DraftForm
                contract_name={selectedContractData?.name}
                contract_type={contract_type}
                display_name={selectedContractData?.displayName}
                counterPartyDraft={counterPartyDraft}
                setCounterPartyDraft={setCounterPartyDraft}
                setSelectedLinkedDraft={setSelectedLinkedDraft}
                contractRequestData={contractRequestData}
                assigneeCombinedData={assigneeCombinedData}
                form_type={form_type}
                umbrellaContractData={umbrellaContractData}
              />
            )}
            <Grid item sm={12}>
              <Stack spacing={2}>
                <Typography fontWeight={'700'}>Manage Access</Typography>
                <Stack spacing={3}>
                  <RISelectComponent
                    name="approvalTypes"
                    control={control}
                    label={`Select assignees ${form_type === 'request_draft' ? '*' : ''}`}
                    options={sortListAlphabetical(assigneeCombinedData)}
                    loading={usersLoading}
                    isMultiselect
                    readOnly={userIsCreator}
                    renderCustomComponent={(value: any, props) => (
                      <ShareChip
                        key={value?.id}
                        {...props}
                        label={value?.name}
                      />
                    )}
                    textFieldProps={{
                      helperText: userIsCreator
                        ? 'You cannot modify these assignees'
                        : 'You can select multiple assignees',
                    }}
                  />
                  <RISelectComponent
                    readOnly={is_renewed}
                    name="groups"
                    control={control}
                    label="Prescribing Department *"
                    valueKey="name"
                    options={sortListAlphabetical(groupListData)}
                    loading={groupLoading}
                    isMultiselect={true}
                    renderCustomComponent={(value: any) => (
                      <CustomChip key={value?.id} label={value?.name} />
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <Stack spacing={2}>
                <Typography fontWeight={'700'}>
                  Add or Select Project
                </Typography>
                <RISelectComponent
                  name="projects"
                  options={sortListAlphabetical(projectData)}
                  control={control}
                  loading={projectLoading}
                  label="Add or Select Project *"
                  renderCustomComponent={(value: any, props) => (
                    <CustomChip {...props} label={value?.name} />
                  )}
                  canCreateNew
                  addNewValue={(value) =>
                    addProjectMutation({ name: value, displayName: value })
                  }
                />
              </Stack>
              <Stack direction="row" marginTop={3}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={createDraftLoading || loadingS3 || loadingUpload}
                >
                  Continue
                </LoadingButton>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default CreateDraft;
